import React from "react";
import styles from "./../../pages/Order/NewOrder.module.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import _ from "lodash";
import Alert from "react-bootstrap/esm/Alert";
const KotDeleteModel = ({
  show,
  setShow,
  initialQuantity,
  isQuantityDecreased,
  decreasedQuantity,
  setDecreasedQuantity,
  deleteKotItem,
  showModelOnResponse,
  cancelDescription,
  setCancelDescription,
}: {
  show: boolean;
  setShow: any;
  initialQuantity: number;
  isQuantityDecreased: boolean;
  decreasedQuantity: number;
  setDecreasedQuantity: any;
  deleteKotItem: any;
  showModelOnResponse: boolean;
  cancelDescription: string;
  setCancelDescription: any;
}) => {
  const handleClose = () => setShow(false);
  const descriptionHasLength: boolean =
    cancelDescription.length > 1 ? true : false;
  const confirmationHeading = isQuantityDecreased
    ? "Decrease Quantity"
    : "Delete Confirmation";
  const confirmationAlert = isQuantityDecreased
    ? "Are you sure you want to Decrease Quantity ?"
    : "Are you sure you want to Delete ?";
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{confirmationHeading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="primary">{confirmationAlert}</Alert>
          <Form>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Write description here..."
                rows={2}
                onChange={(e: any) => {
                  setCancelDescription(e.target.value);
                }}
                value={cancelDescription}
                autoFocus
              />
            </Form.Group>
          </Form>
          {isQuantityDecreased && (
            <div className="d-flex justify-content-between pe-3">
              <div>Decreased Quantity</div>
              <div className="d-flex ">
                <i
                  onClick={() => {
                    if (
                      !_.isNil(decreasedQuantity) &&
                      decreasedQuantity !== 0
                    ) {
                      setDecreasedQuantity(decreasedQuantity - 1);
                    }
                  }}
                  className={`fa fa-minus-circle me-3 pointer ${styles.icon}`}
                />
                <h5>{decreasedQuantity}</h5>
                <i
                  className={`fa fa-plus-circle  pointer ms-2 ${styles.icon}
                `}
                  onClick={() => {
                    if (
                      !_.isNil(decreasedQuantity) &&
                      decreasedQuantity !== initialQuantity
                    ) {
                      setDecreasedQuantity(decreasedQuantity + 1);
                    }
                  }}
                />
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={!descriptionHasLength}
            onClick={() => {
              deleteKotItem(cancelDescription);
              setShow(showModelOnResponse);
            }}
          >
            {isQuantityDecreased ? "Update" : "Delete"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default KotDeleteModel;
