import React, { useCallback, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { getActiveRestaurantId } from "../../common/Utils";
import { getWaiterListOfCompany } from "../../services/AdminService";
import { useCommon } from "../Context/CommonContext";
import _ from "lodash";

const SearchWaiter = (props: any) => {
  const { dispatch } = useCommon();
  const [waiterLists, setWaiterLists] = useState([]);
  const [selectedWaiterId, setSelectedWaiterId] = useState<number>();

  const handleOption = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedWaiterId(Number(e.target.value));
  };

  const init = useCallback(() => {
    getWaiterListOfCompany(getActiveRestaurantId())
      .then((response) => {
        setWaiterLists(response);
      })
      .catch(() => {
        dispatch({
          type: "toast",
          payload: {
            visible: true,
            message: "An error has occurred while processing your request.",
          },
        });
      });
  }, [dispatch]);

  useEffect(() => {
    init();
    return () => {
      setWaiterLists([]); // This worked for me
    };
  }, [dispatch, init]);

  const handleAdd = (e: any) => {
    e.preventDefault();
    props.handleOrderDataPatch(
      props.activeCustomer,
      props.initialNumberOfGuest,
      selectedWaiterId
    );
    dispatch({
      type: "toast",
      payload: {
        visible: true,
        message: "Waiter assigned",
      },
    });
  };

  return (
    <>
      <form onSubmit={handleAdd}>
        <Row className="p-2">
          <Col xs={8}>
            <Form.Group>
              <select
                className="form-select"
                onChange={handleOption}
                defaultValue={
                  _.isNil(props?.initialWaiterId)
                    ? "DEFAULT"
                    : props.initialWaiterId
                }
              >
                <option value="DEFAULT" disabled>
                  Select Waiter
                </option>
                {waiterLists.map((node: any) => (
                  <option key={node.user.id} value={node.user.id}>
                    {node.user.username}
                  </option>
                ))}
              </select>
            </Form.Group>
          </Col>
          <Col>
            <button type="submit" className="btn btn-primary">
              Apply
            </button>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default SearchWaiter;
