import React, { useCallback, useEffect, useState } from "react";
import HeadingTitle from "../../components/HeadingTitle";
import NoResultFound from "../../components/NoResultFound";
import { Stack, Table } from "react-bootstrap";
import { getLocalDateTime, getActiveRestaurantId } from "../../common/Utils";
import ButtonComponent, {
  Icons,
} from "../../components/Buttons/ButtonComponent";
import { getCurrentSession, getSessionList } from "../../services/AdminService";
import { useCommon } from "../../components/Context/CommonContext";
import { Session } from "../../class/Session";
import { useNavigate } from "react-router-dom";
import { PageRoutes } from "../../common/Constants";

const SessionList = () => {
  const { dispatch } = useCommon();
  const navigate = useNavigate();

  const [sessionList, setSessionList] = useState<Session[]>([]);
  const [currentPage] = useState(1);

  const getCurrentSessionData = useCallback(() => {
    dispatch({ type: "loading", payload: true });
    getCurrentSession(getActiveRestaurantId()).then((response: Session) => {
      dispatch({ type: "loading", payload: false });
      setSessionList([response]);
    });
  }, [dispatch]);

  const getSessionData = useCallback(
    (page: number) => {
      dispatch({ type: "loading", payload: true });
      getSessionList(getActiveRestaurantId(), page).then((response: any) => {
        dispatch({ type: "loading", payload: false });
        if (response.results.length === 0) {
          getCurrentSessionData();
        } else {
          setSessionList(response?.results);
        }
      });
    },
    [dispatch, getCurrentSessionData]
  );

  useEffect(() => {
    getSessionData(currentPage);
  }, [currentPage, getSessionData]);

  return (
    <div className="container-fluid pt-4 px-4">
      <div className="d-flex align-items-center justify-content-between">
        <HeadingTitle title="Session History" />
        <ButtonComponent
          tooltip={"Create Session"}
          variant={"primary"}
          type={Icons.Add}
          isButton={true}
          onSubmitClick={(e: any) => {
            if (
              window.confirm(
                "You are about create a new session. Do you want to proceed?"
              )
            ) {
              navigate(PageRoutes.SESSION_OPEN);
            }
          }}
        />
      </div>
      {sessionList.length === 0 ? (
        <NoResultFound message="No results found" />
      ) : (
        <Table
          borderless
          responsive
          className="mt-2"
          style={{ minHeight: "200px" }}
        >
          <thead>
            <tr>
              <th>Dates</th>
              <th>Cash (NRs)</th>
              <th>Open Desc</th>
              <th>Close Desc</th>
              <th>Staff</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {sessionList.map((node: Session) => (
              <tr
                key={node.id}
                className={node.closed_at ? "bg-white" : "bg-light"}
              >
                <td className="bg-transparent">
                  <Stack>
                    <p>Created: {getLocalDateTime(node.created_at)}</p>
                    <p>Closed: {getLocalDateTime(node.closed_at)}</p>
                  </Stack>
                </td>
                <td className="bg-transparent">
                  <Stack>
                    <p>
                      System: <b>{node.system_cash}</b>
                    </p>
                    <p>
                      Open: <b>{node.opening_cash}</b>
                    </p>
                    <p>
                      Close: <b>{node.closing_cash}</b>
                    </p>
                  </Stack>
                </td>
                <td className="bg-transparent">{node.opening_description}</td>
                <td className="bg-transparent">{node.closing_description}</td>
                <Stack className="bg-transparent">
                  <p>
                    Open :{" "}
                    <b>{node.opening_staff && node.opening_staff.username}</b>
                    <b>{!node.opening_staff && "N/A"}</b>
                  </p>
                  <p>
                    Close :{" "}
                    <b>{node.closing_staff && node.closing_staff.username}</b>
                    <b>{!node.closing_staff && "N/A"}</b>
                  </p>
                </Stack>
                <td className="bg-transparent">
                  {node.closed_at == null && (
                    <ButtonComponent
                      tooltip={"Close Session"}
                      variant={"primary"}
                      type={Icons.Close}
                      isButton={false}
                      onSubmitClick={(e: any) => {
                        navigate(PageRoutes.SESSION_CLOSE);
                      }}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default SessionList;
