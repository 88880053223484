import React, { Fragment, useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { searchCategoryList } from "../../services/AdminService";
import { CategoryItem, CategoryOptions } from "../../class/Expense";
import { capitalizeFirstLetter } from "../../common/Utils";

const ExpenseCategoryAddComponent = (props: {
  setTotalAmount: any;
  setCategoryList: any;
}) => {
  const [categoryItems, setCategoryItems] = useState<CategoryItem[]>([]);
  const [categoryOptions, setCategoryOptions] = useState<CategoryOptions[]>([]);

  const [selectedCategory, setSelectedCategory] = useState<any>();
  const [description, setDescription] = useState<string>("");
  const [amount, setAmount] = useState<number>(0);

  useEffect(() => {
    searchCategoryList()
      .then((response: any) => {
        const newList = response.results;
        newList.push({ id: null, name: "" });
        setCategoryOptions(
          newList.map((item: CategoryOptions) => {
            item.name = capitalizeFirstLetter(item.name);
            if (item.has_child) {
              item.disabled = true;
              return item;
            }
            return item;
          })
        );
      })
      .catch(() => {});
  }, []);

  const calculateTotal = (categoryItems: CategoryItem[]) => {
    let total = 0;
    categoryItems.flatMap((item: CategoryItem) => {
      total = item.amount + total;
      return false;
    });

    props.setTotalAmount(total);
  };

  const clearInputs = () => {
    setSelectedCategory([]);
    setDescription("");
    setAmount(0);
  };

  const handleAddItem = (e: any) => {
    e.preventDefault();
    if (selectedCategory !== undefined && selectedCategory.length > 0) {
      categoryItems.push({
        category: selectedCategory[0],
        amount: amount,
        description: description,
      });
      clearInputs();

      setCategoryItems(categoryItems);
      props.setCategoryList(categoryItems);

      calculateTotal(categoryItems);
    }
  };

  const handleDeleteItem = (index: number) => {
    if (categoryItems.length > 0) {
      const updatedItems = [...categoryItems];
      updatedItems.splice(index, 1);
      setCategoryItems(updatedItems);
      props.setCategoryList(updatedItems);
    }
  };

  return (
    <div className="mt-5">
      <h6>Category Details</h6>
      <table className="table">
        <thead>
          <th className="p-2">#</th>
          <th className="p-2">Category</th>
          <th className="p-2">Description</th>
          <th className="p-2">Amount</th>
          <th className="p-2">Action</th>
        </thead>
        <tbody>
          {categoryItems.map((item, index) => (
            <tr key={index}>
              <td style={{ width: "5%" }} colSpan={1}>
                {index + 1}
              </td>
              <td style={{ width: "20%" }} colSpan={1}>
                {item.category.name}
              </td>
              <td style={{ width: "40%" }} colSpan={1}>
                {item.description}
              </td>
              <td>{item.amount}</td>
              <td>
                <button
                  className="btn btn-outline-info"
                  onClick={() => {
                    handleDeleteItem(index);
                  }}
                >
                  <i className="fa fa-trash" /> Remove
                </button>
              </td>
            </tr>
          ))}
          <tr>
            <td style={{ width: "5%" }} colSpan={1}></td>
            <td style={{ width: "20%" }} colSpan={1}>
              <Typeahead
                id="input-size-example"
                labelKey="name"
                onChange={setSelectedCategory}
                options={categoryOptions}
                placeholder="Select category"
                size="sm"
                selected={selectedCategory}
                renderMenuItemChildren={(option: any) => (
                  <Fragment>
                    <div>
                      {option.parent != null ? (
                        <span className="m-2">{option.name}</span>
                      ) : (
                        <span>{option.name}</span>
                      )}
                    </div>
                  </Fragment>
                )}
              />
            </td>
            <td style={{ width: "40%" }} colSpan={1}>
              <textarea
                rows={3}
                className="form-control"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              ></textarea>
            </td>
            <td>
              <div className="d-flex justify-content-between align-items-center">
                <input
                  type="number"
                  className="form-control"
                  onWheel={(e) => e.currentTarget.blur()}
                  value={amount}
                  onChange={(e) => {
                    setAmount(parseFloat(e.target.value));
                  }}
                  style={{ height: "32px" }}
                ></input>
              </div>
            </td>
            <td>
              <button className="btn btn-primary" onClick={handleAddItem}>
                <i className="fa fa-plus" /> Add
              </button>

              <button
                className="btn btn-outline-primary ms-2"
                onClick={() => {
                  clearInputs();
                }}
              >
                <i className="fa fa-eraser" /> Clear
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ExpenseCategoryAddComponent;
