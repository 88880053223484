import React, { useEffect, useState } from "react";
import { Row, Col, Table, Button } from "react-bootstrap";
import ButtonComponent, {
  Icons,
} from "../../components/Buttons/ButtonComponent";
import HeadingTitle from "../../components/HeadingTitle";
import {
  addCreditLimitToCustomer,
  getCompanyCustomers,
} from "../../services/AdminService";
import { useCommon } from "../../components/Context/CommonContext";
import VerticallyCenteredModal from "../../components/Model/VerticallyCenteredModel";
import { SubmitHandler, useForm } from "react-hook-form";
import NoResultFound from "../../components/NoResultFound";
import CustomerCreateModal from "./CustomerCreateModal";
import { MessageStrings } from "../../common/Constants";
import UserSearchDialog from "../../components/User/UserSearchDialog";
import ImageViewers from "../../components/ImageViewer/ImageViewers";
type creditLimit = {
  credit_allowed: number;
  credit_limit: number;
};
const CustomerList = () => {
  const [showCreateCustomerDialog, setShowCreateCustomerDialog] =
    useState(false);

  const [showAssignCustomerDialog, setShowAssignCustomerDialog] =
    useState(false);

  const [customerList, setCustomerList] = useState([]);
  const { dispatch } = useCommon();
  const [showModel, setShowModel] = useState<number | null>(null);
  const [activeCustomerId, setActiveCustomerId] = useState<number>();
  const { register, handleSubmit, setValue } = useForm<creditLimit>({
    criteriaMode: "all",
  });

  useEffect(() => {
    dispatch({ type: "loading", payload: false });
    getCompanyCustomers()
      .then((response) => {
        if (response) {
          dispatch({ type: "loading", payload: false });
          setCustomerList(response.results);
        }
      })
      .catch((error) => {
        dispatch({
          type: "messageModal",
          payload: {
            visible: true,
            title: MessageStrings.ERROR_MESSAGE_TITLE,
            message: MessageStrings.ERROR_MESSAGE_GENERIC,
          },
        });
      });
    return () => {};
  }, [dispatch]);

  const addCreditLimit: SubmitHandler<creditLimit> = (event: any) => {
    dispatch({ type: "loading", payload: true });

    if (event.credit_allowed === "0") {
      event.credit_allowed = false;
    } else {
      event.credit_allowed = true;
    }

    addCreditLimitToCustomer(Number(activeCustomerId), event)
      .then((response: any) => {
        if (response) {
          dispatch({ type: "loading", payload: false });

          setShowModel(null);
          setActiveCustomerId(undefined);

          dispatch({
            type: "toast",
            payload: {
              visible: true,
              message: "Credit edit Successful",
              success: false,
            },
          });
        }
      })
      .catch(() => {
        dispatch({
          type: "messageModal",
          payload: {
            visible: true,
            title: MessageStrings.ERROR_MESSAGE_TITLE,
            message: MessageStrings.ERROR_MESSAGE_GENERIC,
          },
        });
      });
  };

  return (
    <>
      <div className="container-fluid pt-4 px-4">
        <Row className="mb-4">
          <Col>
            <HeadingTitle title="Customer List" />
          </Col>
          <Col className="text-end">
            <ButtonComponent
              tooltip={"Add Customer"}
              variant={"primary"}
              onSubmitClick={() => {
                setShowAssignCustomerDialog(true);
              }}
              type={Icons.Add}
              isButton={true}
            />
          </Col>
        </Row>
        {customerList.length > 0 && (
          <Table borderless responsive>
            <thead>
              <tr>
                <th>Image</th>
                <th>Username</th>
                <th>Name</th>
                <th>Debit</th>
                <th>Credit</th>
                <th>Balance</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {customerList?.map(
                ({
                  id,
                  user,
                  total_debit,
                  total_credit,
                  credit_allowed,
                  credit_limit,
                }: any) => (
                  <tr key={id}>
                    <td>
                      {user.image_url && (
                        <ImageViewers images={[user.image_url]} />
                      )}
                    </td>
                    <td>{user.username}</td>
                    <td>
                      <a href={`/customer/${user.id}`}>
                        {user.first_name} {user.last_name}
                      </a>
                    </td>
                    <td>{total_debit}</td>
                    <td>{total_credit}</td>
                    <td>{total_debit - total_credit}</td>
                    <ButtonComponent
                      tooltip={"Change credit limit"}
                      variant={"primary"}
                      type={Icons.Edit}
                      isButton={false}
                      onSubmitClick={() => {
                        setShowModel(id);
                        setActiveCustomerId(user.id);

                        setValue("credit_limit", credit_limit);
                        setValue("credit_allowed", credit_allowed ? 1 : 0);
                      }}
                    />
                    {showModel && (
                      <VerticallyCenteredModal
                        model_title="Credit limit"
                        show={showModel === id}
                        onHide={() => setShowModel(null)}
                      >
                        <form onSubmit={handleSubmit(addCreditLimit)}>
                          <label
                            className="form-check-label fw-bold mb-2"
                            htmlFor="disabledFieldsetCheck"
                          >
                            Allow Credit
                          </label>
                          <select
                            className="form-select"
                            {...register("credit_allowed", {
                              required: "Required",
                            })}
                          >
                            <option value={0}>No</option>
                            <option value={1}>Yes</option>
                          </select>
                          <label
                            className="form-check-label fw-bold mb-2"
                            htmlFor="disabledFieldsetCheck"
                          >
                            Credit Limit
                          </label>
                          <input
                            className="form-control"
                            type="number"
                            onWheel={(e) => e.currentTarget.blur()}
                            placeholder="Credit Limit"
                            {...register("credit_limit", {
                              required: "Required",
                            })}
                          />
                          <Button className="mt-3" type="submit">
                            Save
                          </Button>
                        </form>
                      </VerticallyCenteredModal>
                    )}
                  </tr>
                )
              )}
            </tbody>
          </Table>
        )}

        {customerList.length === 0 && (
          <NoResultFound message={"No Results Found"} />
        )}
      </div>
      <CustomerCreateModal
        onCreateSuccess={() => {}}
        setShowCreateCustomerDialog={setShowCreateCustomerDialog}
        showCreateCustomerDialog={showCreateCustomerDialog}
      />
      <UserSearchDialog
        setShowAssignCustomerDialog={setShowAssignCustomerDialog}
        showAssignCustomerDialog={showAssignCustomerDialog}
      />
    </>
  );
};

export default CustomerList;
