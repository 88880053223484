import { Inventory, StockItem } from "../class/Inventory";
import { BASE_PATH } from "../common/Constants";
import fetchWrapper from "../common/FetchWrapper";
import { getActiveRestaurantId } from "../common/Utils";
export const getInventoryList = async (
  debouncedSearchValue: string,
  currentPage: number,
  pageSize: number
) => {
  const queryParams = new URLSearchParams();
  if (debouncedSearchValue && debouncedSearchValue.trim() !== "") {
    queryParams.append("search", debouncedSearchValue);
  }
  if (currentPage !== null && currentPage !== undefined) {
    queryParams.append("page", currentPage.toString());
  }
  if (pageSize !== null && pageSize !== undefined) {
    queryParams.append("page_size", pageSize.toString());
  }
  const url = `${BASE_PATH}api/company/${getActiveRestaurantId()}/stock-item/?${queryParams.toString()}`;
  return await fetchWrapper.get(url);
};

export const addInventory = async (data: Inventory) => {
  return await fetchWrapper.post(
    `${BASE_PATH}api/company/${getActiveRestaurantId()}/stock-item/`,
    data
  );
};

export const addStock = async (data: StockItem) => {
  return await fetchWrapper.post(
    `${BASE_PATH}api/company/${getActiveRestaurantId()}/stock-item/transaction/`,
    data
  );
};

export const deleteInventory = async (inventoryId: number) => {
  return await fetchWrapper.delete(
    `${BASE_PATH}api/company/${getActiveRestaurantId()}/stock-item/${inventoryId}`
  );
};

export const getInventoryDetail = async (id: string) => {
  return await fetchWrapper.get(
    `${BASE_PATH}api/company/${getActiveRestaurantId()}/stock-item/${id}`
  );
};

export const editInventory = async (id: string, data: Inventory) => {
  return await fetchWrapper.patch(
    `${BASE_PATH}api/company/${getActiveRestaurantId()}/stock-item/${id}/`,
    data
  );
};

export const searchInventory = async (search: string) => {
  return await fetchWrapper.get(
    `${BASE_PATH}api/company/${getActiveRestaurantId()}/stock-item/?name=${search}`
  );
};

export const getInventoryTransactionsList = async (
  id: number,
  debouncedSearchValue: string,
  currentPage: number,
  pageSize: number,
  startDate: any,
  endDate: any
) => {
  const queryParams = new URLSearchParams();
  if (debouncedSearchValue && debouncedSearchValue.trim() !== "") {
    queryParams.append("search", debouncedSearchValue);
  }
  if (currentPage !== null && currentPage !== undefined) {
    queryParams.append("page", currentPage.toString());
  }
  if (pageSize !== null && pageSize !== undefined) {
    queryParams.append("page_size", pageSize.toString());
  }
  if (startDate !== null && startDate !== undefined) {
    queryParams.append("from", startDate.toISOString().slice(0, 10));
  }
  if (endDate !== null && endDate !== undefined) {
    queryParams.append("to", endDate.toISOString().slice(0, 10));
  }
  const url = `${BASE_PATH}api/company/${getActiveRestaurantId()}/stock-item/transaction/?stock_item=${id}&${queryParams.toString()}`;
  return await fetchWrapper.get(url);
};
