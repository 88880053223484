import { faArrowLeft, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import Stack from "react-bootstrap/Stack";
import { useNavigate } from "react-router-dom";
import { getLocalDateTime } from "../../common/Utils";
import { useCommon } from "../../components/Context/CommonContext";
import { deletePayment } from "../../services/AdminService";
import "react-datepicker/dist/react-datepicker.css";
import ButtonComponent, {
  Icons,
} from "../../components/Buttons/ButtonComponent";
import HeadingTitle from "../../components/HeadingTitle";
import NoResultFound from "../../components/NoResultFound";
import { useDebounce } from "use-debounce";
import { MeasuringUnitType } from "../../class/MeasuringUnitType";
import { getMeasuringUnits } from "../../services/measuringUnitApi";

const MeasuringUnit = (): JSX.Element => {
  const [measuringUnit, setMeasuringUnit] = useState<MeasuringUnitType[]>([]);
  const { dispatch } = useCommon();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [debouncedSearchValue] = useDebounce(searchValue, 1000);

  useEffect(() => {
    dispatch({ type: "loading", payload: true });
    getMeasuringUnits(debouncedSearchValue)
      .then((response: any) => {
        dispatch({ type: "loading", payload: false });
        setMeasuringUnit(response);
      })
      .catch(() => {});
  }, [debouncedSearchValue, dispatch]);

  const handleDelete = (e: any, unitId: any) => {
    e.preventDefault();
    // eslint-disable-next-line no-restricted-globals
    let confirmValue = confirm("Are you sure to delete inventory ?");
    if (confirmValue === true) {
      deletePayment(unitId)
        .then((response) => {
          if (response) {
            window.location.reload();
          }
        })
        .catch((error: any) => {
          dispatch({
            type: "toast",
            payload: {
              visible: true,
              title: "Error !",
              message: error.detail,
            },
          });
        });
    }
  };
  return (
    <div className="mt-4 mx-4">
      <Row className="d-flex justify-content-between align-items-center">
        <Col
          className="d-flex align-items-baseline"
          sm={6}
          md={6}
          lg={6}
          xl={6}
        >
          <FontAwesomeIcon
            className="text-primary pointer me-2"
            icon={faArrowLeft}
            onClick={() => navigate(-1)}
          />
          <HeadingTitle title="Measuring Units" />
        </Col>
        <Col sm={2} md={5} lg={5} xl={2}>
          <Stack direction="horizontal">
            <input
              className="form-control"
              placeholder="Search.."
              type="text"
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
            <Button type="submit" className="m-2 text-primary fw-bold">
              <FontAwesomeIcon className="text-white" icon={faSearch} />
            </Button>
          </Stack>
        </Col>
      </Row>
      {measuringUnit.length === 0 ? (
        <NoResultFound message="No results" />
      ) : (
        <Table borderless responsive className="mt-2">
          <thead>
            <tr>
              <th>Date</th>
              <th>Name</th>
              <th>Symbol</th>
              <th>Description</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {measuringUnit.map(
              ({
                id,
                name,
                symbol,
                description,
                created_at,
              }: MeasuringUnitType) => (
                <tr key={id}>
                  <td>{getLocalDateTime(created_at)}</td>
                  <td className="pointer text-primary">{name}</td>
                  <td>{symbol}</td>
                  <td>{description}</td>
                  <td>
                    <ButtonComponent
                      tooltip={"Delete"}
                      variant={"primary"}
                      type={Icons.Delete}
                      isButton={false}
                      onSubmitClick={(e: any) => handleDelete(e, id)}
                    />
                  </td>
                </tr>
              )
            )}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default MeasuringUnit;
