export const BASE_PATH = process.env.REACT_APP_API_URL;

export const ADMIN_USERNAME = "foodieho-admin-username";
export const DATA_ADMIN_USER = "admin-user-data";
export const ADMIN_USER = "admin-session";

export const PROFILE_IMAGE = "/img/profile.png";
export const API_GET_TOKEN = "api/dashboard/token/";
export const API_GET_COMPANY = "api/company/";
export const API_GET_ACCOUNT = "api/account/";
export const API_GET_PRODUCT = "api/company/1/product";
export const API_GET_USER_DETAIL = "api/account/me";
export const API_GET_TOPUSERS = "api/reputation/top/?page_size=10";
export const API_GET_REPUTATION = "api/reputation/";
export const API_POST_COMPANY = "api/company/";
export const API_POST_PRODUCT = "product/";
export const API_DASHBOARD_OVERVIEW = "api/dashboard/overview";
export const API_GET_COMPANY_DETAIL = "api/company";
export const API_GET_SIGNUP_DATA_ = "api/dashboard/signup";
export const API_DASHBOARD = "api/dashboard/";
export const BANK_PAYMENT_METHODS = "/bank-account/payment-method/";
export const API_PRODUCT_INGREDIENT = "/ingredient/";

export const DATA_ACTIVE_RESTRO_SESSION = "active-restro-session";

export const getKotListApi = (companyId: number) => {
  return BASE_PATH + API_GET_COMPANY + companyId + "/kot/";
};

export enum PageRoutes {
  LOGIN = "/login",
  DASHBOARD = "/",
  REVIEWS = "/reviews",
  NEW_ORDER = "/new-order",
  ORDER_LIST = "/order-history",
  ORDER = "/order",
  INVOICES = "/invoices",
  INVOICES_DETAIL = "/invoices/:id/detail",
  LABELS = "/labels",
  LABEL = "/label",
  USERS = "/users",
  COMPANY = "/company",
  PRODUCTS = "/products",
  EMPlOYEES = "/employees",
  MENU = "/menu",
  TABLE_GROUP = "/table-group",
  TABLE = "/tables",
  NEW_TABLE = "new-table",
  KOT = "/kot",
  KOT_HISTORY = "/kot-history",
  PROFILE = "/profile",
  EXPENSE = "/expense",
  SESSION_HISTORY = "/session-history",
  SESSION_CLOSE = "/session-close",
  SESSION_OPEN = "/session-open",
  BANKING = "/banking",
  NEW_BANKING = "/new-banking",
  PAYMENT_METHODS = "/payment-methods",
  INVENTORY = "/inventory",
  NEW_INVENTORY = "/new-inventory",
  EDIT_INVENTORY = "inventory/:id/edit/",
  INVENTORY_TRANSACTIONS = "/inventory/:id/transactions",
  ADD_INVENTORY_ITEM = "/inventory/:id/add-stock",
  MEASURING_UNITS = "/inventory/measuring-units",
  SUPPLIER = "/supplier",
  NEW_SUPPLIER = "/supplier/new",
  EDIT_SUPPLIER = "/supplier/:id/edit",
  SUPPLIER_DETAIL = "/supplier/:id",
  CUSTOMER = "/customer",
  NEW_CUSTOMER = "/customer/new",
  EDIT_CUSTOMER = "/customer/:id/edit",
  CUSTOMER_DETAIL = "/customer/:id",
  NEW_EXPENSE = "/expense/add",
  SETTLEMENT = "/settlement",
  NEW_SETTLEMENT = "/settlement/new",
  CHANGE_PASSWORD = "/change-password",
}

export enum MessageStrings {
  ERROR_MESSAGE_TITLE = "Something went wrong!",
  ERROR_MESSAGE_GENERIC = "An unexpected error has occurred. Please try again later or contact support if the issue persists. We apologize for any inconvenience.",
}
