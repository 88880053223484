import React, { useState, useCallback } from "react";
import ImageViewer from "react-simple-image-viewer";
function ImageViewers(props: {
  images: any;
  placeholder?: boolean;
  styles?: any;
}) {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const imagesObjects = props.images;
  const openImageViewer = useCallback((index: any) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  return (
    <div>
      {imagesObjects.length !== 0 &&
        imagesObjects
          .slice(0, 3)
          .map(
            (
              obj: { image_url: string | undefined },
              index: React.Key | null | undefined
            ) =>
              obj.image_url === "null" && props.placeholder ? (
                <img
                  key={index}
                  src="img/default_placeholder.png"
                  style={{ width: "25px", height: "25px", objectFit: "cover" }}
                  alt="Images"
                />
              ) : (
                <img
                  src={obj.image_url}
                  onClick={() => openImageViewer(index)}
                  width={props.styles !== undefined ? props.styles.width : "60"}
                  key={index}
                  className="rounded"
                  style={{
                    margin: "2px",
                    zIndex: "5",
                    width: "25px",
                    height: "25px",
                    objectFit: "cover",
                  }}
                  alt=""
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "/img/default_placeholder.png";
                  }}
                />
              )
          )}

      {props.placeholder
        ? " "
        : imagesObjects.length === 0 && (
            <img
              src="/img/default_placeholder.png"
              style={{ width: "25px", height: "25px", objectFit: "cover" }}
              alt="Default Images"
            />
          )}

      {isViewerOpen && (
        <ImageViewer
          src={imagesObjects.map(function (obj: { [x: string]: any }) {
            return obj["image_url"];
          })}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.7)",
            zIndex: "1021",
          }}
          closeOnClickOutside={true}
        />
      )}
    </div>
  );
}

export default ImageViewers;
