import React, { useEffect, useState } from "react";
import HeadingTitle from "../../components/HeadingTitle";
import NoResultFound from "../../components/NoResultFound";
import { KOT, KOTITEM } from "../../class/kot";
import { useCommon } from "../../components/Context/CommonContext";
import { getKotList } from "../../services/AdminService";
import { getActiveRestaurantId } from "../../common/Utils";
import _, { capitalize } from "lodash";
import { Accordion, Row, Col, Tab, Tabs, Button } from "react-bootstrap";
import styles from "./../../pages/Order/NewOrder.module.css";
import KOTListHeader from "../../components/Kot/KOTListHeader";
import { faCancel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CancelKotDialog from "../../components/NewOrder/CancelKotDialog";
import { MessageStrings } from "../../common/Constants";

const KotList = () => {
  const [kotList, setKotList] = useState<KOT[]>([]);
  const [filteredKotList, setFilteredKotList] = useState<KOT[]>([]);

  const [activeStatusTab, setActiveStatusTab] = useState<string>("ongoing");

  const [showCancelKotDialog, setShowCancelKotDialog] = useState(false);
  const [activeKotIdToCancel, setActiveKotIdToCancel] = useState<number | null>(
    null
  );

  const { dispatch } = useCommon();

  useEffect(() => {
    const filteredKotList = kotList.filter((node) => {
      switch (activeStatusTab) {
        case "ongoing":
          return node.status === "ONGOING";

        case "served":
          return node.status === "SERVED";

        case "cancelled":
          return node.status === "CANCELLED";
      }
      return false;
    });

    setFilteredKotList(filteredKotList);
  }, [activeStatusTab, kotList]);

  useEffect(() => {
    dispatch({ type: "loading", payload: true });
    getKotList(getActiveRestaurantId(), null, null, true, 1, 50)
      .then((response: any) => {
        dispatch({ type: "loading", payload: false });
        setKotList(response?.results);
      })
      .catch((error) => {
        dispatch({
          type: "messageModal",
          payload: {
            visible: true,
            title: MessageStrings.ERROR_MESSAGE_TITLE,
            message: MessageStrings.ERROR_MESSAGE_GENERIC,
          },
        });
      });
  }, [dispatch]);

  return (
    <>
      <div className="container-fluid pt-4 px-4">
        <div className="d-flex align-items-center justify-content-between">
          <HeadingTitle title="KOT Status" />
        </div>
        {kotList.length === 0 ? (
          <NoResultFound message="No results found" />
        ) : (
          <>
            <Tabs
              defaultActiveKey={activeStatusTab}
              className="mb-4"
              justify={true}
              onSelect={(eventKey) => {
                if (eventKey != null) {
                  setActiveStatusTab(eventKey);
                }
              }}
            >
              <Tab eventKey="ongoing" title="ONGOING"></Tab>
              <Tab eventKey="served" title="SERVED"></Tab>
              <Tab eventKey="cancelled" title="CANCELED"></Tab>
            </Tabs>
            <Row className="g-2">
              {filteredKotList.length === 0 && (
                <p className="text-muted">No results found</p>
              )}
              {filteredKotList.map((singleKot: KOT, index: number) => (
                <Col xs={6} sm={4} md={2} key={index}>
                  <Accordion
                    key={singleKot.id}
                    className="kot-accordion"
                    defaultActiveKey={singleKot.c_kot_id.toString()}
                  >
                    <Accordion.Item
                      eventKey={singleKot.c_kot_id.toString()}
                      className="mb-2"
                    >
                      <Accordion.Header>
                        <KOTListHeader singleKot={singleKot} />
                      </Accordion.Header>

                      <Accordion.Body>
                        <div>
                          {singleKot?.items.map((node: KOTITEM) => (
                            <Row
                              key={node.id}
                              className={`bg-white g-0 mb-2 mt-2 ${styles.product}`}
                            >
                              <Col xs={10} className="pointer d-flex">
                                <p
                                  className={`mb-0 me-1 ${
                                    node.quantity === 0
                                      ? "text-decoration-line-through text-muted"
                                      : ""
                                  }`}
                                >
                                  {capitalize(node.name)}
                                </p>
                                {!_.isNil(node.description) && (
                                  <span
                                    className={`${
                                      node.quantity === 0
                                        ? "text-decoration-line-through text-muted"
                                        : ""
                                    }`}
                                  >
                                    Note: {node.description}
                                  </span>
                                )}
                              </Col>

                              <Col
                                xs={2}
                                className={`d-flex ${
                                  node.quantity === 0 ? "text-muted" : ""
                                }`}
                              >
                                <p>{node.quantity}</p>
                              </Col>
                            </Row>
                          ))}

                          {singleKot.deleted ||
                          (singleKot.items.length === 1 &&
                            singleKot.items[0].quantity === 0) ? (
                            <></>
                          ) : (
                            <Row className="g-0">
                              <Button
                                variant="outline-primary"
                                onClick={() => {
                                  setActiveKotIdToCancel(singleKot.id);
                                  setShowCancelKotDialog(true);
                                }}
                              >
                                <FontAwesomeIcon icon={faCancel} size="sm" />{" "}
                                Cancel KOT
                              </Button>
                            </Row>
                          )}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>
              ))}
            </Row>
          </>
        )}
      </div>

      <CancelKotDialog
        showCancelKotDialog={showCancelKotDialog}
        setShowCancelKotDialog={setShowCancelKotDialog}
        kotId={activeKotIdToCancel}
      />
    </>
  );
};

export default KotList;
