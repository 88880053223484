import {
  faCancel,
  faCircleCheck,
  faCircleXmark,
  faClose,
  faEdit,
  faMoneyBill,
  faPlusCircle,
  faTrash,
  faUtensils,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Button, Tooltip } from "react-bootstrap";
import { checkCompanyRoles } from "../../common/Utils";
export const Icons = {
  Add: faPlusCircle,
  Edit: faEdit,
  Delete: faTrash,
  Utensils: faUtensils,
  Disable: faCircleXmark,
  Enable: faCircleCheck,
  Close: faClose,
  Cancel: faCancel,
  Payment: faMoneyBill,
  FaCircleXmark: faCircleXmark,
};

const ButtonComponent = (props: any) => {
  if (props.company_roles !== undefined) {
    if (!checkCompanyRoles(props.company_roles)) {
      return <></>;
    }
  }

  return (
    <a
      className="sm-6 gray pointer"
      href={props.href}
      onClick={props.onSubmitClick}
    >
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="button-tooltip" style={{ position: "fixed" }}>
            {props.tooltip}
          </Tooltip>
        }
      >
        <span>
          {props.isButton ? (
            <Button variant={props.variant} className="text-white me-2">
              <FontAwesomeIcon icon={props.type} /> {props.label}
            </Button>
          ) : (
            <FontAwesomeIcon
              className="mx-2"
              size="1x"
              icon={props.type}
              aria-hidden="true"
            />
          )}
        </span>
      </OverlayTrigger>
    </a>
  );
};
export default ButtonComponent;
