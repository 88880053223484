import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import _ from "lodash";
import { getActiveRestaurantId } from "../../common/Utils";
import { deleteKot } from "../../services/AdminService";
import { useCommon } from "../Context/CommonContext";

function CancelKotDialog({
  showCancelKotDialog,
  setShowCancelKotDialog,
  kotId,
}: {
  showCancelKotDialog: boolean;
  setShowCancelKotDialog: any;
  kotId: number | null;
}) {
  const { dispatch } = useCommon();
  // const navigate = useNavigate();

  const handleCloseDialog = () => setShowCancelKotDialog(false);

  const handleCancelKot = (event: any) => {
    event.preventDefault();

    if (!_.isNil(kotId) && !_.isNil(event.target[0].value)) {
      deleteKot(getActiveRestaurantId(), kotId, {
        deleted_description: event.target[0].value,
      })
        .then((response) => {
          dispatch({
            type: "toast",
            payload: {
              visible: true,
              title: "Success",
              message: "Kot has been canceled.",
            },
          });
          handleCloseDialog();
          window.location.reload();
        })
        .catch((error) => {
          dispatch({
            type: "toast",
            payload: {
              visible: true,
              title: "Error",
              message: "Error ! Try again",
            },
          });
        });
    }
  };

  return (
    <Modal show={showCancelKotDialog} onHide={handleCloseDialog} centered>
      <Modal.Title className="p-3">
        Do you want to cancel this KOT #{kotId}?
      </Modal.Title>
      <Modal.Body className="p-3">
        <Form onSubmit={handleCancelKot}>
          <Form.Group className="mb-3" controlId="description">
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter reason for cancelling kot"
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="me-2">
            Confirm
          </Button>

          <Button
            variant="secondary"
            onClick={() => {
              handleCloseDialog();
            }}
          >
            Cancel
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default CancelKotDialog;
