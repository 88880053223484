import React, { useState } from "react";
import { Badge, ListGroup, OverlayTrigger, Popover } from "react-bootstrap";
import SeachBox from "../SearchBox/SeachBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import {
  faCircleChevronDown,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import {
  getActiveRestaurant,
  getActiveUserCompanyRole,
  getCompanyRoles,
  getTrimmedRestaurantName,
  isSuperUser,
  saveActiveRestaurant,
} from "../../common/Utils";
import styles from "../Sidebar/Sidebar.module.css";
import useWindowSize from "../../common/customScreenSizeHook";

const CompanySwitch = () => {
  const navigate = useNavigate();
  const size = useWindowSize();
  const [companyRoles] = useState(getCompanyRoles());
  const [searchList, setSearchList] = useState([]);
  const [searchCount, setSearchCount] = useState<number>();
  const [activeRestro, setActiveRestro] = useState<any>(getActiveRestaurant());
  const getCompanySearchResults = (searchResultFromChild: any) => {
    setSearchList(searchResultFromChild.results);
    setSearchCount(searchResultFromChild.count);
  };

  return (
    <div className="d-flex p-1">
      <OverlayTrigger
        trigger="click"
        placement="bottom"
        rootClose
        overlay={
          <Popover id="popover-basic">
            <Popover.Header as="p">Select Account</Popover.Header>

            <Popover.Body>
              {isSuperUser() ? (
                <>
                  <SeachBox
                    parent={getCompanySearchResults}
                    page_size={6}
                    type="company"
                  />
                  {searchList.length > 0 ? (
                    <ListGroup as="ol">
                      {searchList.map((node: any, index: number) => (
                        <ListGroup.Item
                          action
                          key={node.id}
                          onClick={() => {
                            setActiveRestro(node);
                            saveActiveRestaurant(node);
                            navigate(0);
                          }}
                          as="li"
                          className="d-flex justify-content-between align-items-start"
                        >
                          <img
                            src={
                              node.logo_url || "/img/default_restro_image.png"
                            }
                            className="pointer"
                            height={60}
                            width={60}
                            alt="images"
                          />

                          <div className="me-auto">
                            <div className="fw-bold pointer">
                              {getTrimmedRestaurantName(node.name, 10)}
                            </div>
                            <div className="pointer">
                              {getTrimmedRestaurantName(node.full_address, 10)}
                            </div>
                          </div>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  ) : searchCount === 0 ? (
                    <span className="text-danger">No result found</span>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  {companyRoles.length > 0 ? (
                    <ListGroup as="ol">
                      {companyRoles.map((node: any, index: number) => (
                        <ListGroup.Item
                          action
                          key={node.id}
                          onClick={() => {
                            setActiveRestro(node.company);
                            saveActiveRestaurant(node.company);
                            navigate(0);
                          }}
                          as="li"
                          className="d-flex justify-content-between align-items-start"
                        >
                          <img
                            src={
                              node.company.logo_url ||
                              "/img/default_restro_image.png"
                            }
                            className="pointer"
                            height={60}
                            width={60}
                            alt="images"
                          />

                          <div className="ms-2 me-auto">
                            <div className="fw-bold pointer">
                              {size.width !== undefined && size?.width < 480
                                ? getTrimmedRestaurantName(
                                    node.company?.name,
                                    10
                                  )
                                : node.company?.name}
                            </div>
                            <div className="pointer">
                              {size.width !== undefined && size?.width < 480
                                ? getTrimmedRestaurantName(
                                    node.company?.full_address,
                                    10
                                  )
                                : node.company.full_address}
                              <br />
                              <Badge pill bg="primary">
                                {getActiveUserCompanyRole(node.company.id)}
                              </Badge>
                            </div>
                          </div>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </Popover.Body>
          </Popover>
        }
      >
        <span className={`ms-3 pointer ${styles.switch_account_title}`}>
          {activeRestro == null ? (
            <div
              className={`d-inline-flex mb-2 justify-content-around align-items-center bg-light ${styles.switch_account}`}
            >
              <div className="fw-bold mx-2">Switch Account</div>
              <FontAwesomeIcon
                icon={faCircleChevronDown}
                className="fa-3 text-primary"
              />
            </div>
          ) : (
            <div className="d-flex">
              <div
                className={`d-inline-flex justify-content-between align-items-center  ${styles.restrodiv}`}
              >
                <img
                  src={activeRestro.logo_url || "/img/default_restro_image.png"}
                  height={35}
                  width={35}
                  style={{ borderRadius: "50%" }}
                  alt="images"
                />
                <div className="ms-3 me-auto">
                  <div className="fw-bold">
                    {size.width !== undefined && size?.width < 480
                      ? getTrimmedRestaurantName(activeRestro.name, 10)
                      : activeRestro.name}
                  </div>
                  {size.width !== undefined && size?.width < 480
                    ? getTrimmedRestaurantName(activeRestro.full_address, 10)
                    : activeRestro.name}
                </div>

                <FontAwesomeIcon
                  icon={faCircleChevronDown}
                  className="fa-3 ms-2 text-primary"
                />
              </div>

              {isSuperUser() && (
                <div
                  className={`d-inline-flex justify-content-around mt-1 align-items-center  ${styles.restrodiv}`}
                  onClick={() => {
                    setActiveRestro(null);
                    saveActiveRestaurant(null);
                    navigate("/");
                  }}
                >
                  <div className="fw-bold ms-3 me-1">
                    Clear Active Restaurant
                  </div>
                  <FontAwesomeIcon
                    icon={faCircleXmark}
                    className="fa-3 text-primary"
                  />
                </div>
              )}
            </div>
          )}
        </span>
      </OverlayTrigger>
    </div>
  );
};

export default CompanySwitch;
