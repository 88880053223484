import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel, faRemove } from "@fortawesome/free-solid-svg-icons";
import styles from "./../../pages/Order/NewOrder.module.css";
import { KOT, KOTITEM } from "../../class/kot";
import { useState } from "react";
import _, { capitalize } from "lodash";
import KotDeleteModel from "../Model/KotDeleteModel";
import { Accordion, Button, Col, Row } from "react-bootstrap";
import KOTListHeader from "../Kot/KOTListHeader";
const KotDetails = ({
  data,
  handleCancelKot,
  showModelOnResponse,
  onAddOrder,
  deleteKotData,
}: {
  data: KOT[] | null;
  handleCancelKot: any;
  showModelOnResponse: boolean;
  onAddOrder: any;
  deleteKotData: any;
}) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [cancelDescription, setCancelDescription] = useState<string>("");
  const [isQuantityDecreased, setQuantityDecreased] = useState<boolean>(false);
  const [initialQuantity, setInitialQuantity] = useState<number>(0);
  const [kotId, setKotId] = useState<number>();
  const [productId, setProductId] = useState<number>();
  const [decreasedQuantity, setDecreasedQuantity] = useState<number>(0);
  const [isDeleteKot, setDeleteKot] = useState<boolean>(false);
  const deleteKotItem = (cancelDescription: string) => {
    let data: {};
    if (isQuantityDecreased) {
      data = {
        items: [
          {
            id: productId,
            quantity: decreasedQuantity,
            cancelled_description: cancelDescription,
          },
        ],
      };
      handleCancelKot(kotId, data);
    } else if (!isQuantityDecreased && isDeleteKot) {
      data = {
        deleted_description: cancelDescription,
      };
      deleteKotData(kotId, data);
    } else {
      data = {
        items: [
          {
            id: productId,
            quantity: 0,
            cancelled_description: cancelDescription,
          },
        ],
      };
      handleCancelKot(kotId, data);
    }
  };

  const deleteKOT = (singleKot: KOT) => {
    setCancelDescription("");
    setKotId(singleKot?.id);
    handleShow();
    setQuantityDecreased(false);
    setDeleteKot(true);
  };

  return (
    <>
      <div>
        {!_.isNil(data) &&
          data.map((singleKot: KOT) => (
            <Accordion key={singleKot.id} className="kot-accordion">
              <Accordion.Item
                eventKey={singleKot.c_kot_id.toString()}
                className="mb-2"
              >
                <Accordion.Header>
                  <KOTListHeader singleKot={singleKot} />
                </Accordion.Header>

                <Accordion.Body>
                  <div>
                    {singleKot?.items.map((node: KOTITEM) => (
                      <Row
                        key={node.id}
                        className={`bg-white g-0 mb-2 mt-2 ${styles.product} `}
                      >
                        <Col
                          xs={1}
                          className="pointer"
                          onClick={(e: any) => {
                            if (node.quantity === 0) {
                              e.preventDefault();
                            } else {
                              setCancelDescription("");
                              setKotId(singleKot?.id);
                              setProductId(node?.id);
                              handleShow();
                              setQuantityDecreased(false);
                            }
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faRemove}
                            className="text-primary"
                          />
                        </Col>

                        <Col xs={5} className="pointer d-flex">
                          <p
                            className={`mb-0 me-1 ${
                              node.quantity === 0
                                ? "text-decoration-line-through"
                                : ""
                            }`}
                          >
                            {capitalize(node.name)}
                          </p>
                          {!_.isNil(node.description) && (
                            <span
                              className={`${
                                node.quantity === 0
                                  ? "text-decoration-line-through"
                                  : ""
                              }`}
                            >
                              Note: {node.description}
                            </span>
                          )}
                        </Col>

                        <Col xs={3}>
                          <span
                            className={`${
                              node.quantity === 0
                                ? "text-decoration-line-through"
                                : ""
                            }`}
                          >
                            {node.price}
                          </span>
                        </Col>

                        <Col xs={3} className="d-flex">
                          <i
                            onClick={(e: any) => {
                              if (node.quantity === 0) {
                                e.preventDefault();
                              } else {
                                setCancelDescription("");
                                setDecreasedQuantity(
                                  _.isNil(node.quantity)
                                    ? 0
                                    : node?.quantity - 1
                                );
                                setInitialQuantity(
                                  _.isNil(node.quantity) ? 0 : node?.quantity
                                );
                                setProductId(node?.id);
                                setKotId(singleKot?.id);
                                setQuantityDecreased(true);
                                handleShow();
                              }
                            }}
                            className={`fa fa-minus-circle me-2 pointer ${styles.icon}`}
                          />
                          <p>{node.quantity}</p>
                          <i
                            className={`fa fa-plus-circle pointer ms-1 text-muted  ${styles.icon}`}
                            onClick={(e: any) => {
                              onAddOrder({
                                ...node,
                                id: node?.product,
                                quantity: 1,
                              });
                            }}
                          />
                        </Col>
                      </Row>
                    ))}

                    {singleKot.deleted ||
                    (singleKot.items.length === 1 &&
                      singleKot.items[0].quantity === 0) ? (
                      <></>
                    ) : (
                      <Row className="g-0">
                        <Button
                          variant="outline-primary"
                          onClick={() => deleteKOT(singleKot)}
                        >
                          <FontAwesomeIcon icon={faCancel} size="sm" /> Cancel
                          KOT
                        </Button>
                      </Row>
                    )}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ))}
      </div>

      <KotDeleteModel
        show={show}
        setShow={setShow}
        initialQuantity={initialQuantity}
        isQuantityDecreased={isQuantityDecreased}
        decreasedQuantity={decreasedQuantity}
        setDecreasedQuantity={setDecreasedQuantity}
        deleteKotItem={deleteKotItem}
        showModelOnResponse={showModelOnResponse}
        cancelDescription={cancelDescription}
        setCancelDescription={setCancelDescription}
      />
    </>
  );
};

export default KotDetails;
