import React, { useEffect, useState } from "react";
import { Row, Col, Table } from "react-bootstrap";
import ButtonComponent, {
  Icons,
} from "../../components/Buttons/ButtonComponent";
import HeadingTitle from "../../components/HeadingTitle";
import { MessageStrings, PageRoutes } from "../../common/Constants";
import { deleteSupplier, getSupplierList } from "../../services/AdminService";
import { useCommon } from "../../components/Context/CommonContext";
import NoResultFound from "../../components/NoResultFound";

const SupplierList = () => {
  const [supplierList, setSupplierList] = useState([]);
  const { dispatch } = useCommon();

  useEffect(() => {
    dispatch({ type: "loading", payload: false });
    getSupplierList()
      .then((response) => {
        if (response) {
          dispatch({ type: "loading", payload: false });
          setSupplierList(response.results);
        }
      })
      .catch((error) => {
        dispatch({
          type: "messageModal",
          payload: {
            visible: true,
            title: MessageStrings.ERROR_MESSAGE_TITLE,
            message: MessageStrings.ERROR_MESSAGE_GENERIC,
            error: error,
          },
        });
      });
    return () => {};
  }, [dispatch]);

  const handleDelete = (e: any, name: string, supplier_id: number) => {
    e.preventDefault();
    // eslint-disable-next-line no-restricted-globals
    if (confirm(`Delete ${name}?`)) {
      dispatch({ type: "loading", payload: true });
      deleteSupplier(supplier_id)
        .then((response: any) => {
          window.location.reload();
        })
        .catch((error: any) => {
          dispatch({
            type: "toast",
            payload: {
              visible: true,
              message: "Error",
              success: true,
            },
          });
          dispatch({ type: "loading", payload: false });
        });
    }
  };

  return (
    <div className="container-fluid pt-4 px-4">
      <Row className="mb-4">
        <Col>
          <HeadingTitle title="Supplier List" />
        </Col>
        <Col className="text-end">
          <ButtonComponent
            tooltip={"Add Supplier"}
            variant={"primary"}
            href={PageRoutes.SUPPLIER + "/new"}
            type={Icons.Add}
            isButton={true}
          />
        </Col>
      </Row>
      {supplierList.length > 0 && (
        <Table borderless responsive>
          <thead>
            <tr>
              <th>Id</th>
              <th>Name</th>
              <th>Debit</th>
              <th>Credit</th>
              <th>Balance</th>
              <th className="w-25">Action</th>
            </tr>
          </thead>
          <tbody>
            {supplierList?.map(
              ({ id, name, total_debit, total_credit }: any) => (
                <tr key={id}>
                  <td>{id}</td>
                  <td>
                    <a href={`/supplier/${id}`}>{name}</a>
                  </td>
                  <td>{total_debit}</td>
                  <td>{total_credit}</td>
                  <td>{total_debit - total_credit}</td>
                  <td className="w-25">
                    <ButtonComponent
                      tooltip={"Edit"}
                      variant={"primary"}
                      type={Icons.Edit}
                      href={PageRoutes.SUPPLIER + `/${id}/edit`}
                      isButton={false}
                    />
                    <ButtonComponent
                      tooltip={"Delete"}
                      variant={"primary"}
                      type={Icons.Delete}
                      isButton={false}
                      onSubmitClick={(e: any) => {
                        handleDelete(e, name, id);
                      }}
                    />
                  </td>
                </tr>
              )
            )}
          </tbody>
        </Table>
      )}
      {supplierList.length === 0 && (
        <NoResultFound message={"No Results Found"} />
      )}
    </div>
  );
};

export default SupplierList;
