import "./App.scss";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Suspense, lazy, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AuthProvider, useAuth } from "./components/AuthProvider";
import { CommonProvider } from "./components/Context/CommonContext";
import { LoginResponse } from "./class/LoginResponse";
import { ROLES } from "./common/Utils";
import MainLayout from "./components/Layout/MainLayout";
import AuthLayout from "./components/Layout/AuthLayout";
import LoadingView from "./components/LoadingView";
import Loading from "./components/Loading";
import RequireAuth from "./components/Auth/RequireAuth";
import LabelsList from "./pages/Labels/LabelsList";
import AddLabels from "./pages/Labels/AddLabels";
import OrderDetail from "./pages/Order/OrderDetail";
import { PageRoutes } from "./common/Constants";
import KotList from "./pages/Kot/KotList";
import Profile from "./pages/Users/Profile";
import SessionList from "./pages/Session/SessionList";
import SessionClose from "./pages/Session/SessionClose";
import KotHistory from "./pages/Kot/KotHistory";
import Expense from "./pages/Expense/ExpenseList";
import ExpenseAdd from "./pages/Expense/ExpenseAdd";
import ListBanking from "./pages/Banking/ListBanking";
import AddBanking from "./pages/Banking/AddBanking";
import ListPaymentMethods from "./pages/Banking/PaymentMethods/ListPaymentMethods";
import AddPaymentMethods from "./pages/Banking/PaymentMethods/AddPaymentMethods";
import InventoryList from "./pages/Inventory/InventoryList";
import MeasuringUnit from "./pages/MeasuringUnits/MeasuringUnit";
import AddInventory from "./pages/Inventory/AddInventory";
import InventoryTransactions from "./pages/Inventory/InventoryTransactions";
import AddInventoryItem from "./pages/Inventory/AddInventoryItem";
import AddProductIngredients from "./pages/Add/AddProductIngredients";
import BankDetail from "./pages/Banking/BankDetail";
import SupplierList from "./pages/Supplier/SupplierList";
import AddSupplier from "./pages/Supplier/AddSupplier";
import SupplierDetails from "./pages/Supplier/SupplierDetail";
import EditPaymentMethods from "./pages/Banking/PaymentMethods/EditPaymentMethods";
import CustomerList from "./pages/companyCustomers/CustomerList";
import CustomerDetail from "./pages/companyCustomers/CustomerDetail";
import SessionOpen from "./pages/Session/SessionOpen";
import InvoiceDetail from "./pages/Invoices/InvoiceDetail";
import NewSettlement from "./pages/Settlement/NewSettlement";
import Settlement from "./pages/Settlement/Settlement";
import ChangePassword from "./pages/Users/ChangePassword";
const AddTableGroup = lazy(() => import("./pages/Tables/AddTableGroup"));
const OrderList = lazy(() => import("./pages/Order/OrderList"));
const Login = lazy(() => import("./pages/Login/Login"));
const NotFound = lazy(() => import("./pages/NotFound/NotFound"));
const ToastComponent = lazy(() => import("./components/Toast"));
const CompanyList = lazy(() => import("./pages/Lists/CompanyList"));
const AddCompany = lazy(() => import("./pages/SuperAdmin/AddCompany"));
const AddProducts = lazy(() => import("./pages/Add/AddProducts"));
const CompanyDetail = lazy(() => import("./pages/Detail/CompanyDetail"));
const ProductDetails = lazy(() => import("./pages/Detail/ProductDetail"));
const CompanyProductLists = lazy(() => import("./pages/Detail/ProductList"));
const ProductDetail = lazy(() => import("./pages/Detail/ProductDetail"));
const Users = lazy(() => import("./pages/SuperAdmin/Users"));
const UserDetail = lazy(() => import("./pages/SuperAdmin/UserDetail"));
const Dashboard = lazy(() => import("./pages/Dashboard/Dashboard"));
const MessageModal = lazy(() => import("./components/Model/MessageModal"));
const AllProducts = lazy(() => import("./pages/Detail/AllProducts"));
const Employees = lazy(() => import("./pages/Employees/Employees"));
const Reviews = lazy(() => import("./pages/Reviews/Reviews"));
const AddEmployee = lazy(() => import("./pages/Employees/AddEmployee"));
const EditEmployee = lazy(() => import("./pages/Employees/EditEmployee"));

const Unauthorized = lazy(() => import("./pages/Unauthorized"));
const Order = lazy(() => import("./pages/Order/TableList"));
const OrderByTable = lazy(() => import("./pages/Order/NewOrder"));
const Menu = lazy(() => import("./pages/Menu/Tabs/Menu"));
const AddTable = lazy(() => import("./pages/Tables/AddTable"));
const TableList = lazy(() => import("./pages/Tables/TableList"));
const Invoices = lazy(() => import("./pages/Invoices/Invoices"));
const TableGroupList = lazy(() => import("./pages/Tables/TablesGroupList"));

const App = () => {
  const [showSideBar, setShowSideBar] = useState<boolean>(
    localStorage.getItem("IsShowSideBar") === "true"
  );

  useEffect(() => {
    localStorage.setItem("IsShowSideBar", String(showSideBar));
  }, [showSideBar]);

  return (
    <AuthProvider>
      <CommonProvider>
        <div className="container-mlg position-relative bg-white d-flex p-0">
          <Helmet>
            <meta charSet="utf-8" />
            <meta
              content="width=device-width, initial-scale=1.0"
              name="viewport"
            ></meta>
            <meta content="" name="keywords"></meta>
            <meta content="" name="description"></meta>
            <title>Foodieho | Admin</title>
            <link rel="icon" href="/favicon.png" />
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link
              href="https://fonts.googleapis.com/css2?family=Heebo:wght@400;500;600;700&display=swap"
              rel="stylesheet"
            />
            <link
              href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"
              rel="stylesheet"
            />
            <link
              href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css"
              rel="stylesheet"
            ></link>
          </Helmet>

          <Routes>
            <Route
              element={
                <ProtectedRoute>
                  <MainLayout
                    showSideBar={showSideBar}
                    setShowSideBar={setShowSideBar}
                  />
                </ProtectedRoute>
              }
            >
              <Route
                index
                element={
                  <Suspense fallback={<LoadingView />}>
                    <Dashboard />
                  </Suspense>
                }
              />
              <Route
                path="unauthorized"
                element={
                  <Suspense fallback={<LoadingView />}>
                    <Unauthorized />
                  </Suspense>
                }
              />
              <Route
                path="*"
                element={
                  <Suspense fallback={<LoadingView />}>
                    <NotFound />
                  </Suspense>
                }
              />

              <Route
                element={<RequireAuth allowedRoles={[ROLES.SUPERADMIN]} />}
              >
                {/* Company  */}
                <Route
                  path={PageRoutes.COMPANY}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <CompanyList title="Company List" />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.COMPANY + "/add"}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <AddCompany />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.COMPANY + "/:id/edit"}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <AddCompany />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.COMPANY + "/:id/products"}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <CompanyProductLists />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.COMPANY + "/:id"}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <CompanyDetail />
                    </Suspense>
                  }
                />

                {/* Users  */}
                <Route
                  path={PageRoutes.USERS}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <Users />
                    </Suspense>
                  }
                />
                <Route
                  path="/user/:uid"
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <UserDetail />
                    </Suspense>
                  }
                />

                {/* Labels */}
                <Route
                  path={PageRoutes.LABELS}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <LabelsList />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.LABEL + "/new"}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <AddLabels />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.LABEL + "/:id/edit"}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <AddLabels />
                    </Suspense>
                  }
                />
              </Route>

              <Route
                element={
                  <RequireAuth
                    allowedRoles={[ROLES.ADMIN, ROLES.MANAGER, ROLES.CASHIER]}
                  />
                }
              >
                {/* Expense */}
                <Route
                  path={PageRoutes.EXPENSE}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <Expense />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.EXPENSE + "/add"}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <ExpenseAdd />
                    </Suspense>
                  }
                />

                {/* SETTLEMENT */}
                <Route
                  path={PageRoutes.SETTLEMENT}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <Settlement />
                    </Suspense>
                  }
                />

                <Route
                  path={PageRoutes.NEW_SETTLEMENT}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <NewSettlement />
                    </Suspense>
                  }
                />

                {/* banking */}
                <Route
                  path={PageRoutes.BANKING + "/:bid"}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <BankDetail />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.BANKING}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <ListBanking />
                    </Suspense>
                  }
                />
                <Route
                  path={
                    PageRoutes.BANKING + "/:bid" + PageRoutes.PAYMENT_METHODS
                  }
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <ListPaymentMethods />
                    </Suspense>
                  }
                />
                <Route
                  path={
                    PageRoutes.BANKING +
                    "/:bid" +
                    PageRoutes.PAYMENT_METHODS +
                    "/new"
                  }
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <AddPaymentMethods />
                    </Suspense>
                  }
                />
                <Route
                  path={
                    PageRoutes.BANKING +
                    "/:bid" +
                    PageRoutes.PAYMENT_METHODS +
                    "/edit/:pid"
                  }
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <EditPaymentMethods />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.BANKING + "/new"}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <AddBanking />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.BANKING + "/:bid/edit"}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <AddBanking />
                    </Suspense>
                  }
                />
                {/* supplier */}
                <Route
                  path={PageRoutes.SUPPLIER}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <SupplierList />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.NEW_SUPPLIER}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <AddSupplier />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.EDIT_SUPPLIER}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <AddSupplier />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.SUPPLIER_DETAIL}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <SupplierDetails />
                    </Suspense>
                  }
                />
                {/* company customer */}
                <Route
                  path={PageRoutes.CUSTOMER}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <CustomerList />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.CUSTOMER_DETAIL}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <CustomerDetail />
                    </Suspense>
                  }
                />
                {/* Products  */}
                <Route
                  path={PageRoutes.COMPANY + "/:cid/products/:id/edit"}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <AddProducts />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.COMPANY + "/:cid/products/:id"}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <ProductDetail />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.COMPANY + "/:cid/product/add"}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <AddProducts />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.PRODUCTS + "/:id"}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <ProductDetails />
                    </Suspense>
                  }
                />
                <Route
                  path={"/products/:id/ingredients/add"}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <AddProductIngredients id={null} />
                    </Suspense>
                  }
                />
                {/* Employees  */}
                <Route
                  path={PageRoutes.EMPlOYEES}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <Employees />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.EMPlOYEES + "/add"}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <AddEmployee />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.EMPlOYEES + "/:cid/edit/:id"}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <EditEmployee />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.REVIEWS}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <Reviews />
                    </Suspense>
                  }
                />
              </Route>

              <Route
                element={
                  <RequireAuth
                    allowedRoles={[ROLES.ADMIN, ROLES.CASHIER, ROLES.MANAGER]}
                  />
                }
              >
                <Route
                  path={PageRoutes.MENU}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <Menu />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.PRODUCTS}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <AllProducts />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.NEW_ORDER}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <Order />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.KOT}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <KotList />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.KOT_HISTORY}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <KotHistory />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.PROFILE}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <Profile />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.CHANGE_PASSWORD}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <ChangePassword />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.COMPANY + "/:cid/order/:id"}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <OrderByTable
                        showSideBar={showSideBar}
                        setShowSideBar={setShowSideBar}
                      />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.COMPANY + "/:cid/order"}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <OrderByTable
                        showSideBar={showSideBar}
                        setShowSideBar={setShowSideBar}
                      />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.ORDER_LIST}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <OrderList />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.ORDER + "/:id/detail"}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <OrderDetail />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.INVOICES}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <Invoices />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.INVOICES_DETAIL}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <InvoiceDetail />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.INVENTORY}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <InventoryList />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.NEW_INVENTORY}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <AddInventory />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.INVENTORY_TRANSACTIONS}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <InventoryTransactions />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.EDIT_INVENTORY}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <AddInventory />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.ADD_INVENTORY_ITEM}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <AddInventoryItem />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.MEASURING_UNITS}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <MeasuringUnit />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.SESSION_HISTORY}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <SessionList />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.SESSION_CLOSE}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <SessionClose />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.SESSION_OPEN}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <SessionOpen />
                    </Suspense>
                  }
                />
                {/* Tables Group */}
                <Route
                  path={PageRoutes.TABLE_GROUP}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <TableGroupList />
                    </Suspense>
                  }
                />
                <Route
                  path="/new-table-group"
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <AddTableGroup />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.COMPANY + "/:cid/table-group/:id/edit"}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <AddTableGroup />
                    </Suspense>
                  }
                />
                {/* Tables */}
                <Route
                  path={PageRoutes.TABLE}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <TableList />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.NEW_TABLE}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <AddTable />
                    </Suspense>
                  }
                />
                <Route
                  path={PageRoutes.COMPANY + "/:cid/table/:id/edit"}
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <AddTable />
                    </Suspense>
                  }
                />
              </Route>
            </Route>

            <Route element={<AuthLayout />}>
              <Route
                path={PageRoutes.LOGIN}
                element={
                  <Suspense fallback={<LoadingView />}>
                    <Login />
                  </Suspense>
                }
              />
            </Route>
          </Routes>

          <Loading />

          <Suspense fallback={<LoadingView />}>
            <MessageModal />
          </Suspense>
          <Suspense fallback={<LoadingView />}>
            <ToastComponent />
          </Suspense>
        </div>
      </CommonProvider>
    </AuthProvider>
  );
};

const ProtectedRoute = ({ children }: any) => {
  const { session }: { session: LoginResponse } = useAuth();

  if (!session) {
    return <Navigate to={PageRoutes.LOGIN} replace />;
  }

  return children;
};

export default App;
