import React, { useEffect, useState } from "react";
import HeadingTitle from "../../components/HeadingTitle";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Stack,
  Table,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { getActiveRestaurantId } from "../../common/Utils";
import { useCommon } from "../../components/Context/CommonContext";
import {
  closeCurrentSession,
  getCurrentSession,
} from "../../services/AdminService";
import { Session } from "../../class/Session";
import { PageRoutes } from "../../common/Constants";

type SessionCashValues = {
  1000: number | null;
  500: number | null;
  100: number | null;
  50: number | null;
  20: number | null;
  10: number | null;
  5: number | null;
  2: number | null;
  1: number | null;
};

type SessionForm = {
  sessionCashValues: SessionCashValues;
  total: number | null;
  manual_amount: number | null;
  close_remarks: string | null;
};

const getSessionFormValue = (
  key: keyof SessionCashValues,
  sessionForm: SessionForm
): number => {
  if (sessionForm.sessionCashValues[key] !== null) {
    return sessionForm.sessionCashValues[key] as number;
  } else return 0;
};

const SessionClose = () => {
  const { dispatch } = useCommon();
  const navigate = useNavigate();

  const [currentSessionData, setCurrentSessionData] = useState<Session>();

  const [sessionForm, setSessionForm] = useState<SessionForm>({
    total: null,
    manual_amount: null,
    sessionCashValues: {
      1000: 0,
      500: 0,
      100: 0,
      50: 0,
      10: 0,
      20: 0,
      2: 0,
      1: 0,
      5: 0,
    },
    close_remarks: null,
  });

  const calculateTotalAmount = (key: number, value: string) => {
    let total = 0;

    Object.keys(sessionForm.sessionCashValues).map((item: unknown) => {
      if (key === item) {
        if (value != null) {
          total = parseInt(value) * (item as number) + total;
        }
      } else {
        let count =
          sessionForm.sessionCashValues[item as keyof SessionCashValues];
        if (count != null) {
          total = count * (item as number) + total;
        }
      }
      return true;
    });

    if (Number.isNaN(total)) {
      return 0;
    }

    return total;
  };

  const validate = () => {
    // Amount Validation
    if (sessionForm.total == null && sessionForm.manual_amount == null) {
      dispatch({
        type: "toast",
        payload: {
          visible: true,
          message: "Please enter cash balance",
        },
      });
      return false;
    }

    const amount = sessionForm.total || sessionForm.manual_amount;

    // Remarks Validation
    if (amount !== currentSessionData?.system_cash) {
      if (sessionForm.close_remarks == null) {
        dispatch({
          type: "toast",
          payload: {
            visible: true,
            message: "Please enter remarks.",
          },
        });
        return false;
      } else {
        dispatch({
          type: "toast",
          payload: {
            visible: true,
            message: "Warning, Cash by System not equal to entered balance",
          },
        });
      }
    }

    return true;
  };

  const closeCurrentSessionRequest = () => {
    if (!validate()) {
      return false;
    }

    const totalAmount =
      sessionForm.total === null
        ? sessionForm.manual_amount
        : sessionForm.total;

    dispatch({ type: "loading", payload: true });
    closeCurrentSession(
      getActiveRestaurantId(),
      sessionForm.close_remarks == null ? "" : sessionForm.close_remarks,
      totalAmount as number
    )
      .then((response: any) => {
        dispatch({ type: "loading", payload: false });

        if (window.confirm("Session Closed")) {
          navigate(PageRoutes.SESSION_HISTORY);
        }
      })
      .catch((error) => {
        dispatch({ type: "loading", payload: false });
        dispatch({
          type: "toast",
          payload: {
            visible: true,
            message: error,
          },
        });
      });
  };

  useEffect(() => {
    dispatch({ type: "loading", payload: true });
    getCurrentSession(getActiveRestaurantId())
      .then((response: any) => {
        dispatch({ type: "loading", payload: false });
        setCurrentSessionData(response);
      })
      .catch(() => {});
  }, [dispatch]);

  return (
    <Container className="pt-4 px-4" fluid>
      <div className="d-flex align-items-center justify-content-between">
        <HeadingTitle title="Session Detail" />
      </div>
      <Row className="mt-2" style={{ height: "auto" }}>
        <Col>
          <Card style={{ height: "100%" }}></Card>
        </Col>
        <Col xs lg="3">
          <Card className="p-3">
            <Form className="b-1">
              <p className="text-center text-muted">
                <b>Cash in Counter</b>
              </p>
              {Object.keys(sessionForm.sessionCashValues).map(
                (item: any, index: number) => {
                  return (
                    <Form.Group
                      key={index}
                      as={Row}
                      className="mb-3"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={4}>
                        {item} *
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          type="number"
                          onWheel={(e) => e.currentTarget.blur()}
                          value={
                            sessionForm !== null
                              ? getSessionFormValue(
                                  item as keyof SessionCashValues,
                                  sessionForm
                                ) === null
                                ? ""
                                : getSessionFormValue(
                                    item as keyof SessionCashValues,
                                    sessionForm
                                  )
                              : ""
                          }
                          onChange={(event) => {
                            let totalAmount = calculateTotalAmount(
                              item as number,
                              event.target.value
                            );

                            setSessionForm({
                              ...sessionForm,
                              sessionCashValues: {
                                ...sessionForm.sessionCashValues,
                                [item as keyof SessionCashValues]: Number.isNaN(
                                  parseInt(event.target.value)
                                )
                                  ? ""
                                  : parseInt(event.target.value),
                              },
                              total: totalAmount,
                            });
                          }}
                        />
                      </Col>
                    </Form.Group>
                  );
                }
              )}

              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formHorizontalEmail"
              >
                <Form.Label column sm={4}>
                  Total
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="number"
                    onWheel={(e) => e.currentTarget.blur()}
                    disabled
                    value={
                      sessionForm?.total === null ? "" : sessionForm?.total
                    }
                    onChange={() => {}}
                  />
                </Col>
              </Form.Group>
            </Form>
          </Card>

          <Form.Group className="mt-3">
            <Form.Label>
              <Stack>
                <span className="text-black-50 fw-bold">
                  Enter Cash Manually
                </span>
                <span className="text-info fw-light">
                  Entering cash here will disable above
                </span>
              </Stack>
            </Form.Label>
            <Col>
              <Form.Control
                type="number"
                onWheel={(e) => e.currentTarget.blur()}
                placeholder="Enter your cash balance"
                value={
                  sessionForm?.manual_amount === null
                    ? ""
                    : sessionForm?.manual_amount
                }
                onChange={(event) => {
                  setSessionForm({
                    ...sessionForm,
                    sessionCashValues: {
                      1000: 0,
                      500: 0,
                      100: 0,
                      50: 0,
                      10: 0,
                      20: 0,
                      2: 0,
                      1: 0,
                      5: 0,
                    },
                    total: null,
                    manual_amount: Number.isNaN(parseInt(event.target.value))
                      ? null
                      : parseInt(event.target.value),
                  });
                }}
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>

      <Row className="mt-3 mb-3">
        <Col>
          <Card className="p-3">
            <Table borderless>
              <tbody>
                <tr className="border-0">
                  <td className="fw-bold">
                    <h4 className="p-0">Closing Statement</h4>
                  </td>
                  <td>Total Sales: NRs 2000</td>
                  <td>Credit Sales: NRs 100</td>
                  <td>Credit Expenses: NRs 100</td>
                </tr>

                <tr className="border-0">
                  <td>Cash by System: NRs {currentSessionData?.system_cash}</td>
                  <td>
                    Open By:{" "}
                    {currentSessionData?.opening_staff
                      ? currentSessionData?.opening_staff?.username
                      : "N/A"}
                  </td>
                  <td>
                    <Form.Group className="ps-0">
                      <Form.Label>
                        <span className="text-black-50 fw-bold">Remarks</span>
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          placeholder="Enter your remarks"
                          value={
                            sessionForm.close_remarks != null
                              ? sessionForm.close_remarks
                              : ""
                          }
                          onChange={(event) => {
                            setSessionForm({
                              ...sessionForm,
                              close_remarks:
                                event.target.value != null
                                  ? event.target.value
                                  : "",
                            });
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </td>
                  <td style={{ verticalAlign: "bottom" }}>
                    <div className="d-grid gap-2">
                      <Button
                        variant="primary"
                        className="text-white me-2 bg-primary"
                        onClick={() => {
                          closeCurrentSessionRequest();
                        }}
                      >
                        <FontAwesomeIcon icon={faClose} /> Close Session
                      </Button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SessionClose;
