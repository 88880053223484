import React, { useEffect, useState } from "react";
import { KOT, KOTITEM } from "../../class/kot";
import _ from "lodash";
import { getInvoiceDetail, getOrderData } from "../../services/AdminService";
import { useParams } from "react-router-dom";
import { getActiveRestaurantId } from "../../common/Utils";
import { OrderData } from "../../class/OrderData";
import { useCommon } from "../../components/Context/CommonContext";
import { Badge, Col, Row } from "react-bootstrap";
import { PaymentType } from "../../class/PaymentType";
import KotDetails from "../../components/NewOrder/KotDetails";
import NonKotDetails from "../../components/NewOrder/NonKotDetails";
import CustomerCreateModal from "../companyCustomers/CustomerCreateModal";
const InvoiceDetail = () => {
  const { id } = useParams();
  const [orderDetails, setOrderDetails] = useState<OrderData>();
  const [paymentDetail, setPaymentDetail] = useState<PaymentType>();
  const [kotLists, setKotLists] = useState<KOT[] | []>([]);
  const [nonkotLists, setNonKotLists] = useState<KOTITEM[] | []>([]);

  const [showCreateCustomerDialog, setShowCreateCustomerDialog] =
    useState(false);

  const { dispatch } = useCommon();
  useEffect(() => {
    dispatch({ type: "loading", payload: true });
    if (id)
      getInvoiceDetail(id)
        .then((response: any) => {
          setPaymentDetail(response);

          getOrderData(getActiveRestaurantId(), response.order).then(
            (response) => {
              dispatch({ type: "loading", payload: false });
              setOrderDetails(response);
              setKotLists(response.kot);
              setNonKotLists(response.items);
            }
          );
        })
        .catch(() => {});
  }, [dispatch, id]);

  return (
    <>
      <div className="container-fluid pt-4 px-4">
        <Row>
          <Col xs={12} md={6}>
            <div className="gx-0 full row">
              <h3 className="text-black">Invoice Detail #{id}</h3>
              <div>
                <div className="d-flex justify-content-between">
                  <span>Order No</span>

                  <h6 className="text-primary">#{orderDetails?.id}</h6>
                </div>
                <div className="d-flex  justify-content-between">
                  <span>User</span>
                  <h6
                    className={
                      orderDetails?.user ? "text-success" : "text-danger"
                    }
                  >
                    {orderDetails?.user ? orderDetails?.user.username : "None"}
                  </h6>
                </div>
                <div className="d-flex  justify-content-between">
                  <span>Table</span>
                  <h6>{orderDetails?.table?.name}</h6>
                </div>
                <hr></hr>

                <div className="d-flex justify-content-between">
                  <span>Paid by Company</span>
                  <span>
                    {paymentDetail?.is_non_recievable && (
                      <Badge bg="info">Yes</Badge>
                    )}
                    {!paymentDetail?.is_non_recievable && <Badge>No</Badge>}
                  </span>
                </div>
                <hr></hr>

                <div className="d-flex justify-content-between ">
                  <span>Sub Total</span>
                  <h6 className=" fw-bold">NRs. {orderDetails?.amount}</h6>
                </div>

                <div className="d-flex justify-content-between ">
                  <span>Discount</span>
                  <div className="align-items-start">
                    <h6 className=" fw-bold align-items-start">
                      NRs.{" "}
                      {Number(paymentDetail?.total) -
                        Number(paymentDetail?.paid)}
                    </h6>
                  </div>
                </div>
                <div className="d-flex justify-content-between ">
                  <span>Total Paid </span>
                  <h6 className="text-success fw-bold">
                    NRs. {orderDetails?.total}
                  </h6>
                </div>
                <div className="d-flex justify-content-between">
                  <span>Return </span>
                  <h6 className="text-success fw-bold">
                    NRs.{" "}
                    {Number(orderDetails?.total) - Number(paymentDetail?.total)}
                  </h6>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} md={6}>
            {!_.isNil(id) && nonkotLists.length > 0 && (
              <NonKotDetails
                data={nonkotLists}
                handleCancelKot={() => {}}
                showModelOnResponse={false}
                onAddOrder={() => {}}
                deleteKotData={() => {}}
              />
            )}
            {!_.isNil(id) && !_.isNil(kotLists) && (
              <KotDetails
                data={kotLists}
                handleCancelKot={() => {}}
                showModelOnResponse={false}
                onAddOrder={() => {}}
                deleteKotData={() => {}}
              />
            )}
          </Col>
        </Row>
      </div>

      <CustomerCreateModal
        onCreateSuccess={() => {}}
        setShowCreateCustomerDialog={setShowCreateCustomerDialog}
        showCreateCustomerDialog={showCreateCustomerDialog}
      />
    </>
  );
};

export default InvoiceDetail;
