import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
const MainLayout = ({ showSideBar, setShowSideBar }: any) => {
  return (
    <>
      <Sidebar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      <div className={`content ${!showSideBar ? "open" : ""}`}>
        <Header showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
        <div className="main-container">
          <Outlet />
        </div>
        <Footer showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      </div>
    </>
  );
};
export default MainLayout;
