import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useState } from "react";
import { Button, Col, InputGroup, Row, Table } from "react-bootstrap";
import Stack from "react-bootstrap/Stack";
import { useParams } from "react-router-dom";
import { getLocalDateTime } from "../../common/Utils";
import { useCommon } from "../../components/Context/CommonContext";
import "react-datepicker/dist/react-datepicker.css";
import NoResultFound from "../../components/NoResultFound";
import {
  getInventoryDetail,
  getInventoryTransactionsList,
} from "../../services/inventoryApi";
import { useDebounce } from "use-debounce";
import ReactPaginate from "react-paginate";
import Form from "react-bootstrap/Form";
import InventoryDetails from "../../components/Inventory/InventoryDetails";
import DatePicker from "react-datepicker";
import { DateQuery } from "../../class/misc";
import { InventoryDetail, Transaction } from "../../class/Inventory";
import LinkComponent from "../../components/Link/LinkComponent";

const InventoryList = (): JSX.Element => {
  const [inventory, setInventory] = useState<InventoryDetail>();
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const { dispatch } = useCommon();
  const [searchValue, setSearchValue] = useState("");
  const [debouncedSearchValue] = useDebounce(searchValue, 1000);
  const pageSizeArray = [50, 100, 200, 500];
  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState<number>(pageSizeArray[0]);
  const [currentPage, setcurrentPage] = useState<number>(1);
  const [dateQuery, setDateQuery] = useState<DateQuery>({
    startDate: null,
    endDate: null,
  });
  const { id } = useParams();

  const getInventoryData = useCallback(() => {
    if (id) {
      getInventoryDetail(id)
        .then((response: any) => {
          setInventory(response);
        })
        .catch(() => {});
    }
  }, [id]);

  const getInventoryTransactions = useCallback(() => {
    if (id) {
      dispatch({ type: "loading", payload: true });
      getInventoryTransactionsList(
        parseInt(id),
        debouncedSearchValue,
        currentPage,
        pageSize,
        dateQuery.startDate,
        dateQuery.endDate
      )
        .then((response: any) => {
          dispatch({ type: "loading", payload: false });
          setTransactions(response);

          if (response.length > 0) {
            setPageCount((prevPageCount) =>
              Math.ceil(response.count / pageSize)
            );
          }
        })
        .catch((error: any) => {
          dispatch({ type: "loading", payload: false });
          console.error("Error fetching inventory data:", error);
        });
    }
  }, [
    dispatch,
    id,
    debouncedSearchValue,
    currentPage,
    pageSize,
    dateQuery.startDate,
    dateQuery.endDate,
  ]);

  useEffect(() => {
    getInventoryData();
    getInventoryTransactions();
  }, [getInventoryData, getInventoryTransactions]);

  const onChange = (dates: [any, any]) => {
    const [start, end] = dates;
    setDateQuery({ startDate: start, endDate: end });
  };

  const handlePageClick = (event: any) => {
    const pageNo = event.selected + 1;
    setcurrentPage(pageNo);
  };

  return (
    <div className="mt-4 mx-4">
      {inventory && <InventoryDetails data={inventory} />}
      <div className="d-flex flex-wrap align-items-baseline justify-content-between">
        <Col sm={3} xs={12} md={4} lg={4} xl={4}>
          <DatePicker
            showIcon={true}
            selected={dateQuery.startDate}
            onChange={onChange}
            startDate={dateQuery.startDate}
            endDate={dateQuery.endDate}
            selectsRange
            placeholderText="Select date or range"
            dateFormat="MMM d, yyyy"
            className="form-control"
          />
        </Col>
        <Col sm={3} xs={12} md={2} lg={2} xl={2}>
          <InputGroup className="mt-xs-1">
            <InputGroup.Text id="inputGroup-sizing-default">
              Page Size
            </InputGroup.Text>
            <Form.Select
              aria-label="Default select example"
              aria-describedby="inputGroup-sizing-default"
              onChange={(event: any) => {
                setPageSize(parseInt(event.target.value));
              }}
            >
              {pageSizeArray.map((item: number) => {
                return (
                  <option value={item} key={item} selected={pageSize === item}>
                    {item}
                  </option>
                );
              })}
            </Form.Select>
          </InputGroup>
        </Col>
        <Col sm={3} xs={12} md={2} lg={2} xl={2}>
          <Stack direction="horizontal">
            <input
              className="form-control"
              placeholder="Search.."
              type="text"
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
            <Button type="submit" className="m-2 text-primary fw-bold">
              <FontAwesomeIcon className="text-white" icon={faSearch} />
            </Button>
          </Stack>
        </Col>
      </div>
      {transactions.length === 0 ? (
        <NoResultFound message="No results" />
      ) : (
        <Table borderless responsive className="mt-2">
          <thead>
            <tr>
              <th>ID</th>
              <th>Created At</th>
              <th>Type</th>
              <th>Description</th>
              <th>Quantity</th>
              <th>Unit Price</th>
              <th>Total Price</th>
              <th>Total Stock</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction: Transaction) => (
              <tr key={id}>
                <td>
                  <LinkComponent
                    data={{
                      type: transaction.content_type,
                      name: transaction.object_id,
                    }}
                  />
                </td>
                <td>{getLocalDateTime(transaction.created_at)}</td>
                <td>{transaction.transaction_type}</td>
                <td>{transaction.description}</td>
                <td>{transaction.quantity}</td>
                <td>
                  {transaction.price} / {inventory?.measuring_unit.name}
                </td>
                <td>NRs {transaction.total}</td>
                <td>{transaction.balance}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {transactions.length > 0 && (
        <Row className="mt-4">
          <Col className="text-center">
            <ReactPaginate
              breakLabel="..."
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="Previous"
              nextLabel="Next"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakClassName="page-item"
              breakLinkClassName="page-link"
              marginPagesDisplayed={2}
              containerClassName="pagination d-inline-flex"
              activeClassName="active"
              initialPage={currentPage - 1}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default InventoryList;
