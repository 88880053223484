import React, { Fragment, useState } from "react";
import { Inventory } from "../../class/Inventory";
import { ItemDetails } from "../../class/Expense";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { searchInventory } from "../../services/inventoryApi";
import { useNavigate } from "react-router-dom";
import { PageRoutes } from "../../common/Constants";

const ExpenseItemAddComponent = (props: {
  setTotalAmount: any;
  setInventoryList: any;
}) => {
  const [intentoryItems, setInventoryItems] = useState<ItemDetails[]>([]);
  const [intentoryOptions, setInventoryOptions] = useState<Inventory[]>([]);

  const [selectedProduct, setSelectedProduct] = useState<any>();
  const [description, setDescription] = useState<string>("");
  const [amount, setAmount] = useState<number>(0);
  const [rate, setRate] = useState<number>(0);
  const [quantity, setQuantity] = useState<number>(0);

  const [isLoadingInventoryOptions, setInventoryOptionsLoading] =
    useState(false);

  const navigate = useNavigate();

  const calculateTotal = (intentoryItems: ItemDetails[]) => {
    let total = 0;
    intentoryItems.flatMap((item: ItemDetails) => {
      total = item.amount + total;
      return false;
    });

    props.setTotalAmount(total);
  };

  const clearInputs = () => {
    setSelectedProduct([]);
    setDescription("");
    setAmount(0);
    setRate(0);
    setQuantity(0);
  };

  const handleAddItem = (e: any) => {
    e.preventDefault();
    if (selectedProduct !== undefined && selectedProduct.length > 0) {
      intentoryItems.push({
        amount: amount,
        rate: rate,
        product: selectedProduct[0],
        description: description,
        quantity: quantity,
      });
      clearInputs();
      setInventoryItems(intentoryItems);
      props.setInventoryList(intentoryItems);
      calculateTotal(intentoryItems);
    }
  };

  const handleDeleteItem = (index: number) => {
    if (intentoryItems.length > 0) {
      const updatedItems = [...intentoryItems];
      updatedItems.splice(index, 1);
      setInventoryItems(updatedItems);
      props.setInventoryList(updatedItems);
    }
  };

  const handleSearchInventory = (value: any) => {
    setInventoryOptionsLoading(true);
    searchInventory(value)
      .then((response: any) => {
        const newList = response.results;
        newList.push({ id: null, name: "" });
        console.log(newList);
        setInventoryOptions(newList);
        setInventoryOptionsLoading(false);
      })
      .catch(() => {});
  };

  return (
    <div className="mt-5">
      <h6 className="text-secondary">Inventory Details</h6>
      <table className="table">
        <thead>
          <th className="p-2">#</th>
          <th className="p-2">Product</th>
          <th className="p-2">Description</th>
          <th className="p-2">Quantity</th>
          <th className="p-2">Rate</th>
          <th className="p-2">Amount</th>
          <th className="p-2">Action</th>
        </thead>
        <tbody>
          {intentoryItems.map((item, index) => (
            <tr key={index}>
              <td style={{ width: "5%" }} colSpan={1}>
                {index + 1}
              </td>
              <td style={{ width: "20%" }} colSpan={1}>
                {item.product.name}
              </td>
              <td style={{ width: "40%" }} colSpan={1}>
                {item.description}
              </td>
              <td>{item.quantity}</td>
              <td>{item.rate}</td>
              <td>{item.amount}</td>
              <td style={{ width: "12%" }} colSpan={1}>
                <button
                  className="btn btn-outline-info"
                  onClick={() => {
                    handleDeleteItem(index);
                  }}
                >
                  <i className="fa fa-trash" /> Remove
                </button>
              </td>
            </tr>
          ))}
          <tr>
            <td style={{ width: "5%" }} colSpan={1}></td>
            <td style={{ width: "20%" }} colSpan={1}>
              <AsyncTypeahead
                id="input-size-example"
                labelKey="name"
                onChange={setSelectedProduct}
                options={intentoryOptions}
                isLoading={isLoadingInventoryOptions}
                onSearch={(data) => {
                  handleSearchInventory(data);
                }}
                placeholder="Select inventory / product"
                size="sm"
                filterBy={() => true}
                selected={selectedProduct}
                renderMenuItemChildren={(option: any) => (
                  <Fragment>
                    {option.id !== null && (
                      <Fragment>
                        <div>
                          <span>{option.name}</span>
                        </div>
                      </Fragment>
                    )}

                    {(option as any).id === null && (
                      <Fragment>
                        <div>
                          <span
                            onClick={() => {
                              navigate(PageRoutes.NEW_INVENTORY);
                            }}
                          >
                            <strong>Add New Inventory</strong>
                          </span>
                        </div>
                      </Fragment>
                    )}
                  </Fragment>
                )}
              />
            </td>
            <td style={{ width: "40%" }} colSpan={1}>
              <textarea
                className="form-control"
                value={description}
                rows={3}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              ></textarea>
            </td>
            <td>
              <div className="d-flex justify-content-between align-items-center">
                <input
                  type="number"
                  className="form-control"
                  onWheel={(e) => e.currentTarget.blur()}
                  value={quantity}
                  onChange={(e) => {
                    setQuantity(parseFloat(e.target.value));

                    if (rate) {
                      setAmount(parseFloat(e.target.value) * rate);
                    }
                  }}
                  style={{ height: "32px" }}
                ></input>
              </div>
            </td>
            <td>
              <div className="d-flex justify-content-between align-items-center">
                <input
                  type="number"
                  className="form-control"
                  value={rate}
                  onWheel={(e) => e.currentTarget.blur()}
                  onChange={(e) => {
                    setRate(parseFloat(e.target.value));

                    if (quantity) {
                      setAmount(quantity * parseFloat(e.target.value));
                    }
                  }}
                  style={{ height: "32px" }}
                ></input>
              </div>
            </td>
            <td>
              <div className="d-flex justify-content-between align-items-center">
                <input
                  type="number"
                  className="form-control"
                  value={amount}
                  onWheel={(e) => e.currentTarget.blur()}
                  onChange={(e) => {
                    setAmount(parseFloat(e.target.value));
                  }}
                  style={{ height: "32px" }}
                  disabled
                ></input>
              </div>
            </td>
            <td style={{ width: "15%" }} colSpan={1}>
              <button className="btn btn-primary" onClick={handleAddItem}>
                <i className="fa fa-plus" /> Add
              </button>

              <button
                className="btn btn-outline-primary ms-2"
                onClick={() => {
                  clearInputs();
                }}
              >
                <i className="fa fa-eraser" /> Clear
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ExpenseItemAddComponent;
