import React, { useEffect, useRef, useState } from "react";
import { ImageListType, ImageType } from "react-images-uploading";
import { useParams, useNavigate } from "react-router-dom";
import { useCommon } from "../../components/Context/CommonContext";
import {
  addLabel,
  getLabelsLists,
  updateLabel,
} from "../../services/AdminService";
import imageCompression from "browser-image-compression";
import LabelImageSelector from "../../components/ImageSelector/LabelImageSelector";
const AddLabels = () => {
  type Label = {
    name: string;
    icon: any;
    position: number | null;
  };
  const { id } = useParams();
  const navigate = useNavigate();
  const { dispatch } = useCommon();
  const [labelDetail, setLabelDetail] = useState<Label>({
    name: "",
    icon: [],
    position: null,
  });
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
  };

  const [productImages, setProductImages] = useState<ImageListType>([]);
  const handleIcon = (e: any) => {
    let initial_icon = [...(labelDetail?.icon || [])];
    Array.from(e.target.files).forEach((file: any) => {
      dispatch({ type: "loading", payload: true });
      imageCompression(file, options).then((BlobFile: File) => {
        const file = new File(
          [BlobFile],
          labelDetail?.name + "_" + Date.now() + ".png"
        );
        initial_icon.push({
          image: file,
          image_url: URL.createObjectURL(file),
        });
        dispatch({ type: "loading", payload: false });
      });
    });
    setLabelDetail({
      ...labelDetail,
      icon: initial_icon,
    } as Label);
  };
  const form = useRef(null);
  useEffect(() => {
    if (id !== undefined) {
      getLabelsLists(id)
        .then((response) => {
          if (response) {
            setLabelDetail(response);
            const productImages: ImageListType = [];

            const imageType: ImageType = {
              dataURL: response.icon === null ? "" : response.icon,
              id: response.id,
            };
            productImages.push(imageType);
            setProductImages(productImages);
          }
        })
        .catch((error) => {
          //console.log(error);
        });
    }
  }, [id]);

  const handleInsert = (e: any) => {
    e.preventDefault();
    dispatch({ type: "loading", payload: true });
    if (
      labelDetail == null ||
      labelDetail.name === "" ||
      labelDetail.position === null ||
      labelDetail.icon.length === 0
    ) {
      dispatch({
        type: "toast",
        payload: {
          visible: true,
          message: "Fields can't be empty",
        },
      });
      dispatch({ type: "loading", payload: false });
    } else {
      const bodyFormData = new FormData();
      bodyFormData.append("name", labelDetail.name);
      bodyFormData.append("position", String(labelDetail.position));
      if (id) {
        updateLabel(bodyFormData, id)
          .then((response) => {
            if (response) {
              dispatch({ type: "loading", payload: false });
              dispatch({
                type: "toast",
                payload: {
                  visible: true,
                  message: "Successfully updated",
                },
              });
              navigate(`/labels`);
            }
          })
          .catch((error) => {
            //console.log(error);
          });
      } else {
        labelDetail!.icon!.map((image: any) => {
          bodyFormData.append("icon", image.image);
          return false;
        });
        addLabel(bodyFormData)
          .then((response) => {
            if (response) {
              dispatch({ type: "loading", payload: false });
              navigate(`/labels/`);
            }
          })
          .catch((error) => {
            //console.log(error);
          });
        dispatch({ type: "loading", payload: false });
      }
    }
  };

  return (
    <>
      <div className="container-fluid pt-4 px-4">
        <div className="row col-sm-12 ">
          <div className="col-sm-6 col-xl-6">
            <div className="bg-light  rounded h-100 p-4">
              <h6> {id !== undefined ? "Edit" : "Add New"} Label</h6>
              <form ref={form} onSubmit={handleInsert}>
                <div className="row">
                  <div className="col-sm-12 ">
                    <div className="mb-3">
                      <label htmlFor="Name" className="form-label">
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        value={labelDetail?.name}
                        onChange={(e) => {
                          setLabelDetail({
                            ...labelDetail,
                            name: e.target.value,
                          } as Label);
                        }}
                      />
                    </div>
                    {!id && (
                      <div className="row d-flex mt-4 mb-4 align-items-center">
                        <div className="mb-3">
                          <div className="row d-flex align-items-center">
                            <div
                              className={`${
                                labelDetail?.icon.length === 0
                                  ? "col-sm-0"
                                  : "col-sm-3"
                              }`}
                            >
                              {labelDetail?.icon?.map(
                                (item: any, index: number) => {
                                  return (
                                    <img
                                      key={index}
                                      src={item.image_url}
                                      width={100}
                                      height={110}
                                      alt="Product Images"
                                    />
                                  );
                                }
                              )}
                            </div>
                            <div
                              className={`${
                                labelDetail?.icon.length === 0
                                  ? "col-sm-12"
                                  : " col-sm-9"
                              }`}
                            >
                              <label
                                htmlFor="formFileMultiple"
                                className="form-label"
                              >
                                Icon
                              </label>

                              <input
                                className="form-control"
                                type="file"
                                id="formFileMultiple"
                                onChange={(e) => {
                                  handleIcon(e);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="mb-3">
                      <label htmlFor="position" className="form-label">
                        Position
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        onWheel={(e) => e.currentTarget.blur()}
                        autoComplete="off"
                        value={
                          labelDetail?.position != null
                            ? labelDetail?.position
                            : 0
                        }
                        onChange={(e: any) => {
                          setLabelDetail({
                            ...labelDetail,
                            position: e.target.value,
                          } as Label);
                        }}
                      />
                    </div>
                    <div className="row d-flex align-items-center">
                      <div className="col-sm-5">
                        <button type="submit" className="btn btn-primary m-2">
                          {id ? "UPDATE" : "ADD"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          {id && (
            <div className="col-sm-6 col-xl-6">
              <div className="bg-light rounded h-100 p-4">
                <h6 className="mb-4">Icon</h6>
                <div className="mb-3">
                  <LabelImageSelector
                    className="form-control"
                    images={productImages}
                    id={id}
                    Name={labelDetail?.name}
                    setImages={setProductImages}
                    maxNumber={10}
                    type={"photo"}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AddLabels;
