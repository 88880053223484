import React from "react";
import HeadingTitle from "../HeadingTitle";
import ButtonComponent, { Icons } from "../Buttons/ButtonComponent";
import { InventoryDetail } from "../../class/Inventory";

const InventoryDetails = ({ data }: { data: InventoryDetail }) => {
  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-baseline gray pointer">
          <HeadingTitle title={data.name} />
        </div>
        <div>
          <ButtonComponent
            href={`/inventory/${data.id}/add-stock`}
            tooltip="Add Stock"
            variant="primary"
            type={Icons.Add}
            label="Add Stock"
            isButton={true}
          />
        </div>
      </div>
      <div className="d-flex flex-wrap  align-items-start justify-content-between mt-3">
        <div className="d-flex flex-column justify-content">
          <div>
            <label>
              <small>Description</small>
            </label>
            <br />
            <p>{data.description}</p>
          </div>
        </div>
        <div></div>
        <div>
          <div className="d-flex">
            <label className="me-2">
              <small>Measuring Unit</small>
            </label>
            <p>{data.measuring_unit.name}</p>
          </div>
          <div className="d-flex">
            <label className="me-2">
              <small>Min Threashold Unit</small>
            </label>
            <p>{data.min_stock_threshold}</p>
          </div>
        </div>
      </div>
      <hr className="1px solid gray" />
    </>
  );
};

export default InventoryDetails;
