import { useContext } from "react";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionButton } from "react-bootstrap/AccordionButton";

interface ContextAwareToggleProps {
  children: React.ReactNode;
  eventKey: string;
  callback?: (eventKey: string) => void;
}

const ContextAwareToggle = ({
  children,
  eventKey,
  callback,
}: ContextAwareToggleProps) => {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );
  const isCurrentEventKey = activeEventKey === eventKey;
  return (
    <div>
      <button
        type="button"
        style={{ borderColor: isCurrentEventKey ? "#d0000e" : "" }}
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    </div>
  );
};

export default ContextAwareToggle;
