import React, { useEffect, useRef, useState } from "react";
import { Alert, Breadcrumb, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useCommon } from "../../components/Context/CommonContext";
import { PageRoutes } from "../../common/Constants";
import { Inventory, StockItem } from "../../class/Inventory";
import { SubmitHandler, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Checkbox from "../../components/Checkbox";
import { addStock, getInventoryDetail } from "../../services/inventoryApi";
import { parseErrorMessage } from "../../common/Utils";

const AddInventoryItem = () => {
  const navigate = useNavigate();
  const { dispatch } = useCommon();

  const [inventory, setInventory] = useState<Inventory>();
  const [isPriceChanged, setIsPriceChanged] = useState<boolean>(false);

  const form = useRef(null);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch({ type: "loading", payload: true });
      getInventoryDetail(id)
        .then((response: any) => {
          dispatch({ type: "loading", payload: false });
          if (response) {
            setInventory(response);
          }
        })
        .catch(() => {});
    }
  }, [dispatch, id]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<StockItem>({
    defaultValues: {
      quantity: 0,
      price: 0,
      description: "",
      transaction_type: "MANUAL",
    },
    criteriaMode: "all",
  });

  const handleInsert: SubmitHandler<StockItem> = (data: any) => {
    dispatch({ type: "loading", payload: true });
    data.stock_item = id;
    addStock(data)
      .then((response: any) => {
        if (response.id) {
          navigate(PageRoutes.INVENTORY);
          dispatch({
            type: "toast",
            payload: {
              visible: true,
              message: "Stock added Successfully",
            },
          });
        }
      })
      .catch((error: any) => {
        dispatch({
          type: "toast",
          payload: {
            visible: true,
            message: parseErrorMessage(error),
          },
        });
      })
      .finally(() => {
        dispatch({ type: "loading", payload: false });
      });
  };

  return (
    <div className="container-fluid pt-4 px-4">
      <Breadcrumb>
        <Breadcrumb.Item href={PageRoutes.INVENTORY}>Inventory</Breadcrumb.Item>
        <Breadcrumb.Item href={`/inventory/${inventory?.id}/transactions`}>
          {inventory?.name}
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Add Stock</Breadcrumb.Item>
      </Breadcrumb>
      <div className="row col-sm-12 ">
        <div className="col-sm-6 col-xl-6">
          <div className="bg-light  rounded h-100 p-4">
            <h6 className="mb-2">Add Stock</h6>

            <Alert variant="success">
              <p className="mb-0">
                You are adding stock of <b>{inventory?.name}</b>
              </p>
            </Alert>

            <form ref={form} onSubmit={handleSubmit(handleInsert)}>
              <div className="row">
                <div className="col-sm-12 ">
                  <div className="mb-3">
                    <Form.Group>
                      <Form.Label>
                        Quantity <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        {...register("quantity", {
                          required: " * Required",
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="quantity"
                        render={({ messages }) =>
                          messages &&
                          Object.entries(messages).map(([type, message]) => (
                            <p key={type} className="text-danger">
                              {message}
                            </p>
                          ))
                        }
                      />
                    </Form.Group>
                  </div>
                  <div className="mb-3">
                    <Form.Group>
                      <Form.Label className="d-flex">
                        Unit Price |
                        <Checkbox
                          label="Change Price"
                          isPriceChanged={isPriceChanged}
                          setIsPriceChanged={setIsPriceChanged}
                        />
                      </Form.Label>
                      <Form.Control
                        type="number"
                        onWheel={(e) => e.currentTarget.blur()}
                        disabled={!isPriceChanged}
                        placeholder="price"
                        {...register("price", {
                          required: " * Required",
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="price"
                        render={({ messages }) =>
                          messages &&
                          Object.entries(messages).map(([type, message]) => (
                            <p key={type} className="text-danger">
                              {message}
                            </p>
                          ))
                        }
                      />
                    </Form.Group>
                  </div>
                  <div className="mb-3">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>
                        Description <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Description..."
                        {...register("description", {
                          required: "* Required",
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="description"
                        render={({ messages }) =>
                          messages &&
                          Object.entries(messages).map(([type, message]) => (
                            <p key={type} className="text-danger">
                              {message}
                            </p>
                          ))
                        }
                      />
                    </Form.Group>
                  </div>

                  <div className="mb-3">
                    <button type="submit" className="btn btn-primary">
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddInventoryItem;
