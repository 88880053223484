import React, { useState } from "react";
import ImageUploading, { ImageListType } from "react-images-uploading";
import { patchLabelImage } from "../../services/AdminService";
import { options } from "../../common/Utils";
import imageCompression from "browser-image-compression";

const LabelImageSelector = ({
  maxNumber,
  images,
  setImages,
  type,
  id,
}: any) => {
  const [error, setError] = useState<any>(null);

  const onChange = (
    imageList: ImageListType,
    addUpdateIndex: number[] | undefined
  ) => {
    // data for submit
    setImages(imageList as never[]);
    if (addUpdateIndex !== undefined && addUpdateIndex.length > 0) {
      for (let entry of addUpdateIndex) {
        if (imageList[entry].file !== undefined) {
          onImageAdded(imageList[entry].file!!);
        }
      }
    }
  };

  //API Call
  const onImageAdded = (file: File) => {
    imageCompression(file, options).then((BlobFile: Blob) => {
      const file = new File([BlobFile], type + "_" + Date.now() + ".png");
      patchLabelImage(id, file).catch((error) => {
        setError("Upload Failed");
      });
    });
  };
  return (
    <div>
      <ImageUploading value={images} onChange={onChange} maxNumber={maxNumber}>
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <button
              style={isDragging ? { color: "red" } : undefined}
              onClick={onImageUpload}
              {...dragProps}
              type="button"
              className="btn btn-primary m-2"
            >
              Select New or Drop here
            </button>
            {error && (
              <p style={{ color: "red" }} className="mx-2">
                {error}
              </p>
            )}
            &nbsp;
            {imageList.map((image, index) => (
              <div
                key={index}
                className="image-item"
                style={{ marginTop: 12, marginLeft: 12 }}
              >
                <img src={image.dataURL} alt="" width={150} height={150} />
                <button
                  className="btn m-2"
                  onClick={() => {
                    onImageRemove(index);
                  }}
                ></button>
              </div>
            ))}
          </div>
        )}
      </ImageUploading>
    </div>
  );
};

export default LabelImageSelector;
