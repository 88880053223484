import React from "react";
import { Badge } from "react-bootstrap";
import { KOT } from "../../class/kot";
import { OrderData } from "../../class/OrderData";

const KOTListHeader = ({ singleKot }: { singleKot: KOT }) => {
  return (
    <div
      className={`d-flex ${
        singleKot.deleted ? "pointer" : ""
      } justify-content-between`}
    >
      <span>
        <b>KOT #{singleKot?.c_kot_id}</b>{" "}
        {(singleKot.order as OrderData).id && (
          <>
            <b className="text-black"> | {(singleKot.order as OrderData).id}</b>
          </>
        )}{" "}
        <span className="text-muted">
          {new Date(singleKot.updated_at).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })}
        </span>
        <br></br>
        {singleKot.status === "PENDING" && <Badge bg="info">Ongoing</Badge>}
        {singleKot.status === "SERVED" && <Badge bg="info">Served</Badge>}
        {singleKot.status === "CANCELLED" && (
          <Badge bg="danger">Cancelled</Badge>
        )}{" "}
        {(singleKot.order as OrderData).waiter !== undefined &&
          (singleKot.order as OrderData).waiter !== null &&
          (singleKot.order as OrderData).waiter.username !== "" && (
            <>
              <br></br>
              <br></br>
              <span>
                <i className="text-muted">
                  Created by {(singleKot.order as OrderData).waiter.username}
                </i>
              </span>
            </>
          )}
      </span>
    </div>
  );
};

export default KOTListHeader;
