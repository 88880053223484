import React from "react";
import { useCommon } from "../../../components/Context/CommonContext";
import { SubmitHandler, useForm } from "react-hook-form";
import { addPaymentMethod } from "../../../services/AdminService";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import imageCompression from "browser-image-compression";
import { options } from "../../../common/Utils";
interface paymetMethodProps {
  name: string;
  image: File;
  bank_account: string;
  is_active_in_pos: boolean;
}
const AddPaymentMethods = () => {
  const bid = useParams().bid;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<paymetMethodProps>({ criteriaMode: "all" });
  const { dispatch } = useCommon();
  const navigate = useNavigate();
  const handleImageCompression = (file: FileList) => {
    return new Promise((resolve, reject) => {
      try {
        if (file![0].size / 1024 / 1024 > 5) {
          dispatch({
            type: "toast",
            payload: {
              visible: true,
              message: "File Size is greater than 5MB",
              success: false,
            },
          });
          reject("File size is greater than 5MB");
        } else {
          dispatch({ type: "loading", payload: true });
          imageCompression(file[0], options).then((BlobFile) => {
            const compressedFile = new File(
              [BlobFile],
              "_logo_" + Date.now() + ".png"
            );
            dispatch({ type: "loading", payload: false });
            resolve(compressedFile);
          });
        }
      } catch (error) {
        dispatch({
          type: "toast",
          payload: {
            visible: true,
            message: error,
            success: false,
          },
        });
        reject(error);
      }
    });
  };
  const handleInsert: SubmitHandler<paymetMethodProps> = (event: any) => {
    dispatch({ type: "loading", payload: true });
    const data = new FormData();
    data.append("name", event.name);
    data.append("bank_account", String(bid));
    handleImageCompression(event.image)
      .then((compressedFile: any) => {
        data.append("image", compressedFile);
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        addPaymentMethod(data)
          .then((response: any) => {
            if (response) {
              dispatch({ type: "loading", payload: false });
              dispatch({
                type: "toast",
                payload: {
                  visible: true,
                  message: `Added Successfully.`,
                  success: true,
                },
              });
              navigate(-1);
            }
          })
          .catch(() => {
            dispatch({ type: "loading", payload: false });
            dispatch({
              type: "toast",
              payload: {
                visible: true,
                message: "Error",
                success: false,
              },
            });
          });
      });
  };
  return (
    <div className="container-fluid pt-4 px-4">
      <div className="row col-sm-12 ">
        <div className="col-sm-12 col-xl-6">
          <div className="bg-light  rounded h-100 p-4">
            <h6>Add Payment Method</h6>
            <form onSubmit={handleSubmit(handleInsert)}>
              <div className="row">
                <div className="col-sm-12 ">
                  <div className="mb-3">
                    <label htmlFor="Name" className="form-label">
                      <small>
                        Name <span className="text-primary">*</span>
                      </small>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      autoComplete="off"
                      placeholder="Payment Method Name"
                      {...register("name", { required: "Required" })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="name"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <p key={type} className="text-danger">
                            * {message}
                          </p>
                        ))
                      }
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="Abbreviation" className="form-label">
                      <small>
                        Active in POS <span className="text-primary">*</span>
                      </small>
                    </label>
                    <select
                      id="disabledSelect"
                      className="form-select"
                      {...register("is_active_in_pos", { required: true })}
                    >
                      <option value={"true"}>Yes</option>
                      <option value={"false"}>No</option>
                    </select>
                    <ErrorMessage
                      errors={errors}
                      name="abbreviation"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <p key={type} className="text-danger">
                            * {message}
                          </p>
                        ))
                      }
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="Name" className="form-label">
                      <small>
                        Logo <span className="text-primary">*</span>
                      </small>
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      {...register("image", { required: "Required" })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="image"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <p key={type} className="text-danger">
                            * {message}
                          </p>
                        ))
                      }
                    />
                  </div>

                  <div className="row d-flex align-items-center">
                    <div className="col-sm-5">
                      <button type="submit" className="btn btn-primary mt-2">
                        Proceed
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPaymentMethods;
