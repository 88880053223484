import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, InputGroup, Row, Stack, Table } from "react-bootstrap";
import ButtonComponent, {
  Icons,
} from "../../components/Buttons/ButtonComponent";
import HeadingTitle from "../../components/HeadingTitle";
import { ExpenseItem } from "../../class/Expense";
import { useDebounce } from "use-debounce";
import { useCommon } from "../../components/Context/CommonContext";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PageRoutes } from "../../common/Constants";
import Form from "react-bootstrap/Form";
import { getExpenseList } from "../../services/AdminService";
import ReactPaginate from "react-paginate";
import NoResultFound from "../../components/NoResultFound";
import LinkComponent from "../../components/Link/LinkComponent";

const ExpenseList = () => {
  const [expenseList, setExpenseList] = useState<ExpenseItem[]>([]);
  const { dispatch } = useCommon();
  const [searchValue, setSearchValue] = useState("");
  const [debouncedSearchValue] = useDebounce(searchValue, 1000);
  const pageSizeArray = [50, 100, 200, 500];
  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState<number>(pageSizeArray[0]);
  const [currentPage, setcurrentPage] = useState<number>(1);

  const initExpenseList = useCallback(() => {
    dispatch({ type: "loading", payload: true });
    getExpenseList(debouncedSearchValue, currentPage, pageSize)
      .then((response: any) => {
        dispatch({ type: "loading", payload: false });
        setExpenseList(response?.results);
        setPageCount((prevPageCount) => Math.ceil(response.count / pageSize));
      })
      .catch((error: any) => {
        dispatch({ type: "loading", payload: false });
        console.error("Error fetching inventory data:", error);
      });
  }, [dispatch, debouncedSearchValue, currentPage, pageSize]);

  useEffect(() => {
    initExpenseList();
  }, [initExpenseList]);

  const handlePageClick = (event: any) => {
    const pageNo = event.selected + 1;
    setcurrentPage(pageNo);
  };

  return (
    <div className="container-fluid pt-4 px-4">
      <HeadingTitle title="Expense List" />
      <Row className="d-flex flex-wrap align-items-baseline justify-content-between">
        <Col sm={4} md={5} lg={4} xl={2}>
          <InputGroup>
            <InputGroup.Text id="inputGroup-sizing-default">
              Page Size
            </InputGroup.Text>
            <Form.Select
              aria-label="Default select example"
              aria-describedby="inputGroup-sizing-default"
              onChange={(event: any) => {
                setPageSize(parseInt(event.target.value));
              }}
            >
              {pageSizeArray.map((item: number) => {
                return (
                  <option value={item} key={item} selected={pageSize === item}>
                    {item}
                  </option>
                );
              })}
            </Form.Select>
          </InputGroup>
        </Col>
        <Col md={5} lg={5} xl={4}>
          <form>
            <Stack direction="horizontal">
              <input
                className="form-control"
                placeholder="Search.."
                type="text"
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
              <Button type="submit" className="m-2 text-primary fw-bold">
                <FontAwesomeIcon className="text-white" icon={faSearch} />
              </Button>
              <ButtonComponent
                tooltip={"Add Expense"}
                variant={"primary"}
                href={PageRoutes.NEW_EXPENSE}
                type={Icons.Add}
                isButton={true}
              />
            </Stack>
          </form>
        </Col>
      </Row>

      {expenseList.length === 0 ? (
        <NoResultFound message="No results" />
      ) : (
        <Table borderless responsive>
          <thead>
            <tr>
              <th>Id</th>
              <th>Description</th>
              <th>Paid by</th>
              <th>Paid to</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {expenseList.map((item: ExpenseItem, index: number) => {
              return (
                  <tr key={index}>
                      <td>{item.id}</td>
                      <td>{item.description}</td>
                      <td> <LinkComponent data={{ type: item.paid_from_type, name: item.paid_from_id }} /> </td>
                      <td> <LinkComponent data={{ type: item.payee_type, name: item.payee_id }} /> </td>
                      <td>{item.total}</td>
                  </tr>
              );
            })}
          </tbody>
        </Table>
      )}
      {expenseList.length > 0 && (
        <Row className="mt-4">
          <Col className="text-center">
            <ReactPaginate
              breakLabel="..."
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="Previous"
              nextLabel="Next"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakClassName="page-item"
              breakLinkClassName="page-link"
              marginPagesDisplayed={2}
              containerClassName="pagination d-inline-flex"
              activeClassName="active"
              initialPage={currentPage - 1}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ExpenseList;
