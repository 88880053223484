import React, { useCallback, useEffect, useState } from "react";
import {
  Row,
  Col,
  InputGroup,
  Form,
  Table,
  Dropdown,
  ButtonGroup,
  Badge,
} from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { useNavigate, useParams } from "react-router-dom";
import { getActiveRestaurantId, getLocalDateTime } from "../../common/Utils";
import ButtonComponent, {
  Icons,
} from "../../components/Buttons/ButtonComponent";
import { useCommon } from "../../components/Context/CommonContext";
import HeadingTitle from "../../components/HeadingTitle";
import NoResultFound from "../../components/NoResultFound";
import { getCurrentSession, getKotList } from "../../services/AdminService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { KOT } from "../../class/kot";
import { OrderData } from "../../class/OrderData";
import CancelKotDialog from "../../components/NewOrder/CancelKotDialog";
import { MessageStrings } from "../../common/Constants";

const CURRENT_SESSION_STORAGE_KEY = "current-session";

type DateQuery = {
  startDate: Date | null;
  endDate: Date | null;
};

const KotHistory = () => {
  const statusArray = ["All", "Pending", "Served", "Cancelled"];
  const pageSizeArray = [50, 100, 200, 500];

  const [currentSessionActive, setCurrentSessionActive] =
    useState<boolean>(false);
  const [dateQuery, setDateQuery] = useState<DateQuery>({
    startDate: new Date(),
    endDate: null,
  });
  const [status, setStatus] = useState<String>(statusArray[0]);

  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setcurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(pageSizeArray[0]);

  const [kotList, setKotList] = useState<KOT[]>([]);

  const [showCancelKotDialog, setShowCancelKotDialog] = useState(false);
  const [activeKotIdToCancel, setActiveKotIdToCancel] = useState<number | null>(
    null
  );

  const { dispatch } = useCommon();
  const navigate = useNavigate();
  // const [searchValue, setSearchValue] = useState("");
  const { id } = useParams();
  const onChange = (dates: [any, any]) => {
    const [start, end] = dates;
    setDateQuery({ startDate: start, endDate: end });
  };

  // const handleSubmit = (e: any) => {
  //   e.preventDefault();
  //   getKotData(searchValue, dateQuery);
  // };

  const getKotData = useCallback(
    (orderId: any, dateQuery: DateQuery) => {
      dispatch({ type: "loading", payload: true });
      getKotList(
        getActiveRestaurantId(),
        dateQuery.startDate,
        dateQuery.endDate,
        currentSessionActive,
        currentPage,
        pageSize
      )
        .then((response: any) => {
          dispatch({ type: "loading", payload: false });
          setKotList(response?.results);
          setPageCount(Math.ceil(response.count / pageSize));
        })
        .catch((error) => {
          dispatch({
            type: "messageModal",
            payload: {
              visible: true,
              title: MessageStrings.ERROR_MESSAGE_TITLE,
              message: MessageStrings.ERROR_MESSAGE_GENERIC,
            },
          });
        });
    },
    [currentPage, currentSessionActive, dispatch, pageSize]
  );

  const getCurrentSessionsData = useCallback(() => {
    dispatch({ type: "loading", payload: true });
    getCurrentSession(getActiveRestaurantId())
      .then((response: any) => {
        setDateQuery({
          startDate: new Date(response.created_at),
          endDate: new Date(),
        });
      })
      .catch(() => {});
  }, [dispatch]);

  useEffect(() => {
    getKotData(id, dateQuery);
  }, [dateQuery, getKotData, id]);

  const handlePageClick = (event: any) => {
    const pageNo = event.selected + 1;
    setcurrentPage(pageNo);
  };

  return (
    <>
      <div className="container-fluid pt-4 px-4">
        <div className="d-flex align-items-center justify-content-between">
          <HeadingTitle title="KOT History" />
        </div>
        <Row className="d-flex align-items-center">
          <Col sm={6} lg={3} xl={3}>
            <DatePicker
              showIcon={true}
              selected={dateQuery.startDate}
              onChange={onChange}
              startDate={dateQuery.startDate}
              endDate={dateQuery.endDate}
              selectsRange
              placeholderText="Select date or range"
              dateFormat="MMM d, yyyy"
              className="form-control"
            />
          </Col>
          <Col sm={6} lg={2} xl={2}>
            <InputGroup>
              <InputGroup.Text id="inputGroup-sizing-default">
                Page Size
              </InputGroup.Text>
              <Form.Select
                aria-label="Default select example"
                aria-describedby="inputGroup-sizing-default"
                onChange={(event) => {
                  setPageSize(parseInt(event.target.value));
                }}
              >
                {pageSizeArray.map((item: number) => {
                  return (
                    <option
                      value={item}
                      key={item}
                      selected={pageSize === item}
                    >
                      {item}
                    </option>
                  );
                })}
              </Form.Select>
            </InputGroup>
          </Col>
          <Col sm={6} md={6} lg={{ span: 3, offset: 4 }}>
            <Form className="form-control mt-1 mt-md-0">
              <Form.Check // prettier-ignore
                checked={currentSessionActive}
                type="switch"
                id="custom-switch"
                label="Show only ongoing session"
                onChange={(event) => {
                  setCurrentSessionActive(event.target.checked);

                  if (event.target.checked) {
                    sessionStorage.setItem(CURRENT_SESSION_STORAGE_KEY, "true");
                    getCurrentSessionsData();
                  } else {
                    sessionStorage.setItem(
                      CURRENT_SESSION_STORAGE_KEY,
                      "false"
                    );
                    setDateQuery({
                      startDate: new Date(),
                      endDate: null,
                    });
                  }
                }}
              />
            </Form>
          </Col>
          {/* <Col sm={6} md={6} lg={2}>
          <form onSubmit={handleSubmit}>
            <Stack direction="horizontal">
              <input
                className="form-control"
                placeholder="Order id"
                type="number"
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
              <Button type="submit" className="m-2 text-primary fw-bold">
                <FontAwesomeIcon className="text-white" icon={faSearch} />
              </Button>
            </Stack>
          </form>
        </Col> */}
        </Row>
        {kotList.length === 0 ? (
          <NoResultFound message="No results found" />
        ) : (
          <Table
            borderless
            responsive
            className="mt-2"
            style={{ minHeight: "200px" }}
          >
            <thead>
              <tr>
                <th>Date</th>
                <th>Waiter</th>
                <th>User</th>
                <th>KotId</th>
                <th>OrderId</th>
                <th className="toggle-table-header">
                  <Dropdown as={ButtonGroup}>
                    <Dropdown.Toggle id="dropdown-custom-components">
                      {status !== statusArray[0] ? (
                        <Badge bg="info" className="ms-1">
                          {status}
                        </Badge>
                      ) : (
                        <>Status</>
                      )}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {statusArray.map((item, index) => {
                        return (
                          <Dropdown.Item
                            key={index}
                            eventKey={index}
                            active={status === item ? true : false}
                            onClick={() => {
                              setStatus(item);
                            }}
                          >
                            {item}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </th>
                <th>Table</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {kotList
                .filter((item: any) => {
                  if (status !== statusArray[0]) {
                    return status.toUpperCase() === item.status;
                  }

                  return true;
                })
                .map((node: KOT) => (
                  <tr key={node.id}>
                    <td>{getLocalDateTime(node.created_at)}</td>
                    <td>{(node.order as OrderData).waiter.username}</td>
                    <td
                      className="pointer text-primary"
                      onClick={() => {
                        if ((node.order as OrderData).user?.id) {
                          navigate(
                            `/user/${(node.order as OrderData).user?.id}`
                          );
                        }
                      }}
                    >
                      {(node.order as OrderData).user?.username}
                    </td>
                    <td>{node.id}</td>
                    <td
                      className="pointer text-primary"
                      onClick={() => {
                        node.status === "SERVED"
                          ? navigate(`/order/${node.id}/detail`)
                          : navigate(
                              `/company/${getActiveRestaurantId()}/order/${
                                (node.order as OrderData).id
                              }/?table=${(node.order as OrderData).table.id}`,
                              { replace: true }
                            );
                      }}
                    >
                      {(node.order as OrderData).id}
                    </td>
                    <td>
                      {node.status === "ONGOING" && (
                        <Badge bg="warning">Ongoing</Badge>
                      )}
                      {node.status === "SERVED" && (
                        <Badge bg="success">Served</Badge>
                      )}
                      {node.status === "CANCELLED" && (
                        <Badge bg="danger">Cancelled</Badge>
                      )}
                    </td>
                    <td>{(node.order as OrderData).table.name}</td>
                    <td>
                      {node.status === "ONGOING" && (
                        <ButtonComponent
                          tooltip={"Cancel"}
                          variant={"primary"}
                          type={Icons.Cancel}
                          isButton={false}
                          onSubmitClick={(e: any) => {
                            if (node.id != null) {
                              setActiveKotIdToCancel(node.id!);
                              setShowCancelKotDialog(true);
                            }
                          }}
                        />
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        )}

        {kotList.length > 0 && (
          <Row className="mt-4">
            <Col className="text-center">
              <ReactPaginate
                breakLabel="..."
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="Previous"
                nextLabel="Next"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakClassName="page-item"
                breakLinkClassName="page-link"
                marginPagesDisplayed={2}
                containerClassName="pagination d-inline-flex"
                activeClassName="active"
                initialPage={currentPage - 1}
              />
            </Col>
          </Row>
        )}
      </div>

      <CancelKotDialog
        showCancelKotDialog={showCancelKotDialog}
        setShowCancelKotDialog={setShowCancelKotDialog}
        kotId={activeKotIdToCancel}
      />
    </>
  );
};

export default KotHistory;
