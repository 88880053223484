import React, { useEffect, useMemo, useState } from "react";
import { useCommon } from "../../../components/Context/CommonContext";
import imageCompression from "browser-image-compression";
import { options } from "../../../common/Utils";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  getPaymentMethodDetail,
  patchPaymentMethod,
} from "../../../services/AdminService";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import _ from "lodash";
import { PageRoutes } from "../../../common/Constants";
interface paymetMethodProps {
  name: string;
  image: File[];
  is_active_in_pos: string;
}

const EditPaymentMethods = () => {
  const [oldImage, setOldImage] = useState<string | null>(null);
  const navigate = useNavigate();
  const bid = useParams().bid;
  const pid = useParams().pid;
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<paymetMethodProps>({ criteriaMode: "all" });
  const { dispatch } = useCommon();

  const handleImageCompression = (file: FileList) => {
    return new Promise((resolve, reject) => {
      try {
        if (file![0].size / 1024 / 1024 > 5) {
          dispatch({
            type: "toast",
            payload: {
              visible: true,
              message: "File Size is greater than 5MB",
              success: false,
            },
          });
          reject("File size is greater than 5MB");
        } else {
          imageCompression(file[0], options).then((BlobFile) => {
            const compressedFile = new File(
              [BlobFile],
              "_logo_" + Date.now() + ".png"
            );
            resolve(compressedFile);
          });
        }
      } catch (error) {
        dispatch({ type: "loading", payload: false });
        dispatch({
          type: "toast",
          payload: {
            visible: true,
            message: error,
            success: false,
          },
        });
        reject(error);
      }
    });
  };

  const handleInsert: SubmitHandler<paymetMethodProps> = (event: any) => {
    dispatch({ type: "loading", payload: true });

    const data = new FormData();
    data.append("name", event.name);
    data.append("is_active_in_pos", event.is_active_in_pos);

    if (event.image.length > 0) {
      handleImageCompression(event.image)
        .then((compressedFile: any) => {
          data.append("image", compressedFile);
          uploadPaymentMethod(data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      uploadPaymentMethod(data);
    }
  };

  const uploadPaymentMethod = (data: any) => {
    if (pid !== undefined)
      patchPaymentMethod(data, pid)
        .then((response: any) => {
          if (response) {
            dispatch({
              type: "toast",
              payload: {
                visible: true,
                message: `Edited Successfully`,
                success: true,
              },
            });
            navigate(PageRoutes.BANKING + "/" + bid + "/payment-methods");
          }
        })
        .catch(() => {
          dispatch({ type: "loading", payload: false });
          dispatch({
            type: "toast",
            payload: {
              visible: true,
              message: "Error",
              success: false,
            },
          });
        })
        .finally(() => {
          dispatch({ type: "loading", payload: false });
        });
  };

  const isEdit = useMemo(() => Boolean(bid), [bid]);
  useEffect(() => {
    if (!_.isNil(bid) && !_.isNil(pid))
      getPaymentMethodDetail(bid, pid).then((response: any) => {
        setOldImage(response.image);

        setValue("name", response.name);
        setValue(
          "is_active_in_pos",
          response.is_active_in_pos ? "true" : "false"
        );
      });
  }, [bid, isEdit, pid, setValue]);

  return (
    <div className="container-fluid pt-4 px-4">
      <div className="row col-sm-12 ">
        <div className="col-sm-12 col-xl-6">
          <div className="bg-light  rounded h-100 p-4">
            <h6>Edit Payment Method</h6>
            <form onSubmit={handleSubmit(handleInsert)}>
              <div className="row">
                <div className="col-sm-12 ">
                  <div className="mb-3">
                    <label htmlFor="Name" className="form-label">
                      <small>Name</small>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      autoComplete="off"
                      placeholder="payment method"
                      {...register("name", { required: "Required" })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="name"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <p key={type} className="text-danger">
                            * {message}
                          </p>
                        ))
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="Abbreviation" className="form-label">
                      <small>
                        Active in POS <span className="text-primary">*</span>
                      </small>
                    </label>
                    <select
                      id="disabledSelect"
                      className="form-select"
                      {...register("is_active_in_pos", { required: true })}
                    >
                      <option value={"true"}>Yes</option>
                      <option value={"false"}>No</option>
                    </select>
                    <ErrorMessage
                      errors={errors}
                      name="abbreviation"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <p key={type} className="text-danger">
                            * {message}
                          </p>
                        ))
                      }
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="Name" className="form-label">
                      <small>Image</small>
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      {...register("image")}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="image"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <p key={type} className="text-danger">
                            * {message}
                          </p>
                        ))
                      }
                    />
                  </div>

                  {watch("image") && watch("image").length > 0 ? (
                    <img
                      src={URL.createObjectURL(watch("image")[0])}
                      className="pointer"
                      height={60}
                      width={60}
                      alt="images"
                    />
                  ) : (
                    <img
                      src={oldImage || "/img/default_restro_image.png"}
                      className="pointer"
                      height={60}
                      width={60}
                      alt="images"
                    />
                  )}
                  <div className="row d-flex align-items-center">
                    <div className="col-sm-5">
                      <button type="submit" className="btn btn-primary mt-2">
                        Proceed
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPaymentMethods;
