import Lottie from "lottie-react";
import notFoundAnimation from "../../src/lottie/notFound.json";

const NoResultFound = ({ message }: { message: string }) => {
  return (
    <>
      <div
        id="spinner"
        className="show d-flex align-items-center justify-content-center"
      >
        <Lottie animationData={notFoundAnimation} />
        <br />
        <h5 className="text-muted position-absolute">{message}</h5>
      </div>
    </>
  );
};

export default NoResultFound;
