import React from "react";

const LinkComponent = (props: any) => {
  console.log(props.data);

  return (
    <>
      {(() => {
        if (props.data.type === "expense") {
          return <a href={`/expense/`}>{props.data.name}</a>;
        } else if (props.data.type === "settlement") {
          return <a href={`/settlement/`}>{props.data.name}</a>;
        } else if (props.data.type === "payment") {
          return <a href={`/invoices/`}>{props.data.name}</a>;
        } else if (props.data.type === "order") {
          return <a href={`/order/${props.data.name}/detail`}>{props.data.name}</a>;
        } else if (props.data.type === "bankaccount") {
          return (
            <a href={`/banking/${props.data.name.id}`}>
              {props.data.name.name}
            </a>
          );
        } else if (props.data.type === "supplier") {
          return (
            <a href={`/supplier/${props.data.name.id}`}>
              {props.data.name.name}
            </a>
          );
        } else if (props.data.type === "user") {
          return (
            <a href={`/customer/${props.data.name.id}`}>
              {props.data.name.first_name} {props.data.name.last_name}
            </a>
          );
        } else {
          return <>{props.data.name}</>;
        }
      })()}
    </>
  );
};

export default LinkComponent;
