import React, { useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { User } from "../../class/User";
import { useCommon } from "../Context/CommonContext";
import UserSearchBox from "../SearchBox/UserSearchBox";
import { assignCompanyCustomers } from "../../services/AdminService";
import { MessageStrings } from "../../common/Constants";
import { parseErrorMessage } from "../../common/Utils";
const UserSearchDialog = ({
  showAssignCustomerDialog,
  setShowAssignCustomerDialog,
}: {
  showAssignCustomerDialog: boolean;
  setShowAssignCustomerDialog: any;
}) => {
  const handleCloseAssignCustomerDialog = () =>
    setShowAssignCustomerDialog(false);

  const { dispatch } = useCommon();
  const [selectedUser, setSelectedUser] = useState<User[]>([]);

  const handleAdd = (event: any) => {
    event.preventDefault();
    if (selectedUser.length > 0) {
      assignCompanyCustomers({
        user: selectedUser[0].id,
      })
        .then((response) => {
          if (response) {
            setSelectedUser([]);
            dispatch({
              type: "toast",
              payload: {
                visible: true,
                message: "Customer assigned succesfully.",
              },
            });
            handleCloseAssignCustomerDialog();
            window.location.reload();
          }
        })
        .catch((error) => {
          if (parseErrorMessage(error)) {
            dispatch({
              type: "toast",
              payload: {
                visible: true,
                message: parseErrorMessage(error),
              },
            });
          } else {
            dispatch({
              type: "messageModal",
              payload: {
                visible: true,
                title: MessageStrings.ERROR_MESSAGE_TITLE,
                message: MessageStrings.ERROR_MESSAGE_GENERIC,
              },
            });
          }
        });
    }
  };

  return (
    <Modal
      show={showAssignCustomerDialog}
      onHide={handleCloseAssignCustomerDialog}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Assign Customer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleAdd}>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Username</Form.Label>
                <UserSearchBox
                  label={"username"}
                  name={"username"}
                  onChange={(e: any) => {
                    if (e && e.length > 0) setSelectedUser(e);
                    else setSelectedUser([]);
                  }}
                  selectedUser={selectedUser}
                  placeholder={"Search user"}
                  onCreateSuccess={() => {
                    handleCloseAssignCustomerDialog();
                    window.location.reload();
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col className="mt-3">
              <Form.Group>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={selectedUser.length === 0}
                >
                  Confirm
                </button>
              </Form.Group>
            </Col>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default UserSearchDialog;
