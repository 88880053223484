import React, { useEffect, useState } from "react";
import { KOT, KOTITEM } from "../../class/kot";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBellConcierge, faUser } from "@fortawesome/free-solid-svg-icons";
import {
  getInvoices,
  getOrderData,
  getPaymentMethods,
} from "../../services/AdminService";
import { useParams } from "react-router-dom";
import {
  capitalizeFirstLetter,
  getActiveRestaurantId,
} from "../../common/Utils";
import { OrderData } from "../../class/OrderData";
import { useCommon } from "../../components/Context/CommonContext";
import {
  Accordion,
  Badge,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Stack,
  Tooltip,
  Image,
  ListGroup,
} from "react-bootstrap";
import ContextAwareToggle from "../../components/NewOrderAccordion";
import UserSearch from "../../components/User/UserSearch";
import SearchWaiter from "../../components/User/SearchWaiter";
import { PaymentType } from "../../class/PaymentType";
import KotDetails from "../../components/NewOrder/KotDetails";
import NonKotDetails from "../../components/NewOrder/NonKotDetails";
import CustomerCreateModal from "../companyCustomers/CustomerCreateModal";
import { PaymentMethod } from "../../class/PaymentMethod";
import HeadingTitle from "../../components/HeadingTitle";
const OrderDetail = () => {
  const { id } = useParams();
  const [orderDetails, setOrderDetails] = useState<OrderData>();
  const [paymentDetail, setPaymentDetail] = useState<PaymentType>();
  const [kotLists, setKotLists] = useState<KOT[] | []>([]);
  const [nonkotLists, setNonKotLists] = useState<KOTITEM[] | []>([]);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);

  const [showCreateCustomerDialog, setShowCreateCustomerDialog] =
    useState(false);

  const { dispatch } = useCommon();
  useEffect(() => {
    dispatch({ type: "loading", payload: true });
    getPaymentMethods(getActiveRestaurantId())
      .then((response) => {
        setPaymentMethods(response.results);
      })
      .catch((error) => {
        console.log(error);
      });

    getOrderData(getActiveRestaurantId(), Number(id)).then((response) => {
      dispatch({ type: "loading", payload: false });
      setOrderDetails(response);
      setKotLists(response.kot);
      setNonKotLists(response.items);
    });

    getInvoices(undefined, undefined, Number(id))
      .then((response: any) => {
        dispatch({ type: "loading", payload: false });
        setPaymentDetail(response?.results[0]);
      })
      .catch(() => {});
  }, [dispatch, id]);

  return (
    <>
      <div className="container-fluid pt-4 px-4">
        <Row>
          <HeadingTitle title="Billing Details" />

          <Col xs={12} md={6}>
            <div className="gx-0 full row">
              <div>
                <ListGroup className="mb-2">
                  <ListGroup.Item>
                    <div className="d-flex justify-content-between">
                      <span>Order No</span>

                      <h6 className="text-primary">#{orderDetails?.id}</h6>
                    </div>
                    <div className="d-flex  justify-content-between">
                      <span>User</span>
                      <h6
                        className={
                          orderDetails?.user ? "text-success" : "text-danger"
                        }
                      >
                        {orderDetails?.user
                          ? orderDetails?.user.username
                          : "None"}
                      </h6>
                    </div>
                    <div className="d-flex  justify-content-between">
                      <span>Table</span>
                      <h6>{orderDetails?.table?.name}</h6>
                    </div>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <div className="d-flex justify-content-between">
                      <span>Paid by Company</span>
                      <span>
                        {paymentDetail?.is_non_recievable && (
                          <Badge bg="info">Yes</Badge>
                        )}
                        {!paymentDetail?.is_non_recievable && <Badge>No</Badge>}
                      </span>
                    </div>
                  </ListGroup.Item>

                  {paymentDetail?.methods.map((node: any, index: number) => (
                    <ListGroup.Item key={index}>
                      <Row className="mb-1">
                        <Col>
                          {paymentMethods
                            .filter((method) => method.id === node.bank_method)
                            .map((method: any, index: number) => (
                              <>
                                <Image
                                  src={method.image}
                                  alt={method.name}
                                  style={{
                                    borderRadius: "100px",
                                  }}
                                  width="45px"
                                  height="45px"
                                  className={`pointer border border-2 border-light"
                      }`}
                                />{" "}
                                {capitalizeFirstLetter(method.name)}
                                {node.is_cash_return ? " Return" : ""}
                              </>
                            ))}
                        </Col>
                        <Col>
                          <div className="mb-2 text-end">
                            {node.is_cash_return ? "- " : ""}
                            {node.total}
                          </div>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}

                  <ListGroup.Item>
                    <div className="d-flex justify-content-between ">
                      <span>Sub Total</span>
                      <h6 className=" fw-bold">NRs. {orderDetails?.amount}</h6>
                    </div>

                    <div className="d-flex justify-content-between ">
                      <span>Discount</span>
                      <div className="align-items-start">
                        <h6 className=" fw-bold align-items-start">
                          NRs.{" "}
                          {Number(paymentDetail?.total) -
                            Number(paymentDetail?.paid)}
                        </h6>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between ">
                      <span>Total Paid </span>
                      <h6 className="text-success fw-bold">
                        NRs. {orderDetails?.total}
                      </h6>
                    </div>
                  </ListGroup.Item>
                </ListGroup>
              </div>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <Accordion defaultActiveKey="" className="mb-3 border rounded ">
              <Stack direction="horizontal" gap={3} className="p-2">
                <ContextAwareToggle eventKey="0">
                  <OverlayTrigger
                    placement="left"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id="button-tooltip">
                        {!_.isNil(orderDetails?.user)
                          ? orderDetails?.user?.username
                          : "Not assigned"}
                      </Tooltip>
                    }
                  >
                    <div className="bg-light text-center p-2 ">
                      <FontAwesomeIcon icon={faUser} aria-hidden="true" />
                    </div>
                  </OverlayTrigger>
                </ContextAwareToggle>
                <ContextAwareToggle eventKey="1">
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id="button-tooltip">
                        {!_.isNil(orderDetails?.waiter)
                          ? orderDetails?.waiter.username
                          : "Not assigned"}
                      </Tooltip>
                    }
                  >
                    <div className="bg-light text-center p-2 ">
                      <FontAwesomeIcon
                        icon={faBellConcierge}
                        aria-hidden="true"
                      />
                    </div>
                  </OverlayTrigger>
                </ContextAwareToggle>
              </Stack>

              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <UserSearch
                    activeCustomer={
                      !_.isNil(orderDetails) ? orderDetails?.user : null
                    }
                    initialNumberOfGuest={
                      !_.isNil(orderDetails) ? orderDetails.no_of_guest : null
                    }
                    setShowCreateCustomerDialog={(value: boolean) => {
                      setShowCreateCustomerDialog(value);
                    }}
                  />
                </Card.Body>
              </Accordion.Collapse>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <SearchWaiter
                    activeCustomer={
                      !_.isNil(orderDetails?.user)
                        ? orderDetails?.user.id
                        : null
                    }
                    initialNumberOfGuest={
                      !_.isNil(orderDetails?.no_of_guest)
                        ? orderDetails?.no_of_guest
                        : null
                    }
                    initialWaiterId={orderDetails?.waiter?.id}
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Accordion>

            {!_.isNil(id) && nonkotLists.length > 0 && (
              <NonKotDetails
                data={nonkotLists}
                handleCancelKot={() => {}}
                showModelOnResponse={false}
                onAddOrder={() => {}}
                deleteKotData={() => {}}
              />
            )}
            {!_.isNil(id) && !_.isNil(kotLists) && (
              <KotDetails
                data={kotLists}
                handleCancelKot={() => {}}
                showModelOnResponse={false}
                onAddOrder={() => {}}
                deleteKotData={() => {}}
              />
            )}
          </Col>
        </Row>
      </div>

      <CustomerCreateModal
        setShowCreateCustomerDialog={setShowCreateCustomerDialog}
        showCreateCustomerDialog={showCreateCustomerDialog}
        onCreateSuccess={() => {}}
      />
    </>
  );
};

export default OrderDetail;
