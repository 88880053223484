import Modal from "react-bootstrap/Modal";
interface ModelProps{
  model_title?:string;
  show:boolean;
  onHide:any;
  children:JSX.Element
}

const VerticallyCenteredModal = ({model_title,show,onHide,children}:ModelProps) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {model_title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {children}
      </Modal.Body>
    </Modal>
  );
};

export default VerticallyCenteredModal;
