import {
  ADMIN_USER,
  API_DASHBOARD,
  API_DASHBOARD_OVERVIEW,
  API_GET_ACCOUNT,
  API_GET_COMPANY,
  API_GET_COMPANY_DETAIL,
  API_GET_PRODUCT,
  API_GET_REPUTATION,
  API_GET_SIGNUP_DATA_,
  API_GET_TOKEN,
  API_GET_TOPUSERS,
  API_GET_USER_DETAIL,
  API_POST_COMPANY,
  API_POST_PRODUCT,
  API_PRODUCT_INGREDIENT,
  BANK_PAYMENT_METHODS,
  BASE_PATH,
  DATA_ACTIVE_RESTRO_SESSION,
  DATA_ADMIN_USER,
  getKotListApi,
} from "../common/Constants";
import { BehaviorSubject } from "rxjs";
import fetchWrapper from "../common/FetchWrapper";
import { Cart } from "../class/cart";
import { PaymentType } from "../class/PaymentType";
import { getActiveRestaurantId } from "../common/Utils";
import { TableGroup } from "../pages/Tables/AddTableGroup";
import _ from "lodash";
import { ExpenseAPIRequest } from "../class/Expense";

const isBrowser = typeof window !== "undefined";
const sessionKey = ADMIN_USER;

const userSubject = new BehaviorSubject(
  isBrowser &&
    (localStorage.getItem(sessionKey) != null
      ? JSON.parse(localStorage.getItem(sessionKey) || "")
      : null)
);

export const postLogin = async (data: { username: any; password: any }) => {
  const user = await fetchWrapper.post(`${BASE_PATH}${API_GET_TOKEN}`, data);
  // publish user to subscribers and store in local storage to stay logged in between page refreshes
  user.email = data.username;
  userSubject.next(user);
  localStorage.setItem(sessionKey, JSON.stringify(user));
  return user;
};

const postLogout = () => {
  localStorage.removeItem(ADMIN_USER);
  localStorage.removeItem(DATA_ACTIVE_RESTRO_SESSION);
  localStorage.removeItem(DATA_ADMIN_USER);
  userSubject.next(null);
};

export const storeSessionDataInStorage = (data: {
  refresh: string | null;
  access: string | null;
}) => {
  localStorage.setItem(sessionKey, JSON.stringify(data));
};

export const getDasboardData = async () => {
  return await fetchWrapper.get(BASE_PATH + API_DASHBOARD_OVERVIEW);
};

export const getCompanyList = async (
  page: number,
  verifiedFilter: boolean | null
) => {
  let path = BASE_PATH + `${API_GET_COMPANY}?page=` + page;
  if (verifiedFilter != null) {
    path =
      BASE_PATH +
      `${API_GET_COMPANY}?page=` +
      page +
      `&verified=` +
      verifiedFilter;
  }
  return await fetchWrapper.get(path);
};

export const getSearchList = async (name: string, page_size: number) => {
  return await fetchWrapper.get(
    BASE_PATH + `${API_GET_COMPANY}?name=` + name + `&page_size=` + page_size
  );
};

export const deleteCompany = async (id: number, data: FormData) => {
  return await fetchWrapper.formPatch(
    BASE_PATH + `${API_GET_COMPANY}${id}/`,
    data
  );
};
export const verifyCompany = async (id: number, data: FormData) => {
  return await fetchWrapper.formPatch(
    BASE_PATH + `${API_GET_COMPANY}${id}/`,
    data
  );
};

export const verifyProduct = async (
  cid: number,
  id: number,
  data: FormData
) => {
  return await fetchWrapper.formPatch(
    BASE_PATH + `${API_GET_COMPANY}${cid}/product/${id}/`,
    data
  );
};
export const getProductList = async (limit: number) => {
  return await fetchWrapper.get(
    BASE_PATH + `${API_GET_PRODUCT}?limit=` + limit
  );
};
export const getProductLists = async (
  companyId: string | null,
  verifiedFilter: boolean | null
) => {
  let path = BASE_PATH + `${API_GET_COMPANY}`;
  if (companyId) {
    path = BASE_PATH + `${API_GET_COMPANY}${companyId}/product/?page_size=100`;
  } else {
    path = BASE_PATH + `api/product/?page_size=100`;
  }
  if (verifiedFilter != null) {
    path = path + `&verified=` + verifiedFilter;
  }
  return await fetchWrapper.get(path);
};

export const getCompanyProductLists = async (
  page: number,
  companyId: string
) => {
  return await fetchWrapper.get(
    BASE_PATH + `${API_GET_COMPANY}${companyId}/product/?page=${page}`
  );
};

export const getUsers = async (page: number) => {
  let path = BASE_PATH + `${API_GET_ACCOUNT}?page=` + page + "&page_size=50";
  return await fetchWrapper.get(path);
};

export const searchUserList = async (username: string, page_size: number) => {
  return await fetchWrapper.get(
    BASE_PATH +
      `${API_GET_ACCOUNT}?username=` +
      username +
      `&page_size=` +
      page_size
  );
};
export const getUserDetail = async (uid: number) => {
  return await fetchWrapper.get(BASE_PATH + API_GET_ACCOUNT + `${uid}`);
};
export const getUserReputationList = async (uid: number) => {
  return await fetchWrapper.get(
    BASE_PATH + API_GET_REPUTATION + `${uid}?page_size=100`
  );
};
export const getUserLoginDataList = async (uid: number) => {
  return await fetchWrapper.get(
    BASE_PATH + API_GET_ACCOUNT + `${uid}/login?page_size=20`
  );
};
export const insertCompany = async (data: FormData) => {
  return await fetchWrapper.formPost(`${BASE_PATH}${API_POST_COMPANY}`, data);
};
export const updateCompany = async (data: FormData, id: string) => {
  return await fetchWrapper.formPatch(
    `${BASE_PATH}${API_POST_COMPANY}${id}/`,
    data
  );
};
export const getCompanyReview = async (
  page: number,
  page_size: number,
  companyno: number
) => {
  return await fetchWrapper.get(
    BASE_PATH +
      `${API_GET_COMPANY}${companyno}/review/?page=${page}&page_size=${page_size}`
  );
};
export const deleteCompanyReview = async (
  cid: number,
  commentid: number,
  data: FormData
) => {
  return await fetchWrapper.formPatch(
    BASE_PATH + `${API_GET_COMPANY}${cid}/review/${commentid}/`,
    data
  );
};
export const deleteProductReview = async (
  cid: number,
  productid: number,
  commentid: number,
  data: FormData
) => {
  return await fetchWrapper.formPatch(
    BASE_PATH +
      `${API_GET_COMPANY}${cid}/product/${productid}/review/${commentid}/`,
    data
  );
};

export const getProductReview = async (
  page: number,
  page_size: number,
  companyno: number,
  productno: number
) => {
  return await fetchWrapper.get(
    BASE_PATH +
      `${API_GET_COMPANY}${companyno}/product/${productno}/review/?page=${page}&page_size=${page_size}`
  );
};
export const getProductReviews = async () => {
  return await fetchWrapper.get(BASE_PATH + `${API_DASHBOARD}product-reviews/`);
};
export const getCompanyReviews = async () => {
  return await fetchWrapper.get(BASE_PATH + `${API_DASHBOARD}company-reviews/`);
};

export const getSpecificProductReview = async (
  cid: number,
  pid: number,
  commentid: number
) => {
  return await fetchWrapper.get(
    BASE_PATH + `${API_GET_COMPANY}${cid}/product/${pid}/review/${commentid}/`
  );
};
export const updateProduct = async (
  data: FormData,
  cid: string,
  pid: string
) => {
  return await fetchWrapper.formPut(
    `${BASE_PATH}${API_GET_COMPANY}${cid}/product/${pid}/`,
    data
  );
};
export const updateProductUsingJson = async (
  data: any,
  cid: string,
  pid: string
) => {
  return await fetchWrapper.patch(
    `${BASE_PATH}${API_GET_COMPANY}${cid}/product/${pid}/`,
    data
  );
};

export const addProduct = async (data: FormData, companyId: string) => {
  return await fetchWrapper.formPost(
    `${BASE_PATH}api/company/${companyId}/${API_POST_PRODUCT}`,
    data
  );
};

export const searchProduct = async (
  query: string,
  page_size: number,
  companyId: number
) => {
  if (companyId === -1) {
    return await fetchWrapper.get(
      BASE_PATH + `api/product?name=${query}&page_size=${page_size}`
    );
  } else
    return await fetchWrapper.get(
      BASE_PATH +
        `${API_GET_COMPANY}${companyId}/product?name=${query}&page_size=${page_size}`
    );
};

export const getCompanyDetail = async (id: string) => {
  return await fetchWrapper.get(BASE_PATH + `${API_GET_COMPANY_DETAIL}/${id}`);
};

export const getProductDetail = async (cid: string, pid: string) => {
  return await fetchWrapper.get(
    BASE_PATH + `${API_GET_COMPANY}${cid}/product/${pid}`
  );
};

export const getUserData = async () => {
  return await fetchWrapper.get(`${BASE_PATH}${API_GET_USER_DETAIL}`);
};

export const getTopUsers = async () => {
  return await fetchWrapper.get(`${BASE_PATH}${API_GET_TOPUSERS}`);
};
export const getUserRegistrationData = async (
  startDate: string,
  endDate: string,
  viewBy: string
) => {
  return await fetchWrapper.get(
    BASE_PATH +
      `${API_GET_SIGNUP_DATA_}?report_of=${viewBy}&from=${startDate}T00:00:00Z&to=${endDate}T23:59:59Z`
  );
};

export const deleteCompanyImage = async (
  companyId: string,
  type: string,
  imageId: string
) => {
  return await fetchWrapper.delete(
    `${BASE_PATH}${API_GET_COMPANY_DETAIL}/${companyId}/${type}/${imageId}`
  );
};
export const deleteProductImage = async (
  companyId: string,
  type: string,
  productId: string,
  imageId: string
) => {
  return await fetchWrapper.delete(
    `${BASE_PATH}${API_GET_COMPANY_DETAIL}/${companyId}/product/${productId}/photo/${imageId}/`
  );
};

export const addCompanyImage = async (
  companyId: string,
  type: string,
  file: File
) => {
  const formData = new FormData();
  formData.append("image", file);
  return await fetchWrapper.formPost(
    `${BASE_PATH}${API_GET_COMPANY_DETAIL}/${companyId}/${type}/`,
    formData
  );
};
export const addProductImage = async (
  companyId: string,
  productId: string,
  type: string,
  file: File
) => {
  const formData = new FormData();
  formData.append("image", file);
  return await fetchWrapper.formPost(
    `${BASE_PATH}${API_GET_COMPANY_DETAIL}/${companyId}/product/${productId}/photo/`,
    formData
  );
};
export const addProductReview = async (
  data: FormData,
  cid: number,
  id: number
) => {
  return await fetchWrapper.formPost(
    BASE_PATH + `${API_GET_COMPANY}${cid}/product/${id}/review/`,
    data
  );
};
export const editProductReview = async (
  data: FormData,
  cid: number,
  pid: number,
  commentid: number
) => {
  return await fetchWrapper.formPatch(
    BASE_PATH + `${API_GET_COMPANY}${cid}/product/${pid}/review/${commentid}/`,
    data
  );
};

export const addCompanyReview = async (cid: number, data: FormData) => {
  return await fetchWrapper.formPost(
    BASE_PATH + `${API_GET_COMPANY}${cid}/review/`,
    data
  );
};
export const addReviewImage = async (
  companyId: string,
  productId: string,
  type: string,
  file: File
) => {
  const formData = new FormData();
  formData.append("image", file);
  return await fetchWrapper.formPost(
    `${BASE_PATH}${API_GET_COMPANY_DETAIL}/${companyId}/product/${productId}/review/`,
    formData
  );
};
export const deleteReviewImage = async (
  companyId: string,
  type: string,
  productId: string,
  imageId: string
) => {
  return await fetchWrapper.delete(
    `${BASE_PATH}${API_GET_COMPANY_DETAIL}/${companyId}/product/${productId}/photo/${imageId}/`
  );
};
const AdminService = {
  user: userSubject.asObservable(),
  get userValue() {
    return userSubject.value;
  },
  postLogout,
};

/**
 * Used to enable or disable product
 * @param companyId
 * @param productId
 * @param deleted
 * @returns
 */
export const disableProduct = async (
  companyId: string,
  productId: number,
  deleted: boolean
) => {
  const bodyFormData = new FormData();
  bodyFormData.append("deleted", String(!deleted));
  return await fetchWrapper.formPatch(
    BASE_PATH + `${API_GET_COMPANY}${companyId}/product/${productId}/`,
    bodyFormData
  );
};

/**
 * Used to delete product permanently
 * @param companyId
 * @param productId
 * @returns
 */
export const deleteProduct = async (companyId: string, productId: number) => {
  return await fetchWrapper.delete(
    BASE_PATH + `${API_GET_COMPANY}${companyId}/product/${productId}/`
  );
};

export const addApk = async (
  version: any,
  file: File | null,
  os_type: string
) => {
  const formData = new FormData();

  formData.append("version", String(version));
  if (file !== null && os_type !== "ios") {
    formData.append("file", file);
  }
  formData.append("os_type", os_type);

  return await fetchWrapper.formPost(`${BASE_PATH}api/apk/`, formData);
};

export const getApk = async () => {
  return await fetchWrapper.get(`${BASE_PATH}api/apk`);
};

export const getEmployeeList = async (
  companyId: number,
  viewBy: string | null
) => {
  return await fetchWrapper.get(
    `${BASE_PATH}${API_GET_COMPANY}${companyId}/role`
  );
};

export const deleteEmployeeEntry = async (
  entryId: number,
  companyId: number
) => {
  return await fetchWrapper.delete(
    BASE_PATH + `${API_GET_COMPANY}${companyId}/role/${entryId}/`
  );
};

export const addEmployeeRoleEntry = async (
  companyId: number,
  uid: number,
  roleId: number
) => {
  return await fetchWrapper.post(
    BASE_PATH + `${API_GET_COMPANY}${companyId}/role/`,
    {
      role: roleId,
      user: uid,
    }
  );
};

export const editEmployeeRoleEntry = async (
  companyId: number,
  uid: number,
  roleId: number,
  entryId: string
) => {
  return await fetchWrapper.patch(
    BASE_PATH + `${API_GET_COMPANY}${companyId}/role/${entryId}/`,
    {
      role: roleId,
      user: uid,
    }
  );
};

export const getEmployeeRoleList = async () => {
  return await fetchWrapper.get(`${BASE_PATH}api/role-name/`);
};

export const getRoleDetail = async (cid: string, id: string) => {
  return await fetchWrapper.get(`${BASE_PATH}api/company/${cid}/role/${id}/`);
};

/**
 * Get latest product reviews listing using company ID
 * @param cid
 * @returns
 */
export const getLatestProductReviews = async (cid: string) => {
  return await fetchWrapper.get(
    `${BASE_PATH}api/company/${cid}/product/review/`
  );
};
/**
 * to get product categories of a company
 * @param cid companyId
 */
export const getCompanyProductCategory = async (cid: string) => {
  return await fetchWrapper.get(`${BASE_PATH}api/company/${cid}/category/`);
};
/**
 * to create product category of a company
 * @param cid companyId
 */
export const addCompanyProductCategory = async (
  cid: string,
  data: FormData
) => {
  return await fetchWrapper.formPost(
    `${BASE_PATH}api/company/${cid}/category/`,
    data
  );
};
/**
 * add products to category
 * @param cid companyId
 * @param categoryId categoryId of company
 *
 */
export const categorizeCompanyProducts = async (
  cid: any,
  categoryId: string,
  data: any
) => {
  return await fetchWrapper.post(
    `${BASE_PATH}api/company/${cid}/category/${categoryId}/`,
    data
  );
};

/**
 * delete a single product from category
 * @param cid companyId
 * @param categoryId categoryId of company
 *
 */
export const deleteProductFromCategory = async (
  cid: number,
  categoryId: number,
  data: any
) => {
  return await fetchWrapper.post(
    `${BASE_PATH}api/company/${cid}/category/${categoryId}/remove/`,
    data
  );
};

export const mergeProduct = async (
  companyId: string,
  productId: number,
  ids: []
) => {
  return await fetchWrapper.post(
    BASE_PATH + `${API_GET_COMPANY}${companyId}/product/${productId}/merge/`,
    {
      merge_items: ids,
    }
  );
};

// Restaurant Tables

export const addRestaurantTable = async (cid: number, data: any) => {
  return await fetchWrapper.post(`${BASE_PATH}api/company/${cid}/table/`, data);
};
export const getCompanyTablesLists = async (cid: number) => {
  return await fetchWrapper.get(`${BASE_PATH}api/company/${cid}/table/all/`);
};

export const getCompanyTables = async (cid: number) => {
  return await fetchWrapper.get(`${BASE_PATH}api/company/${cid}/table/`);
};
export const getRestaurantTableDetail = async (cid: number, id: number) => {
  return await fetchWrapper.get(`${BASE_PATH}api/company/${cid}/table/${id}`);
};
export const deleteRestaurantTable = async (cid: number, tableId: any) => {
  return await fetchWrapper.delete(
    `${BASE_PATH}api/company/${cid}/table/${tableId}/`
  );
};
export const generateNewKot = async (
  cid: number,
  orderId: number,
  data: any
) => {
  return await fetchWrapper.post(`${BASE_PATH}api/company/${cid}/kot/`, data);
};
export const updateRestaurantTable = async (
  cid: number,
  tableId: number,
  data: any
) => {
  return await fetchWrapper.patch(
    `${BASE_PATH}api/company/${cid}/table/${tableId}/`,
    data
  );
};

// export const getLabelDetail = async (LabelId: string | null) => {
//   if (LabelId) {
//     return await fetchWrapper.get(BASE_PATH + `api/explore/label/${LabelId}/`);
//   }
//   return await fetchWrapper.get(BASE_PATH + `api/explore/label/`);
// };

/** place order*/
export const placeNewOrder = async (cid: number, data: Cart) => {
  return await fetchWrapper.post(`${BASE_PATH}api/company/${cid}/order/`, data);
};
export const patchKotOrder = async (
  cid: number,
  orderId: number,
  data: Cart
) => {
  return await fetchWrapper.patch(
    `${BASE_PATH}api/company/${cid}/order/${orderId}/`,
    data
  );
};
export const patchOrderData = async (
  cid: number,
  orderId: number,
  data: any
) => {
  return await fetchWrapper.patch(
    `${BASE_PATH}api/company/${cid}/order/${orderId}/`,
    data
  );
};
/** get order list*/
export const getOrderData = async (cid: number, id: number) => {
  return await fetchWrapper.get(`${BASE_PATH}api/company/${cid}/order/${id}/`);
};

export const getOrderKotCategory = async (cid: number) => {
  return await fetchWrapper.get(`${BASE_PATH}api/company/${cid}/kot-category/`);
};

// Process payment
export const processPayment = async (cid: number, data: PaymentType) => {
  return await fetchWrapper.post(
    `${BASE_PATH}api/company/${cid}/payment/`,
    data
  );
};

// Invoices
export const getInvoices = async (fromDate: any, toDate: any, orderId: any) => {
  let query = "";
  if (orderId !== null && orderId !== undefined) {
    query = `?order=${orderId}`;
  } else if (
    fromDate !== null &&
    fromDate !== undefined &&
    toDate !== null &&
    toDate !== undefined
  ) {
    query = `?from=${fromDate.toISOString().slice(0, 10)}&to=${toDate
      .toISOString()
      .slice(0, 10)}`;
  } else if (fromDate !== null && fromDate !== undefined) {
    query = `?from=${fromDate.toISOString().slice(0, 10)}&to=${fromDate
      .toISOString()
      .slice(0, 10)}`;
  }
  return await fetchWrapper.get(
    `${BASE_PATH}api/company/${getActiveRestaurantId()}/payment/${query}`
  );
};

export const getInvoiceDetail = async (id: string) => {
  return await fetchWrapper.get(
    `${BASE_PATH}api/company/${getActiveRestaurantId()}/payment/${id}/`
  );
};

export const deletePayment = async (orderId: number) => {
  return await fetchWrapper.delete(
    `${BASE_PATH}api/company/${getActiveRestaurantId()}/payment/${orderId}`
  );
};

export const getCompanyTableGroup = async (cid: number) => {
  return await fetchWrapper.get(`${BASE_PATH}api/company/${cid}/table-group/`);
};
export const getCompanyTableGroupDetail = async (cid: number, id: number) => {
  return await fetchWrapper.get(
    `${BASE_PATH}api/company/${cid}/table-group/${id}/`
  );
};
export const addTableGroup = async (cid: number, data: TableGroup) => {
  return await fetchWrapper.post(
    `${BASE_PATH}api/company/${cid}/table-group/`,
    data
  );
};

export const updateTableGroup = async (
  cid: number,
  id: number,
  data: TableGroup
) => {
  return await fetchWrapper.patch(
    `${BASE_PATH}api/company/${cid}/table-group/${id}/`,
    data
  );
};

export const deleteTableGroup = async (cid: number, id: number) => {
  return fetchWrapper.delete(
    `${BASE_PATH}api/company/${cid}/table-group/${id}/`
  );
};

export const updateOrderStatus = async (
  cid: number,
  orderId: number,
  status: any
) => {
  return await fetchWrapper.patch(
    `${BASE_PATH}api/company/${cid}/order/${orderId}/`,
    {
      status: status,
    }
  );
};

export const getWaiterListOfCompany = async (cid: number) => {
  return await fetchWrapper.get(`${BASE_PATH}api/company/${cid}/role/?role=4`);
};

export const getLabelsLists = async (LabelId: string | null) => {
  if (LabelId) {
    return await fetchWrapper.get(BASE_PATH + `api/explore/label/${LabelId}/`);
  }
  return await fetchWrapper.get(BASE_PATH + `api/explore/label/`);
};

export const addLabel = async (data: any) => {
  return await fetchWrapper.formPost(`${BASE_PATH}api/explore/label/`, data);
};

export const updateLabel = async (data: FormData, id: any) => {
  return await fetchWrapper.formPatch(
    `${BASE_PATH}api/explore/label/${id}/`,
    data
  );
};
export const patchLabelImage = async (labelId: string, file: File) => {
  const formData = new FormData();
  formData.append("icon", file);
  return await fetchWrapper.formPatch(
    `${BASE_PATH}api/explore/label/${labelId}/`,
    formData
  );
};

export const getKotList = async (
  cid: number,
  fromDate: any,
  toDate: any,
  currentSession: boolean,
  currentPage: number,
  pageSize: number
) => {
  let query = "";
  if (currentSession) {
    query = "?current-session=true";
  } else if (
    fromDate !== null &&
    fromDate !== undefined &&
    toDate !== null &&
    toDate !== undefined
  ) {
    query = `?from=${fromDate.toISOString().slice(0, 10)}&to=${toDate
      .toISOString()
      .slice(0, 10)}`;
  } else if (fromDate !== null && fromDate !== undefined) {
    query = `?from=${fromDate.toISOString().slice(0, 10)}&to=${fromDate
      .toISOString()
      .slice(0, 10)}`;
  }
  return await fetchWrapper.get(
    `${getKotListApi(cid)}${query}&page=${currentPage}&page_size=${pageSize}`
  );
};

// kot
export const patchKotData = async (cid: number, KotId: number, data: any) => {
  return await fetchWrapper.patch(
    `${BASE_PATH}api/company/${cid}/kot/${KotId}/`,
    data
  );
};

export const deleteKot = async (cid: number, KotId: number, data: any) => {
  return await fetchWrapper.formDelete(
    `${BASE_PATH}api/company/${cid}/kot/${KotId}/`,
    data
  );
};

export const deleteLabel = async (id: number) => {
  return await fetchWrapper.delete(`${BASE_PATH}api/explore/label/${id}/`);
};

export const getOrderLists = async (
  fromDate: any,
  toDate: any,
  orderId: any,
  currentPage: number,
  pageSize: number
) => {
  let query = "?";
  if (orderId !== null && orderId !== undefined) {
    query = `?id=${orderId}`;
  } else if (
    fromDate !== null &&
    fromDate !== undefined &&
    toDate !== null &&
    toDate !== undefined
  ) {
    query = `?from=${fromDate.toISOString().slice(0, 10)}&to=${toDate
      .toISOString()
      .slice(0, 10)}`;
  } else if (fromDate !== null && fromDate !== undefined) {
    query = `?from=${fromDate.toISOString().slice(0, 10)}&to=${fromDate
      .toISOString()
      .slice(0, 10)}`;
  }
  return await fetchWrapper.get(
    `${BASE_PATH}api/company/${getActiveRestaurantId()}/order/${query}&page=${currentPage}&page_size=${pageSize}`
  );
};

export const cancelOrder = async (cid: number, orderId: number, data: any) => {
  return await fetchWrapper.formDelete(
    `${BASE_PATH}api/company/${cid}/order/${orderId}/`,
    data
  );
};

export const getSessionList = async (cid: number, page: number) => {
  return await fetchWrapper.get(
    `${BASE_PATH}api/company/${cid}/counter-session/?page=${page}`
  );
};

export const getCurrentSession = async (cid: number) => {
  return await fetchWrapper.get(
    `${BASE_PATH}api/company/${cid}/counter-session/current/`
  );
};

export const closeCurrentSession = async (
  cid: number,
  close_remarks: string,
  closing_cash: number
) => {
  return await fetchWrapper.post(
    `${BASE_PATH}api/company/${cid}/counter-session/close/`,
    {
      closing_cash: closing_cash,
      closing_description: close_remarks,
    }
  );
};

export const openNewSession = async (
  cid: number,
  opening_cash: number,
  opening_description: string
) => {
  return await fetchWrapper.post(
    `${BASE_PATH}api/company/${cid}/counter-session/`,
    {
      opening_cash: opening_cash,
      opening_description: opening_description,
    }
  );
};

export const searchCategoryList = async () => {
  return await fetchWrapper.get(
    BASE_PATH + `${API_GET_COMPANY}${getActiveRestaurantId()}/coa/?code=600000`
  );
};

export const getBankList = async (searchValue: string | null) => {
  let query = "";
  if (!_.isNil(searchValue)) {
    query = `?name=${searchValue}`;
  }
  return await fetchWrapper.get(
    BASE_PATH +
      `${API_GET_COMPANY}${getActiveRestaurantId()}/bank-account${query}`
  );
};

export const addBankAccount = async (data: any) => {
  return await fetchWrapper.formPost(
    BASE_PATH + `${API_GET_COMPANY}${getActiveRestaurantId()}/bank-account/`,
    data
  );
};
export const getBankAccount = async (bid: any) => {
  return await fetchWrapper.get(
    BASE_PATH +
      `${API_GET_COMPANY}${getActiveRestaurantId()}/bank-account/${bid}/`
  );
};
export const patchBankAccount = async (bid: any, data: any) => {
  return await fetchWrapper.formPatch(
    BASE_PATH +
      `${API_GET_COMPANY}${getActiveRestaurantId()}/bank-account/${bid}/`,
    data
  );
};

export const deleteBank = async (bank_id: number) => {
  return await fetchWrapper.delete(
    BASE_PATH +
      `${API_GET_COMPANY}${getActiveRestaurantId()}/bank-account/${bank_id}/`
  );
};

export const getPaymentMethodsList = async (bid: number) => {
  return await fetchWrapper.get(
    BASE_PATH +
      `${API_GET_COMPANY}${getActiveRestaurantId()}/bank-account/payment-method/?bank=${bid}`
  );
};
export const getPaymentMethod = async () => {
  return await fetchWrapper.get(
    BASE_PATH +
      `${API_GET_COMPANY}${getActiveRestaurantId()}/bank-account/payment-method/`
  );
};
export const getPaymentMethodDetail = async (bid: string, pid: string) => {
  return await fetchWrapper.get(
    BASE_PATH +
      `${API_GET_COMPANY}${getActiveRestaurantId()}/bank-account/payment-method/${pid}/`
  );
};
export const addPaymentMethod = async (data: FormData) => {
  return await fetchWrapper.formPost(
    BASE_PATH +
      `${API_GET_COMPANY}${getActiveRestaurantId()}/bank-account/payment-method/`,
    data
  );
};
export const patchPaymentMethod = async (data: FormData, pid: string) => {
  return await fetchWrapper.formPatch(
    BASE_PATH +
      `${API_GET_COMPANY}${getActiveRestaurantId()}/bank-account/payment-method/${pid}/`,
    data
  );
};

export const addExpense = async (bank_id: number | null, data: any) => {
  if (!_.isNil(bank_id)) {
    return await fetchWrapper.post(
      BASE_PATH +
        `${API_GET_COMPANY}${getActiveRestaurantId()}/bank-account/${bank_id}/`,
      data
    );
  }
};
export const getPaymentMethods = async (cid: number) => {
  return await fetchWrapper.get(
    `${BASE_PATH}api/company/${cid}${BANK_PAYMENT_METHODS}`
  );
};

export const getProductIngredients = async (cid: number, pid: number) => {
  return await fetchWrapper.get(
    `${BASE_PATH}api/company/${cid}/product/${pid}${API_PRODUCT_INGREDIENT}`
  );
};

export const setProductIngredients = async (
  cid: number,
  pid: number,
  data: any
) => {
  return await fetchWrapper.post(
    `${BASE_PATH}api/company/${cid}/product/${pid}${API_PRODUCT_INGREDIENT}`,
    data
  );
};

export const deleteProductIngredients = async (
  cid: number,
  pid: number,
  id: number
) => {
  return await fetchWrapper.delete(
    `${BASE_PATH}api/company/${cid}/product/${pid}${API_PRODUCT_INGREDIENT}${id}/`
  );
};

export const addDeposit = async (bank_id: number | null, data: any) => {
  if (!_.isNil(bank_id)) {
    return await fetchWrapper.post(
      BASE_PATH +
        `${API_GET_COMPANY}${getActiveRestaurantId()}/bank-account/${bank_id}/deposit/`,
      data
    );
  }
};

export const deletePaymentMethod = async (method_id: number) => {
  return await fetchWrapper.delete(
    BASE_PATH +
      `${API_GET_COMPANY}${getActiveRestaurantId()}/bank-account/payment-method/${method_id}/`
  );
};

export const getBankTransaction = async (bank_id: number) => {
  if (!_.isNil(bank_id)) {
    return await fetchWrapper.get(
      BASE_PATH +
        `${API_GET_COMPANY}${getActiveRestaurantId()}/transaction/?type=bankaccount&id=${bank_id}`
    );
  }
};

// Supplier

export const getSupplierList = async () => {
  return await fetchWrapper.get(
    BASE_PATH + `${API_GET_COMPANY}${getActiveRestaurantId()}/supplier`
  );
};
export const getSupplierDetail = async (supplier_id: number) => {
  return await fetchWrapper.get(
    BASE_PATH +
      `${API_GET_COMPANY}${getActiveRestaurantId()}/supplier/${supplier_id}/`
  );
};
export const postSupplier = async (data: any) => {
  return await fetchWrapper.post(
    BASE_PATH + `${API_GET_COMPANY}${getActiveRestaurantId()}/supplier/`,
    data
  );
};
export const patchSupplier = async (bid: any, data: any) => {
  return await fetchWrapper.patch(
    BASE_PATH + `${API_GET_COMPANY}${getActiveRestaurantId()}/supplier/${bid}/`,
    data
  );
};
export const deleteSupplier = async (supplier_id: number) => {
  return await fetchWrapper.delete(
    BASE_PATH +
      `${API_GET_COMPANY}${getActiveRestaurantId()}/supplier/${supplier_id}/`
  );
};

export const getSupplierTransaction = async (supplier_id: number) => {
  if (!_.isNil(supplier_id)) {
    return await fetchWrapper.get(
      BASE_PATH +
        `${API_GET_COMPANY}${getActiveRestaurantId()}/transaction/?type=supplier&id=${supplier_id}`
    );
  }
};

export const searchSupplierList = async (username: string) => {
  return await fetchWrapper.get(
    BASE_PATH +
      `${API_GET_COMPANY}${getActiveRestaurantId()}/supplier/?name=` +
      username
  );
};

export const getCustomerTransactionList = async (id: number) => {
  return await fetchWrapper.get(
    BASE_PATH +
      `${API_GET_COMPANY}${getActiveRestaurantId()}/transaction/?type=user&id=` +
      id
  );
};

export const getCompanyCustomers = async () => {
  return await fetchWrapper.get(
    BASE_PATH + `${API_GET_COMPANY}${getActiveRestaurantId()}/customer/`
  );
};

export const assignCompanyCustomers = async (data: any) => {
  return await fetchWrapper.post(
    BASE_PATH + `${API_GET_COMPANY}${getActiveRestaurantId()}/customer/`,
    data
  );
};

export const getCompanyCustomerDetail = async (id: number) => {
  return await fetchWrapper.get(
    BASE_PATH + `${API_GET_COMPANY}${getActiveRestaurantId()}/customer/${id}/`
  );
};

export const addCreditLimitToCustomer = async (
  customer_id: number,
  data: any
) => {
  return await fetchWrapper.patch(
    BASE_PATH +
      `${API_GET_COMPANY}${getActiveRestaurantId()}/customer/${customer_id}/`,
    data
  );
};

export const addExpenseData = async (data: ExpenseAPIRequest) => {
  return await fetchWrapper.post(
    BASE_PATH + `${API_GET_COMPANY}${getActiveRestaurantId()}/expense/`,
    data
  );
};

export const addExpenseAttachment = async (expenseId: number, file: File) => {
  const formData = new FormData();
  formData.append("file", file);
  return await fetchWrapper.formPost(
    BASE_PATH +
      `${API_GET_COMPANY}${getActiveRestaurantId()}/expense/${expenseId}/attachment/`,
    formData
  );
};

export const getExpenseList = async (
  debouncedSearchValue: string,
  currentPage: number,
  pageSize: number
) => {
  const queryParams = new URLSearchParams();
  if (debouncedSearchValue && debouncedSearchValue.trim() !== "") {
    queryParams.append("search", debouncedSearchValue);
  }
  if (currentPage !== null && currentPage !== undefined) {
    queryParams.append("page", currentPage.toString());
  }
  if (pageSize !== null && pageSize !== undefined) {
    queryParams.append("page_size", pageSize.toString());
  }
  const url = `${BASE_PATH}api/company/${getActiveRestaurantId()}/expense/?${queryParams.toString()}`;
  return await fetchWrapper.get(url);
};

export const getTopProductSales = async (cid: number) => {
  const url = `${BASE_PATH}api/company/${cid}/product-data/`;
  return await fetchWrapper.get(url);
};

export const getIncomeExpenseReport = async (cid: number) => {
  const url = `${BASE_PATH}api/company/${cid}/income-expense-report/`;
  return await fetchWrapper.get(url);
};

export const getProductSalesData = async (
  cid: number,
  startDate: string,
  endDate: string,
  viewBy: string,
  products: any
) => {
  const valuesArr = products.map((node: any) => node.id).toString();
  const url = `${BASE_PATH}api/company/${cid}/product-graph-data/?from=${startDate}T00:00:00Z&to=${endDate}T23:59:59Z&report_of=${viewBy}&products=${valuesArr}`;
  return await fetchWrapper.get(url);
};

export const getSalesData = async (
  cid: number,
  startDate: string,
  endDate: string,
  viewBy: string
) => {
  return await fetchWrapper.get(
    `${BASE_PATH}api/company/${cid}/income-expense-data/?from=${startDate}T00:00:00Z&to=${endDate}T23:59:59Z&report_of=${viewBy}`
  );
};

export const createCustomer = async (cid: number, data: any) => {
  data.company_id = cid;
  return await fetchWrapper.post(`${BASE_PATH}api/account/`, data);
};

export const createSettlement = async (cid: number, data: any) => {
  return await fetchWrapper.post(
    `${BASE_PATH}api/company/${cid}/settlement/`,
    data
  );
};

export const getSettlementList = async (
  cid: number,
  fromDate: Date | null,
  toDate: Date | null,
  currentPage: number,
  pageSize: number
) => {
  let query = "";
  if (
    fromDate !== null &&
    fromDate !== undefined &&
    toDate !== null &&
    toDate !== undefined
  ) {
    query = `?from=${fromDate.toISOString().slice(0, 10)}&to=${toDate
      .toISOString()
      .slice(0, 10)}`;
  } else if (fromDate !== null && fromDate !== undefined) {
    query = `?from=${fromDate.toISOString().slice(0, 10)}&to=${fromDate
      .toISOString()
      .slice(0, 10)}`;
  }
  return await fetchWrapper.get(
    `${BASE_PATH}api/company/${cid}/settlement/?page=${currentPage}&page_size=${pageSize}&${query}`
  );
};

export const changePassword = async (data: any) => {
  return await fetchWrapper.post(
    `${BASE_PATH}api/account/password/change/`,
    data
  );
};

export default AdminService;
