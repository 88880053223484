import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import {
  getSupplierDetail,
  getSupplierTransaction,
} from "../../services/AdminService";
import { Link, useParams } from "react-router-dom";
import { accountTranscations } from "../../class/Banking";
import { SupplierType } from "../../class/SupplierTypes";
import LinkComponent from "../../components/Link/LinkComponent";
import { PageRoutes } from "../../common/Constants";

const SupplierDetails = () => {
  const param = useParams();
  const id = param.id;
  const [supplierDetail, setSupplierDetail] = useState<SupplierType>();
  const [accountTransactions, setAccountTransactions] = useState<
    accountTranscations[]
  >([]);
  useEffect(() => {
    getSupplierDetail(Number(id))
      .then((response: SupplierType) => {
        setSupplierDetail(response);
      })
      .catch(() => {});
    getSupplierTransaction(Number(id))
      .then((response: any) => {
        setAccountTransactions(response.results);
      })
      .catch(() => {});
  }, [id]);
  return (
    <div className=" container-fluid pt-4 px-4">
      <div className="row ">
        <div className=" mb-3">
          <h3>{supplierDetail?.name}</h3>
          <div className="d-flex justify-content-between">
            <h6>
              Balance :{" "}
              {Number(supplierDetail?.total_debit) -
                Number(supplierDetail?.total_credit)}
            </h6>
            <Link to={PageRoutes.NEW_SETTLEMENT}>
              <Button variant="secondary">Settlement</Button>
            </Link>
          </div>
          <hr />
          <h4 className="my-1 h4">Account Transactions</h4>
          <Table borderless responsive>
            <thead>
              <tr>
                <th>Ref Id</th>
                <th className="w-25">Description</th>
                <th className="w-25">Transactions Type</th>
                <th>Debit</th>
                <th>Credit</th>
              </tr>
            </thead>
            <tbody>
              {accountTransactions?.map(
                ({
                  id,
                  debit,
                  credit,
                  ref_type,
                  ref_id,
                  description,
                }: accountTranscations) => (
                  <tr key={id}>
                    <td>
                      <LinkComponent data={{ type: ref_type, name: ref_id }} />
                    </td>
                    <td>{description}</td>
                    <td>{ref_type}</td>
                    <td>{debit}</td>
                    <td>{credit}</td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default SupplierDetails;
