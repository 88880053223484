import {
  faBars,
  faDashboard,
  faNoteSticky,
  faUser,
  faUtensils,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Nav, NavItem } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { PageRoutes } from "../../common/Constants";

const Footer = ({
  showSideBar,
  setShowSideBar,
}: {
  showSideBar: any;
  setShowSideBar: any;
}) => {
  const tabs = [
    {
      route: PageRoutes.DASHBOARD,
      icon: faDashboard,
      label: "Dashboard",
    },
    {
      route: PageRoutes.NEW_ORDER,
      icon: faUtensils,
      label: "Orders",
    },
    {
      route: PageRoutes.KOT,
      icon: faNoteSticky,
      label: "KOT",
    },
    {
      route: "/profile",
      icon: faUser,
      label: "Profile",
    },
    {
      route: "",
      icon: faBars,
      label: "Others",
    },
  ];
  return (
    <>
      <nav
        className="navbar fixed-bottom bg-light bottom-tab-nav d-block d-md-none"
        role="navigation"
      >
        <Nav className="w-100" variant="primary">
          <div className=" d-flex flex-row justify-content-around w-100">
            {tabs.map((tab, index) => (
              <NavItem key={`tab-${index}`}>
                {tab.route !== "" ? (
                  <NavLink to={tab.route} className="nav-link bottom-nav-link">
                    <div className="row d-flex flex-column justify-content-center align-items-center">
                      <FontAwesomeIcon size="lg" icon={tab.icon} />
                      <div className="bottom-tab-label">{tab.label}</div>
                    </div>
                  </NavLink>
                ) : (
                  <div
                    className="nav-link bottom-nav-link"
                    onClick={() => {
                      setShowSideBar(!showSideBar);
                    }}
                  >
                    <div className="row d-flex flex-column justify-content-center align-items-center">
                      <FontAwesomeIcon size="lg" icon={tab.icon} />
                      <div className="bottom-tab-label">{tab.label}</div>
                    </div>
                  </div>
                )}
              </NavItem>
            ))}
          </div>
        </Nav>
      </nav>
    </>
  );
};

export default Footer;
