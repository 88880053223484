/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteLabel, getLabelsLists } from "../../services/AdminService";
import ButtonComponent, {
  Icons,
} from "../../components/Buttons/ButtonComponent";
import { useCommon } from "../../components/Context/CommonContext";
import _ from "lodash";
import { Table } from "react-bootstrap";
import HeadingTitle from "../../components/HeadingTitle";
import NoResultFound from "../../components/NoResultFound";
import ImageViewers from "../../components/ImageViewer/ImageViewers";

const AllProducts = () => {
  const [labelsList, setLabelsList] = useState<any>([]);
  const navigate = useNavigate();
  const { dispatch } = useCommon();

  const handleDelete = (e: any, labelId: number, name: string) => {
    e.preventDefault();
    // eslint-disable-next-line no-restricted-globals
    let confirmValue: boolean = confirm(`Delete ${name}?`);
    if (confirmValue === true) {
      deleteLabel(labelId)
        .then((response) => {
          const value: any = Object.create(labelsList);
          const filteredValue: any = value.filter(
            (el: any) => el.id !== labelId
          );
          setLabelsList(filteredValue);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  let sn = 0;
  useEffect(() => {
    // dispatch({ type: "loading", payload: true });
    getLabelsLists(null)
      .then((response: any) => {
        // dispatch({ type: "loading", payload: false });
        setLabelsList(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  if (_.isNil(labelsList)) {
    dispatch({ type: "loading", payload: false });
  }
  return (
    <>
      <div className=" container-fluid pt-4 px-4">
        <div>
          <div className="d-flex justify-content-between mb-4">
            <HeadingTitle title="Labels" />
            <ButtonComponent
              tooltip={"Add Label"}
              variant={"primary"}
              type={Icons.Add}
              isButton={true}
              onSubmitClick={() => navigate("/label/new")}
            />
          </div>

          <div className="table-responsive">
            {labelsList.length <= 0 ? (
              <NoResultFound message="No results found" />
            ) : (
              <Table borderless responsive>
                <thead>
                  <tr className="text-dark">
                    <th scope="col">#</th>
                    <th scope="col">Image</th>
                    <th scope="col">Name</th>
                    <th scope="col" className="d-none d-sm-block">
                      Position
                    </th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {labelsList.length > 0 &&
                    labelsList.map((node: any, index: number) => (
                      <tr key={node.id}>
                        <td>{++sn}</td>
                        <td
                          style={{
                            overflowX: "hidden",
                          }}
                        >
                          <ImageViewers
                            images={[{ image_url: `${node.icon}` }]}
                            placeholder={true}
                          />
                        </td>
                        <td>
                          <a className="text-black">{node.name}</a>
                        </td>
                        <td className="d-none d-sm-block">{node.position}</td>
                        <td className="w-25">
                          <ButtonComponent
                            tooltip={"Edit"}
                            variant={"primary"}
                            type={Icons.Edit}
                            isButton={false}
                            onSubmitClick={() =>
                              navigate(`/label/${node.id}/edit`)
                            }
                          />

                          <ButtonComponent
                            tooltip={"Delete"}
                            variant={"primary"}
                            type={Icons.Delete}
                            isButton={false}
                            onSubmitClick={(e: any) => {
                              handleDelete(e, node.id, node.name);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AllProducts;
