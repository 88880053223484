import React, { useEffect, useState } from "react";
import { Card, ListGroup, Stack } from "react-bootstrap";
import {
  getAdminUserData,
  getCompanyRoles,
  isSuperUser,
  setAdminUserData,
} from "../../common/Utils";
import { getUserData } from "../../services/AdminService";
import { useCommon } from "../../components/Context/CommonContext";
import { MessageStrings } from "../../common/Constants";
import CompanySwitchV2 from "../../components/CompanySwitchV2";

const Profile = () => {
  const [companyRoles] = useState(getCompanyRoles());
  const [userData, setuserData] = useState({ username: "" });
  const localUserData = getAdminUserData();
  const { dispatch } = useCommon();

  useEffect(() => {
    if (localUserData != null) {
      setuserData(JSON.parse(localUserData));
    } else {
      getUserData()
        .then((response) => {
          setuserData(response);
          setAdminUserData(response);
        })
        .catch((error) => {
          dispatch({
            type: "messageModal",
            payload: {
              visible: true,
              title: MessageStrings.ERROR_MESSAGE_TITLE,
              message: MessageStrings.ERROR_MESSAGE_GENERIC,
            },
          });
        });
    }
    return () => {};
  }, [localUserData, dispatch, companyRoles]);

  return (
    <div className="container-fluid pt-4 px-4">
      <div className="row p-4">
        <Card className="bg-white col-12 col-md-4 rounded-4">
          <ProfileCard user={userData} />
        </Card>
        {isSuperUser() ? (
          <Card className="d-block d-md-none bg-white col-12 col-md-4 rounded-4 mt-4">
            <CompanySwitchV2 />
          </Card>
        ) : (
          <div className="bg-white col-12 col-md-4 rounded-4">
            <UserCompanyList data={companyRoles} />
          </div>
        )}
      </div>
    </div>
  );
};

const ProfileCard = (props: { user: any }) => {
  return (
    <div className="p-2">
      <div className="d-flex justify-content-center">
        <img
          src={props.user?.image_url || "img/profile.png"}
          alt="profile"
          width={150}
          height={150}
          className="rounded-2"
        />
      </div>
      <div className="mt-3">
        <table className="w-100">
          <tr>
            <td>
              <small className="fw-bold">Username</small>
            </td>
            <td align="right">
              <small>{props.user?.username}</small>
            </td>
          </tr>

          <tr>
            <td>
              <small className="fw-bold">First Name</small>
            </td>
            <td align="right">
              <small>{props.user?.first_name || "-"}</small>
            </td>
          </tr>

          <tr>
            <td>
              <small className="fw-bold">Last Name</small>
            </td>
            <td align="right">
              <small>{props.user?.last_name || "-"}</small>
            </td>
          </tr>

          <tr>
            <td>
              <small className="fw-bold">Phone No</small>
            </td>
            <td align="right">
              <small>{props.user?.phone || "-"}</small>
            </td>
          </tr>

          <tr>
            <td>
              <small className="fw-bold">Email</small>
            </td>
            <td align="right">
              <small>{props.user?.email || "-"}</small>
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
};

const UserCompanyList = (props: { data: any }) => {
  return (
    <div>
      <div className="pt-1 mx-3 ">
        <h6>Your Organizations</h6>
      </div>
      <ListGroup>
        {props.data?.map((item: any) => (
          <ListGroup.Item key={item.company?.id}>
            <Stack className="d-flex p-1" direction="horizontal">
              <img
                src={item.company?.logo_url || "img/profile.png"}
                alt="profile"
                width={50}
                height={50}
                className="rounded-circle"
              />
              <p className="ms-2 mb-0">{item.company?.name}</p>
            </Stack>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
};

export default Profile;
