import React, { useCallback, useEffect, useState } from "react";
import { getSettlementList } from "../../services/AdminService";
import { Row, Col, InputGroup, Table, Form, Badge } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import {
  capitalizeFirstLetter,
  getActiveRestaurantId,
  getLocalDateTime,
} from "../../common/Utils";
import { useCommon } from "../../components/Context/CommonContext";
import HeadingTitle from "../../components/HeadingTitle";
import NoResultFound from "../../components/NoResultFound";
import DatePicker from "react-datepicker";
import ButtonComponent, {
  Icons,
} from "../../components/Buttons/ButtonComponent";
import { useNavigate } from "react-router-dom";
import { PageRoutes } from "../../common/Constants";

export type SettlementRequest = {
  id: number;
  payee_type: any;
  payee_id: any;
  paid_from_type: any;
  paid_from_id: any;
  payment_mode: any;
  total: number;
  ref_no: string | null;
  created_at: string;
  description: string | null;
};

type DateQuery = {
  startDate: Date | null;
  endDate: Date | null;
};

const Settlement = () => {
  const pageSizeArray = [50, 100, 200, 500];
  const navigate = useNavigate();

  const [dateQuery, setDateQuery] = useState<DateQuery>({
    startDate: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
    endDate: new Date(),
  });
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setcurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(pageSizeArray[0]);

  const [settlementList, setSettlementList] = useState<SettlementRequest[]>([]);
  const { dispatch } = useCommon();
  const onChange = (dates: [any, any]) => {
    const [start, end] = dates;
    setDateQuery({ startDate: start, endDate: end });
  };

  const getSettlementData = useCallback(
    (dateQuery: DateQuery) => {
      dispatch({ type: "loading", payload: true });
      getSettlementList(
        getActiveRestaurantId(),
        dateQuery.startDate,
        dateQuery.endDate,
        currentPage,
        pageSize
      )
        .then((response: any) => {
          dispatch({ type: "loading", payload: false });
          setSettlementList(response?.results);
          setPageCount(Math.ceil(response.count / pageSize));
        })
        .catch(() => {});
    },
    [currentPage, dispatch, pageSize]
  );

  useEffect(() => {
    getSettlementData(dateQuery);
  }, [dateQuery, getSettlementData]);

  const handlePageClick = (event: any) => {
    const pageNo = event.selected + 1;
    setcurrentPage(pageNo);
  };

  return (
    <div className="container-fluid pt-4 px-4">
      <div className="d-flex align-items-center justify-content-between">
        <Col>
          <HeadingTitle title="Settlement History" />
        </Col>
        <Col className="text-end">
          <ButtonComponent
            tooltip={"Perform Settlement"}
            variant={"primary"}
            onSubmitClick={() => {
              navigate(PageRoutes.NEW_SETTLEMENT);
            }}
            type={Icons.Add}
            isButton={true}
          />
        </Col>
      </div>
      <Row className="d-flex align-items-center">
        <Col sm={6} lg={3} xl={3}>
          <DatePicker
            showIcon={true}
            selected={dateQuery.startDate}
            onChange={onChange}
            startDate={dateQuery.startDate}
            endDate={dateQuery.endDate}
            selectsRange
            placeholderText="Select date or range"
            dateFormat="MMM d, yyyy"
            className="form-control"
          />
        </Col>
        <Col sm={6} lg={2} xl={2}>
          <InputGroup>
            <InputGroup.Text id="inputGroup-sizing-default">
              Page Size
            </InputGroup.Text>
            <Form.Select
              aria-label="Default select example"
              aria-describedby="inputGroup-sizing-default"
              onChange={(event) => {
                setPageSize(parseInt(event.target.value));
              }}
            >
              {pageSizeArray.map((item: number) => {
                return (
                  <option value={item} key={item} selected={pageSize === item}>
                    {item}
                  </option>
                );
              })}
            </Form.Select>
          </InputGroup>
        </Col>
      </Row>
      {settlementList.length === 0 ? (
        <NoResultFound message="No results found" />
      ) : (
        <Table
          borderless
          responsive
          className="mt-2"
          style={{ minHeight: "200px" }}
        >
          <thead>
            <tr>
              <th>Date</th>
              <th>Paid By</th>
              <th>Paid To</th>
              <th>Payment Mode</th>
              <th>Amount</th>
              <th>Ref No</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {settlementList.map((node: SettlementRequest) => (
              <tr key={node.id}>
                <td>{getLocalDateTime(node.created_at)}</td>
                <td>
                  {node.paid_from_type === "user" &&
                    capitalizeFirstLetter(node.paid_from_id.username)}
                  {node.paid_from_type !== "user" &&
                    capitalizeFirstLetter(node.paid_from_id.name)}
                  <br />
                  <Badge bg={"secondary"}>
                    {capitalizeFirstLetter(node.paid_from_type)}
                  </Badge>
                </td>
                <td>
                  {node.payee_type === "user" &&
                    capitalizeFirstLetter(node.payee_id.username)}
                  {node.payee_type !== "user" &&
                    capitalizeFirstLetter(node.payee_id.name)}
                  <br />
                  <Badge bg={"info"}>
                    {capitalizeFirstLetter(node.payee_type)}
                  </Badge>
                </td>
                <td>{capitalizeFirstLetter(node.payment_mode.name)}</td>
                <td>{node.total}</td>
                <td>{node.ref_no ? node.ref_no : "-"}</td>
                <td>{node.description ? node.description : "-"}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {settlementList.length > 0 && (
        <Row className="mt-4">
          <Col className="text-center">
            <ReactPaginate
              breakLabel="..."
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="Previous"
              nextLabel="Next"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakClassName="page-item"
              breakLinkClassName="page-link"
              marginPagesDisplayed={2}
              containerClassName="pagination d-inline-flex"
              activeClassName="active"
              initialPage={currentPage - 1}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Settlement;
