import React from "react";
import { Form } from "react-bootstrap";

const Checkbox = ({
  label,
  isPriceChanged,
  setIsPriceChanged,
}: {
  label: string;
  isPriceChanged: boolean;
  setIsPriceChanged: any;
}) => {
  return (
    <>
      <Form.Group className="mx-2" controlId="priceCheckbox">
        <Form.Check
          type="checkbox"
          label={label}
          onClick={() => setIsPriceChanged(!isPriceChanged)}
        />
      </Form.Group>
    </>
  );
};

export default Checkbox;
