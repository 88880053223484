import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { User } from "../../class/User";
import { useCommon } from "../../components/Context/CommonContext";
import _ from "lodash";
import UserSearchBox from "../SearchBox/UserSearchBox";
const UserSearch = (props: any) => {
  const { dispatch } = useCommon();
  const [selectedUser, setSelectedUser] = useState<User[]>([]);
  const [noOfGuest, setNoOfGuest] = useState<string>(
    props.initialNumberOfGuest
  );
  useEffect(() => {
    if (!_.isNil(props?.activeCustomer)) {
      setSelectedUser([props?.activeCustomer]);
    }
    if (!_.isNil(props.initialNumberOfGuest)) {
      setNoOfGuest(props.initialNumberOfGuest);
    }
  }, [props.activeCustomer, props.initialNumberOfGuest]);

  const handleAdd = (event: any) => {
    event.preventDefault();
    const isGuestNoChanged = props.initialNumberOfGuest !== noOfGuest;
    const selectedUserExists = selectedUser.length > 0;
    const isCustomerChanged =
      selectedUserExists &&
      selectedUser[0]?.username !== (props.activeCustomer?.username || null);

    // Case 1: Both number of guests and customer are changed
    if (isGuestNoChanged && isCustomerChanged) {
      props.handleOrderDataPatch(selectedUser[0]?.id, noOfGuest);
    }
    // Case 2: Only customer is changed
    else if (isCustomerChanged) {
      props.handleOrderDataPatch(selectedUser[0]?.id, noOfGuest);
    }
    // Case 3: Only the number of guests changes
    else if (isGuestNoChanged) {
      props.handleOrderDataPatch(selectedUser[0]?.id, noOfGuest);
    }
    // Case 4: Both selectedUser and noOfGuest are empty
    else if (!selectedUserExists) {
      dispatch({
        type: "toast",
        payload: {
          visible: true,
          message: "Fields can't be empty",
        },
      });
    }
  };

  const handleRemove = () => {
    props.handleOrderDataPatch(null, noOfGuest);
  };

  return (
    <>
      <form onSubmit={handleAdd}>
        <Row className="p-2">
          <Col xs={12} sm={6}>
            <Form.Group>
              <Form.Label>Username</Form.Label>
              <UserSearchBox
                label={"username"}
                name={"username"}
                onChange={(e: any) => {
                  if (e && e.length > 0) setSelectedUser(e);
                }}
                selectedUser={selectedUser}
                placeholder={"Search user"}
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={6}>
            <Form.Group>
              <Form.Label>No. of Guests</Form.Label>
              <Form.Control
                type="number"
                placeholder="Number of guest"
                min={0}
                max={100}
                value={noOfGuest}
                onChange={(e) => {
                  setNoOfGuest(e.target.value);
                }}
                onWheel={(e) => e.currentTarget.blur()}
                onClick={(e) => e.currentTarget.select()}
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={12} className="mt-3">
            <button type="submit" className="btn btn-primary">
              Confirm
            </button>

            {props.activeCustomer && (
              <button className="btn btn-info ms-2" onClick={handleRemove}>
                Remove User
              </button>
            )}
          </Col>
        </Row>
      </form>
    </>
  );
};

export default UserSearch;
