import { ErrorMessage } from "@hookform/error-message";
import React, { useEffect, useMemo } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useCommon } from "../../components/Context/CommonContext";
import {
  addBankAccount,
  getBankAccount,
  patchBankAccount,
} from "../../services/AdminService";
import { PageRoutes } from "../../common/Constants";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
type bankType = {
  name: string;
  abbreviation: string;
};
const AddBanking = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<bankType>({ criteriaMode: "all" });
  const { dispatch } = useCommon();
  const navigate = useNavigate();
  const { bid } = useParams();
  const handleInsert: SubmitHandler<bankType> = (event: any) => {
    const data = new FormData();
    data.append("name", event.name);
    data.append("abbreviation", event.abbreviation);
    dispatch({ type: "loading", payload: true });
    if (!_.isNil(bid) && isEdit) {
      patchBankAccount(bid, data)
        .then((response: any) => {
          if (response) {
            dispatch({ type: "loading", payload: false });
            dispatch({
              type: "toast",
              payload: {
                visible: true,
                message: `Updated Successfully.`,
                success: true,
              },
            });
            navigate(PageRoutes.BANKING);
          }
        })
        .catch(() => {
          dispatch({ type: "loading", payload: false });
          dispatch({
            type: "toast",
            payload: {
              visible: true,
              message: "Error",
              success: false,
            },
          });
        });
    } else {
      addBankAccount(data)
        .then((response: any) => {
          if (response) {
            dispatch({ type: "loading", payload: false });
            dispatch({
              type: "toast",
              payload: {
                visible: true,
                message: `Added Successfully.`,
                success: true,
              },
            });
            navigate(PageRoutes.BANKING);
          }
        })
        .catch(() => {
          dispatch({ type: "loading", payload: false });
          dispatch({
            type: "toast",
            payload: {
              visible: true,
              message: "Error",
              success: false,
            },
          });
        });
    }
  };

  const isEdit = useMemo(() => Boolean(bid), [bid]);

  useEffect(() => {
    if (!_.isNil(bid))
      getBankAccount(bid)
        .then((response: any) => {
          setValue("name", response?.name);
          setValue("abbreviation", response?.abbreviation);
        })
        .catch(() => {});
  }, [bid, isEdit, setValue]);

  const formTitle = isEdit ? "Update Banking" : "Add Banking";
  const buttonText = isEdit ? "Update" : "Add";
  return (
    <div className="container-fluid pt-4 px-4">
      <div className="row col-sm-12 ">
        <div className="col-sm-6 col-xl-6">
          <div className="bg-light rounded h-100 p-4">
            <h6>{formTitle}</h6>
            <form onSubmit={handleSubmit(handleInsert)}>
              <div className="row">
                <div className="col-sm-12">
                  <div className="mb-2">
                    <label htmlFor="Name" className="form-label">
                      <small>
                        Name <span className="text-primary">*</span>
                      </small>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      autoComplete="off"
                      {...register("name", { required: "Required" })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="name"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <p key={type} className="text-danger">
                            * {message}
                          </p>
                        ))
                      }
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="Abbreviation" className="form-label">
                      <small>
                        Abbreviation <span className="text-primary">*</span>
                      </small>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      autoComplete="off"
                      {...register("abbreviation", { required: "Required" })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="abbreviation"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <p key={type} className="text-danger">
                            * {message}
                          </p>
                        ))
                      }
                    />
                  </div>

                  <div className="row d-flex align-items-center">
                    <div className="col-sm-5">
                      <button type="submit" className="btn btn-primary mt-2">
                        {buttonText}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBanking;
