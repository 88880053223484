import React, { useEffect, useMemo, useRef, useState } from "react";
import { Breadcrumb, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { parseErrorMessage } from "../../common/Utils";
import { useCommon } from "../../components/Context/CommonContext";
import { PageRoutes } from "../../common/Constants";
import { getMeasuringUnits } from "../../services/measuringUnitApi";
import { Inventory } from "../../class/Inventory";
import { SubmitHandler, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import {
  addInventory,
  editInventory,
  getInventoryDetail,
} from "../../services/inventoryApi";
import { MeasuringUnitType } from "../../class/MeasuringUnitType";

const AddInventory = () => {
  const navigate = useNavigate();
  const { dispatch } = useCommon();
  const [measuringUnits, setMeasuringUnits] = useState<MeasuringUnitType[]>([]);
  const [selectedUnitId, setSelectedUnitId] = useState<number>();
  const form = useRef(null);
  const { id } = useParams();
  const isEdit = useMemo(() => Boolean(id), [id]);
  useEffect(() => {
    getMeasuringUnits()
      .then((response) => {
        if (response) {
          setMeasuringUnits(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    setValue,
    reset,
  } = useForm<Inventory>({
    defaultValues: {
      name: "",
      description: "",
      measuring_unit: null,
      min_stock_threshold: 0,
    },
    criteriaMode: "all",
  });

  const handleInsert: SubmitHandler<Inventory> = (data: any) => {
    dispatch({ type: "loading", payload: true });
    if (isEdit && id) {
      editInventory(id, data)
        .then((response: any) => {
          dispatch({ type: "loading", payload: false });
          if (response.id) {
            dispatch({
              type: "toast",
              payload: {
                visible: true,
                message: "Edit Successful",
              },
            });
            navigate(PageRoutes.INVENTORY);
          }
        })
        .catch((error: any) => {
          dispatch({
            type: "toast",
            payload: {
              visible: true,
              message: parseErrorMessage(error),
            },
          });
        })
        .finally(() => {
          dispatch({ type: "loading", payload: false });
        });
    } else {
      addInventory(data)
        .then((response: any) => {
          dispatch({ type: "loading", payload: false });
          if (response.id) {
            dispatch({
              type: "toast",
              payload: {
                visible: true,
                message: "Added Successfully",
              },
            });
            navigate(PageRoutes.INVENTORY);
          }
        })
        .catch((error: any) => {
          dispatch({
            type: "toast",
            payload: {
              visible: true,
              message: parseErrorMessage(error),
            },
          });
        })
        .finally(() => {
          dispatch({ type: "loading", payload: false });
        });
    }
  };

  useEffect(() => {
    reset({
      name: "",
      description: "",
      measuring_unit: null,
      min_stock_threshold: 0,
    });
  }, [isSubmitSuccessful, reset]);

  useEffect(() => {
    if (isEdit && id) {
      dispatch({ type: "loading", payload: true });
      getInventoryDetail(id)
        .then((response: any) => {
          dispatch({ type: "loading", payload: false });
          if (response) {
            setValue("name", response.name);
            setValue("description", response.description);
            setValue("measuring_unit", response.measuring_unit);
            setSelectedUnitId(response.measuring_unit.id);
            setValue("min_stock_threshold", response.min_stock_threshold);
          }
        })
        .catch(() => {});
    }
  }, [dispatch, id, isEdit, setValue]);
  return (
    <div className="container-fluid pt-4 px-4">
      <Breadcrumb>
        <Breadcrumb.Item href={PageRoutes.INVENTORY}>Inventory</Breadcrumb.Item>
        <Breadcrumb.Item active>{isEdit ? "Edit" : "Add"}</Breadcrumb.Item>
      </Breadcrumb>

      <div className="row col-sm-12 ">
        <div className="col-sm-6 col-xl-6">
          <div className="bg-light  rounded h-100 p-4">
            <h6 className="mb-2">{isEdit ? "Edit" : "Add"} Inventory</h6>

            <form ref={form} onSubmit={handleSubmit(handleInsert)}>
              <div className="row">
                <div className="col-sm-12 ">
                  <div className="mb-3">
                    <Form.Group>
                      <Form.Label>
                        Name <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Inventory name"
                        {...register("name", {
                          required: " * Required",
                          maxLength: {
                            value: 50,
                            message: "Max length is 50",
                          },
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="name"
                        render={({ messages }) =>
                          messages &&
                          Object.entries(messages).map(([type, message]) => (
                            <p key={type} className="text-danger">
                              {message}
                            </p>
                          ))
                        }
                      />
                    </Form.Group>
                  </div>
                  <div className="mb-3">
                    <Form.Group>
                      <Form.Label>
                        Description <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Description"
                        {...register("description", {
                          required: " * Required",
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="description"
                        render={({ messages }) =>
                          messages &&
                          Object.entries(messages).map(([type, message]) => (
                            <p key={type} className="text-danger">
                              {message}
                            </p>
                          ))
                        }
                      />
                    </Form.Group>
                  </div>
                  <div className="mb-3">
                    <Form.Group>
                      <Form.Label>
                        Measuring Unit <span className="text-danger">* </span>
                      </Form.Label>
                      <select
                        className="form-select"
                        {...register("measuring_unit", {
                          required: "* Required",
                        })}
                      >
                        <option disabled>Select</option>
                        {measuringUnits.map((node: any) => (
                          <option
                            key={node.id}
                            value={node.id}
                            selected={node.id === selectedUnitId}
                          >
                            {node.name}
                          </option>
                        ))}
                      </select>
                      <ErrorMessage
                        errors={errors}
                        name="measuring_unit"
                        render={({ messages }) =>
                          messages &&
                          Object.entries(messages).map(([type, message]) => (
                            <p key={type} className="text-danger">
                              {message}
                            </p>
                          ))
                        }
                      />
                    </Form.Group>
                  </div>
                  <div className="mb-3">
                    <Form.Group>
                      <Form.Label>
                        Minimum Threshold <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        onWheel={(e) => e.currentTarget.blur()}
                        placeholder="Threshold unit"
                        {...register("min_stock_threshold", {
                          required: "* Required",
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="min_stock_threshold"
                        render={({ messages }) =>
                          messages &&
                          Object.entries(messages).map(([type, message]) => (
                            <p key={type} className="text-danger">
                              {message}
                            </p>
                          ))
                        }
                      />
                    </Form.Group>
                  </div>

                  <div className="mb-3">
                    <button type="submit" className="btn btn-primary">
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddInventory;
