import { ErrorMessage } from "@hookform/error-message";
import React, { useEffect, useMemo } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useCommon } from "../../components/Context/CommonContext";
import {
  getSupplierDetail,
  patchSupplier,
  postSupplier,
} from "../../services/AdminService";
import { PageRoutes } from "../../common/Constants";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { SupplierType } from "../../class/SupplierTypes";

const AddSupplier = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<SupplierType>({ criteriaMode: "all" });
  const { dispatch } = useCommon();
  const navigate = useNavigate();

  const { id } = useParams();
  console.log(id);
  const handleInsert: SubmitHandler<SupplierType> = (event: any) => {
    if (!_.isNil(id) && isEdit) {
      patchSupplier(id, event)
        .then((response: any) => {
          if (response) {
            dispatch({ type: "loading", payload: false });
            dispatch({
              type: "toast",
              payload: {
                visible: true,
                message: `Updated Successfully.`,
                success: true,
              },
            });
            navigate(PageRoutes.SUPPLIER);
          }
        })
        .catch(() => {
          dispatch({ type: "loading", payload: false });
          dispatch({
            type: "toast",
            payload: {
              visible: true,
              message: "Error",
              success: false,
            },
          });
        });
    } else {
      postSupplier(event)
        .then((response: any) => {
          if (response) {
            dispatch({ type: "loading", payload: false });
            dispatch({
              type: "toast",
              payload: {
                visible: true,
                message: `Added Successfully.`,
                success: true,
              },
            });
            navigate(PageRoutes.SUPPLIER);
          }
        })
        .catch(() => {
          dispatch({ type: "loading", payload: false });
          dispatch({
            type: "toast",
            payload: {
              visible: true,
              message: "Error",
              success: false,
            },
          });
        });
    }
  };

  const isEdit = useMemo(() => Boolean(id), [id]);

  useEffect(() => {
    if (!_.isNil(id))
      getSupplierDetail(Number(id))
        .then((response: any) => {
          setValue("name", response?.name);
        })
        .catch(() => {});
  }, [id, isEdit, setValue]);

  const formTitle = isEdit ? "Update Supplier" : "Add Supplier";
  const buttonText = isEdit ? "Update" : "Add";
  return (
    <div className="container-fluid pt-4 px-4">
      <div className="row col-sm-12 ">
        <div className="col-sm-6 col-xl-6">
          <div className="bg-light rounded h-100 p-4">
            <h6>{formTitle}</h6>
            <form onSubmit={handleSubmit(handleInsert)}>
              <div className="row">
                <div className="col-sm-12">
                  <div className="mb-2">
                    <label htmlFor="Name" className="form-label">
                      <small>Name</small>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      autoComplete="off"
                      {...register("name", { required: "Required" })}
                      placeholder="Enter supplier name"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="name"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <p key={type} className="text-danger">
                            * {message}
                          </p>
                        ))
                      }
                    />
                  </div>

                  <div className="row d-flex align-items-center">
                    <div className="col-sm-5">
                      <button type="submit" className="btn btn-primary mt-2">
                        {buttonText}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSupplier;
