import { BASE_PATH } from "../common/Constants";
import fetchWrapper from "../common/FetchWrapper";
import { getActiveRestaurantId } from "../common/Utils";

export const getMeasuringUnits= async (searchValue?:string) => {
  const queryParams = new URLSearchParams();
  if (searchValue && searchValue.trim() !== '') {
    queryParams.append('search', searchValue);
  }
  return await fetchWrapper.get(
    `${BASE_PATH}api/company/${getActiveRestaurantId()}/unit/?${queryParams.toString()}`
  );
};