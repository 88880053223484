import { ErrorMessage } from "@hookform/error-message";
import React from "react";
import { Form, Modal } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { useCommon } from "../../components/Context/CommonContext";
import { createCustomer } from "../../services/AdminService";
import { getActiveRestaurantId, parseErrorMessage } from "../../common/Utils";

type User = {
  first_name: string;
  last_name: string;
  phone: number;
  username: string;
  password: string;
  email: string;
};

const CustomerCreateModal = ({
  onCreateSuccess,
  showCreateCustomerDialog,
  setShowCreateCustomerDialog,
}: {
  onCreateSuccess: any;
  showCreateCustomerDialog: boolean;
  setShowCreateCustomerDialog?: any;
}) => {
  const handleCloseCreateCustomerDialog = () =>
    setShowCreateCustomerDialog(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<User>({
    defaultValues: {
      first_name: "",
      last_name: "",
      phone: 0,
    },
    criteriaMode: "all",
  });

  const { dispatch } = useCommon();

  const handleInsert: SubmitHandler<User> = (event: User) => {
    event.username = `${event.first_name}${event.last_name}${Date.now()}`;
    event.password = `${event.first_name}@${Date.now()}`;
    event.email = `${event.first_name}${
      event.last_name
    }${Date.now()}@gmail.com`;

    dispatch({ type: "loading", payload: true });
    createCustomer(getActiveRestaurantId(), event)
      .then((response: any) => {
        onCreateSuccess();
        dispatch({ type: "loading", payload: false });
        dispatch({
          type: "messageModal",
          payload: {
            visible: true,
            title: "Success",
            message: "Customer created successfully.",
          },
        });
        handleCloseCreateCustomerDialog();
      })
      .catch((error: any) => {
        dispatch({ type: "loading", payload: false });
        dispatch({
          type: "toast",
          payload: {
            visible: true,
            message: parseErrorMessage(error),
            success: false,
          },
        });
      });
  };

  return (
    <Modal
      show={showCreateCustomerDialog}
      onHide={handleCloseCreateCustomerDialog}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Create Customer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(handleInsert)}>
          <div className="row">
            <div className="col-sm-12 ">
              <div className="mb-3">
                <Form.Group>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="First Name"
                    {...register("first_name", {
                      required: "This field is required",
                      maxLength: {
                        value: 50,
                        message: "This input exceed maxLength 50",
                      },
                      pattern: /^[a-zA-Z]+$/,
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="first_name"
                    render={({ messages }) =>
                      messages &&
                      Object.entries(messages).map(([type, message]) => (
                        <p key={type} className="text-danger">
                          {message}
                        </p>
                      ))
                    }
                  />
                </Form.Group>
              </div>

              <div className="mb-3">
                <Form.Group>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Last Name"
                    {...register("last_name", {
                      required: "This field is required",
                      maxLength: {
                        value: 50,
                        message: "This input exceed maxLength 50",
                      },
                      pattern: /^[a-zA-Z]+$/,
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="last_name"
                    render={({ messages }) =>
                      messages &&
                      Object.entries(messages).map(([type, message]) => (
                        <p key={type} className="text-danger">
                          {message}
                        </p>
                      ))
                    }
                  />
                </Form.Group>
              </div>

              <div className="mb-3">
                <Form.Group>
                  <Form.Label>Mobile Number</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Mobile Number"
                    onWheel={(e) => e.currentTarget.blur()}
                    onClick={(e) => e.currentTarget.select()}
                    {...register("phone", {
                      required: "This field is required",
                      maxLength: {
                        value: 10,
                        message: "This input exceed maxLength 10",
                      },
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="phone"
                    render={({ messages }) =>
                      messages &&
                      Object.entries(messages).map(([type, message]) => (
                        <p key={type} className="text-danger">
                          {message}
                        </p>
                      ))
                    }
                  />
                </Form.Group>
              </div>

              <div className="mb-3">
                <button type="submit" className="btn btn-primary">
                  ADD
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default CustomerCreateModal;
