/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import {
  setAdminUserData,
  getAdminUserData,
  ROLES,
  checkCompanyRoles,
  getActiveRestaurantId,
} from "../../common/Utils";
import { getUserData } from "../../services/AdminService";
import { useLocation } from "react-router-dom";
import NavLinks from "../NavLinks";
import { PageRoutes } from "../../common/Constants";
import useWindowSize from "../../common/customScreenSizeHook";
import _ from "lodash";

const Sidebar = ({
  showSideBar,
  setShowSideBar,
}: {
  showSideBar: any;
  setShowSideBar: any;
}) => {
  const location = useLocation();
  const size = useWindowSize();
  const pathname = location.pathname;
  let activeRestro = getActiveRestaurantId();
  const localUserData = getAdminUserData();

  useEffect(() => {
    if (_.isNil(localUserData)) {
      getUserData().then((response) => {
        setAdminUserData(response);
      });
    }
    return () => {};
  }, [localUserData]);

  return (
    <div
      className={`sidebar-bg  pb-3 ${
        !showSideBar ? "open sidebarClose" : "sidebar"
      }`}
      style={{
        backgroundImage: `url("/img/sidebar_bg.png")`,
        opacity: "0.9",
      }}
    >
      <nav className="navbar navbar-white">
        <div className="d-flex justify d-none d-md-block">
          {showSideBar ? (
            <img
              src="/foodieho_white.png"
              className="ms-3"
              style={{ width: "125px", objectFit: "cover" }}
            />
          ) : (
            ""
          )}

          {size.width !== undefined && size?.width < 990 ? (
            <img src="/favicon.png" className="ms-2" />
          ) : (
            <span
              className="sidebar-toggler text-white flex text-decoration-none pointer  "
              onClick={() => {
                setShowSideBar(!showSideBar);
              }}
            >
              <i className="fa fa-bars ms-3 mt-2"></i>
            </span>
          )}
        </div>
        <div className="w-100">
          <hr style={{ border: "1px solid white" }} />
        </div>
        <div className={`navbar-nav ${showSideBar ? "ps-3" : ""} mt-1 w-100`}>
          {!showSideBar ? "" : <p className=" ps-1 text-white ">Main Links</p>}
          <NavLinks
            pathname={pathname}
            showSideBar={showSideBar}
            name={"Dashboard"}
            path={PageRoutes.DASHBOARD}
            setShowSideBar={setShowSideBar}
            icon_name="fa-chart-line"
          />

          {activeRestro != null && (
            <>
              <NavLinks
                pathname={pathname}
                showSideBar={showSideBar}
                name={"Reviews"}
                path={PageRoutes.REVIEWS}
                setShowSideBar={setShowSideBar}
                icon_name="fa-comment"
              />

              {checkCompanyRoles([
                ROLES.ADMIN,
                ROLES.MANAGER,
                ROLES.CASHIER,
              ]) && (
                <>
                  <NavLinks
                    pathname={pathname}
                    showSideBar={showSideBar}
                    name={"New Order"}
                    path={PageRoutes.NEW_ORDER}
                    setShowSideBar={setShowSideBar}
                    icon_name="fa-plus-circle"
                  />
                  <NavLinks
                    pathname={pathname}
                    showSideBar={showSideBar}
                    name={"KOT Status"}
                    path={PageRoutes.KOT}
                    setShowSideBar={setShowSideBar}
                    icon_name="fa-utensils"
                  />
                  <NavLinks
                    pathname={pathname}
                    showSideBar={showSideBar}
                    name={"KOT History"}
                    path={PageRoutes.KOT_HISTORY}
                    setShowSideBar={setShowSideBar}
                    icon_name="fa-sticky-note"
                  />

                  <NavLinks
                    pathname={pathname}
                    showSideBar={showSideBar}
                    name={"Order History"}
                    path={PageRoutes.ORDER_LIST}
                    setShowSideBar={setShowSideBar}
                    icon_name="fa-sticky-note"
                  />
                  <NavLinks
                    pathname={pathname}
                    showSideBar={showSideBar}
                    name={"Session History"}
                    path={PageRoutes.SESSION_HISTORY}
                    setShowSideBar={setShowSideBar}
                    icon_name="fa-history"
                  />
                  <NavLinks
                    pathname={pathname}
                    showSideBar={showSideBar}
                    name={"Invoice History"}
                    path={PageRoutes.INVOICES}
                    setShowSideBar={setShowSideBar}
                    icon_name="fa-receipt"
                  />
                  <NavLinks
                    pathname={pathname}
                    showSideBar={showSideBar}
                    name={"Settlement History"}
                    path={PageRoutes.SETTLEMENT}
                    setShowSideBar={setShowSideBar}
                    icon_name="fa-receipt"
                  />
                </>
              )}
            </>
          )}
          {activeRestro == null && (
            <>
              <NavLinks
                pathname={pathname}
                showSideBar={showSideBar}
                name={"Labels"}
                path={PageRoutes.LABELS}
                setShowSideBar={setShowSideBar}
                icon_name="fa-tag"
              />

              <NavLinks
                pathname={pathname}
                showSideBar={showSideBar}
                name={"Users"}
                path={PageRoutes.USERS}
                setShowSideBar={setShowSideBar}
                icon_name="fa-users"
              />
            </>
          )}

          {activeRestro != null && (
            <>
              {checkCompanyRoles([
                ROLES.ADMIN,
                ROLES.MANAGER,
                ROLES.CASHIER,
              ]) && (
                <>
                  {!showSideBar ? (
                    <></>
                  ) : (
                    <p className="mt-2 ps-1 text-white ">Operations</p>
                  )}

                  <NavLinks
                    pathname={pathname}
                    showSideBar={showSideBar}
                    name={"Expense"}
                    path={PageRoutes.EXPENSE}
                    setShowSideBar={setShowSideBar}
                    icon_name="fa-sticky-note"
                  />

                  <NavLinks
                    pathname={pathname}
                    showSideBar={showSideBar}
                    name={"Banking"}
                    path={PageRoutes.BANKING}
                    setShowSideBar={setShowSideBar}
                    icon_name="fa-university"
                  />
                  <NavLinks
                    pathname={pathname}
                    showSideBar={showSideBar}
                    name={"Supplier"}
                    path={PageRoutes.SUPPLIER}
                    setShowSideBar={setShowSideBar}
                    icon_name="fa-store"
                  />
                  <NavLinks
                    pathname={pathname}
                    showSideBar={showSideBar}
                    name={"Customers"}
                    path={PageRoutes.CUSTOMER}
                    setShowSideBar={setShowSideBar}
                    icon_name="fa-users"
                  />
                  <NavLinks
                    pathname={pathname}
                    showSideBar={showSideBar}
                    name={"Inventory"}
                    path={PageRoutes.INVENTORY}
                    setShowSideBar={setShowSideBar}
                    icon_name="fa-utensils"
                  />
                </>
              )}
            </>
          )}
          {!showSideBar ? (
            <></>
          ) : (
            <p className="mt-2 ps-1 text-white ">Customization</p>
          )}
          {activeRestro == null && (
            <NavLinks
              pathname={pathname}
              showSideBar={showSideBar}
              name={"Company"}
              path={PageRoutes.COMPANY}
              icon_name="fa-building"
              setShowSideBar={setShowSideBar}
            />
          )}

          <NavLinks
            pathname={pathname}
            showSideBar={showSideBar}
            setShowSideBar={setShowSideBar}
            name={"Products"}
            path={PageRoutes.PRODUCTS}
            icon_name="fa-hotdog"
          />
          {activeRestro != null && (
            <>
              <NavLinks
                pathname={pathname}
                showSideBar={showSideBar}
                name={"Employee"}
                path={PageRoutes.EMPlOYEES}
                setShowSideBar={setShowSideBar}
                icon_name="fa-users"
              />
              <NavLinks
                pathname={pathname}
                showSideBar={showSideBar}
                name={"Menu"}
                path={PageRoutes.MENU}
                icon_name="fa-file-alt"
                setShowSideBar={setShowSideBar}
              />

              {checkCompanyRoles([
                ROLES.ADMIN,
                ROLES.MANAGER,
                ROLES.CASHIER,
              ]) && (
                <>
                  <NavLinks
                    pathname={pathname}
                    showSideBar={showSideBar}
                    name={"Table Group"}
                    path={PageRoutes.TABLE_GROUP}
                    setShowSideBar={setShowSideBar}
                    icon_name="fa-layer-group"
                  />
                  <NavLinks
                    pathname={pathname}
                    showSideBar={showSideBar}
                    name={"Table"}
                    path={PageRoutes.TABLE}
                    icon_name="fa-table"
                    setShowSideBar={setShowSideBar}
                  />
                </>
              )}
            </>
          )}
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;
