import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import {
  getCompanyCustomerDetail,
  getCustomerTransactionList,
} from "../../services/AdminService";
import { Link, useParams } from "react-router-dom";
import { accountTranscations } from "../../class/Banking";
import { useCommon } from "../../components/Context/CommonContext";
import LinkComponent from "../../components/Link/LinkComponent";
import { PageRoutes } from "../../common/Constants";

type CustomerResponse = {
  id: number;
  user: User;
  credit_allowed: boolean;
  credit_limit: number;
  total_debit: number;
  total_credit: number;
  updated_at: Date;
  created_at: Date;
  company: number;
};

type User = {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  image: null;
  image_url: null;
  level: number;
  phone_verified: boolean;
  total_points: number;
};

const CustomerDetail = () => {
  const param = useParams();
  const id = param.id;
  const [customerDetail, setCustomerDetail] = useState<CustomerResponse>();
  const [accountTransactions, setAccountTransactions] = useState<
    accountTranscations[]
  >([]);
  const { dispatch } = useCommon();

  useEffect(() => {
    dispatch({ type: "loading", payload: true });
    getCompanyCustomerDetail(Number(id))
      .then((response: CustomerResponse) => {
        setCustomerDetail(response);
        getCustomerTransactionList(Number(response.user.id))
          .then((response: any) => {
            setAccountTransactions(response.results);
            dispatch({ type: "loading", payload: false });
          })
          .catch(() => {});
      })
      .catch(() => {});
  }, [dispatch, id]);
  return (
    <div className=" container-fluid pt-4 px-4">
      <div className="row ">
        <div className=" mb-3">
          <h3>{customerDetail?.user?.first_name} {customerDetail?.user?.last_name}</h3>
          <div className="d-flex justify-content-between">
            <h6>
              Balance :{" "}
              {Number(customerDetail?.total_debit) -
                Number(customerDetail?.total_credit)}{" "}
            </h6>
            <Link to={PageRoutes.NEW_SETTLEMENT}>
              <Button variant="secondary">Settlement</Button>
            </Link>
          </div>

          <hr />
          <h4 className="my-1 h4">Account Transactions</h4>
          <Table borderless responsive>
            <thead>
              <tr>
                <th>Ref Id</th>
                <th className="w-25">Description</th>
                <th>Transaction type</th>
                <th>Debit</th>
                <th>Credit</th>
              </tr>
            </thead>
            <tbody>
              {accountTransactions?.map(
                ({
                  id,
                  debit,
                  credit,
                  ref_id,
                  ref_type,
                  description,
                }: accountTranscations) => (
                  <tr key={id}>
                    <td>
                      <LinkComponent data={{ type: ref_type, name: ref_id }} />
                    </td>
                    <td>{description}</td>
                    <td>{ref_type}</td>
                    <td>{debit}</td>
                    <td>{credit}</td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default CustomerDetail;
