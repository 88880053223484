import Lottie from "lottie-react";
import loadingAnimation from "../../src/lottie/loading.json";

const LoadingView = () => {
  return (
    <>
      <div
        id="spinner"
        className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
      >
        <Lottie animationData={loadingAnimation} />
      </div>
    </>
  );
};

export default LoadingView;
