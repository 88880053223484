import React, { useState } from "react";
import { ListGroup } from "react-bootstrap";
import SeachBox from "./SearchBox/SeachBox";
import { useNavigate } from "react-router-dom";
import {
  getActiveRestaurant,
  getTrimmedRestaurantName,
  isSuperUser,
  saveActiveRestaurant,
} from "../common/Utils";
import styles from "./Sidebar/Sidebar.module.css";
import useWindowSize from "../common/customScreenSizeHook";
import ButtonComponent, { Icons } from "./Buttons/ButtonComponent";

const CompanySwitchV2 = () => {
  const navigate = useNavigate();
  const size = useWindowSize();
  const [searchList, setSearchList] = useState([]);
  const [searchCount, setSearchCount] = useState<number>();
  const [activeRestro, setActiveRestro] = useState<any>(getActiveRestaurant());
  const getCompanySearchResults = (searchResultFromChild: any) => {
    setSearchList(searchResultFromChild.results);
    setSearchCount(searchResultFromChild.count);
  };

  return (
    <div className="d-flex p-1 w-100">
      {activeRestro == null ? (
        <div
          className={`mb-2 justify-content-around align-items-center ${styles.switch_account}`}
        >
          <div className="fw-bold mx-2">Switch Account</div>
          {isSuperUser() ? (
            <>
              <SeachBox
                parent={getCompanySearchResults}
                page_size={6}
                type="company"
              />
              {searchList.length > 0 ? (
                <ListGroup as="ol">
                  {searchList.map((node: any, index: number) => (
                    <ListGroup.Item
                      action
                      key={node.id}
                      onClick={() => {
                        setActiveRestro(node);
                        saveActiveRestaurant(node);
                        navigate(0);
                      }}
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <img
                        src={node.logo_url || "/img/default_restro_image.png"}
                        className="pointer"
                        height={60}
                        width={60}
                        alt="images"
                      />

                      <div className="me-auto">
                        <div className="fw-bold pointer">
                          {getTrimmedRestaurantName(node.name, 10)}
                        </div>
                        <div className="pointer">
                          {getTrimmedRestaurantName(node.full_address, 10)}
                        </div>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              ) : searchCount === 0 ? (
                <span className="text-danger">No result found</span>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div className="align-items-center">
          <div className="fw-bold mx-2 mb-2">Active Account</div>
          <div className="d-flex">
            <div className={`d-flex align-items-center ${styles.restrodiv}`}>
              <img
                src={activeRestro.logo_url || "/img/default_restro_image.png"}
                height={35}
                width={35}
                style={{ borderRadius: "50%" }}
                alt="images"
              />
              <div className="ms-3 me-4">
                <div className="fw-bold">
                  {size.width !== undefined && size?.width < 480
                    ? getTrimmedRestaurantName(activeRestro.name, 40)
                    : activeRestro.name}
                </div>
                {size.width !== undefined && size?.width < 480
                  ? getTrimmedRestaurantName(activeRestro.full_address, 40)
                  : activeRestro.name}
              </div>
            </div>
            <ButtonComponent
              tooltip={"Clear Active Restaurant"}
              variant={"primary"}
              type={Icons.FaCircleXmark}
              isButton={true}
              onSubmitClick={(e: any) => {
                setActiveRestro(null);
                saveActiveRestaurant(null);
                navigate("/");
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CompanySwitchV2;
