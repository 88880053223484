import { ErrorMessage } from "@hookform/error-message";
import React from "react";
import { Form } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import { useCommon } from "../../components/Context/CommonContext";
import { useNavigate } from "react-router-dom";
import { changePassword } from "../../services/AdminService";
import { PageRoutes } from "../../common/Constants";
import { parseErrorMessage } from "../../common/Utils";

type Password = {
  old_password: string;
  new_password1: string;
  new_password2: string;
};

const ChangePassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<Password>({
    defaultValues: {
      new_password1: "",
      new_password2: "",
    },
    criteriaMode: "all",
  });
  const { dispatch } = useCommon();
  const navigate = useNavigate();

  const handleChange: SubmitHandler<Password> = (event: any) => {
    dispatch({ type: "loading", payload: true });
    changePassword(event)
      .then((response: any) => {
        dispatch({ type: "loading", payload: false });
        dispatch({
          type: "toast",
          payload: {
            visible: true,
            message: "Changed Successfully.",
            success: true,
          },
        });
        navigate(PageRoutes.PROFILE);
      })
      .catch((error: any) => {
        dispatch({ type: "loading", payload: false });
        dispatch({
          type: "toast",
          payload: {
            visible: true,
            message: parseErrorMessage(error),
            success: false,
          },
        });
      });
  };

  return (
    <div className="container-fluid pt-4 px-4">
      <div className="row col-sm-12 ">
        <div className="col-sm-6 col-xl-6">
          <div className="bg-light  rounded h-100 p-4">
            <h6 className="mb-2">Change Password</h6>
            <form onSubmit={handleSubmit(handleChange)}>
              <div className="row">
                <div className="col-sm-12 ">
                  <div className="mb-3">
                    <Form.Group>
                      <Form.Label>New Password</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        {...register("new_password1", {
                          required: "You must specify a password",
                          minLength: {
                            value: 8,
                            message: "Minimum length is 8",
                          },
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="new_password1"
                        render={({ messages }) =>
                          messages &&
                          Object.entries(messages).map(([type, message]) => (
                            <p key={type} className="text-danger">
                              {message}
                            </p>
                          ))
                        }
                      />
                    </Form.Group>
                  </div>

                  <div className="mb-3">
                    <Form.Group>
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        {...register("new_password2", {
                          required: "This field is required",
                          validate: (val: string) => {
                            if (watch("new_password1") !== val) {
                              return "Your passwords do no match";
                            }
                          },
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="new_password2"
                        render={({ messages }) =>
                          messages &&
                          Object.entries(messages).map(([type, message]) => (
                            <p key={type} className="text-danger">
                              {message}
                            </p>
                          ))
                        }
                      />
                    </Form.Group>
                  </div>

                  <div className="mb-3">
                    <button type="submit" className="btn btn-primary">
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
