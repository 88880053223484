import {
  ADMIN_USER,
  DATA_ACTIVE_RESTRO_SESSION,
  DATA_ADMIN_USER,
} from "./Constants";

const setAdminUserData = (data: any) => {
  localStorage.setItem(DATA_ADMIN_USER, JSON.stringify(data));
};

const getAdminUserData = () => {
  var sessionData = localStorage.getItem(DATA_ADMIN_USER);
  return sessionData;
};

export const getUserNameData = () => {
  try {
    var sessionData =
      localStorage.getItem(DATA_ADMIN_USER) || "{'username':'admin'}";
    var user = JSON.parse(sessionData).username;
    return user;
  } catch (e) {
    return "";
  }
};

export const getUserId = () => {
  var sessionData = localStorage.getItem(DATA_ADMIN_USER) || "{'id':'null'}";
  var userid = JSON.parse(sessionData).id;
  return userid;
};
export { setAdminUserData, getAdminUserData };

export const convertSingleDigitToDouble = (data: number) => {
  const stringData = "" + data;
  if (stringData.length === 1) {
    return "0" + data;
  }
  return stringData;
};

export const convertStarValue = (number: number): number => {
  return number / 0.05;
};

export const saveActiveRestaurant = (data: any) => {
  localStorage.setItem("multiSelections", JSON.stringify([]));
  localStorage.setItem(DATA_ACTIVE_RESTRO_SESSION, JSON.stringify(data));
};

export const getActiveRestaurant = () => {
  try {
    var sessionData = localStorage.getItem(DATA_ACTIVE_RESTRO_SESSION) || null;
    if (sessionData) {
      var user = JSON.parse(sessionData);
    } else {
      return null;
    }
    return user;
  } catch (e) {
    return null;
  }
};

export const options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
};
export const getActiveRestaurantId = () => {
  try {
    var sessionData = localStorage.getItem(DATA_ACTIVE_RESTRO_SESSION) || null;
    if (sessionData) {
      var id = JSON.parse(sessionData).id;
    } else {
      return null;
    }
    return id;
  } catch (e) {
    return null;
  }
};

export const getCompanyRoles = () => {
  try {
    var sessionData = localStorage.getItem(ADMIN_USER) || null;
    if (sessionData) {
      var data = JSON.parse(sessionData);
    } else {
      return null;
    }
    return data.user.company_roles;
  } catch (e) {
    return null;
  }
};

export const getActiveUserCompanyRole = (cid: number) => {
  var sessionData = localStorage.getItem(ADMIN_USER) || null;
  if (sessionData) {
    var data = JSON.parse(sessionData);
    const foundNode = data.user.company_roles.find(
      (node: any) => node.company.id === cid
    );
    if (foundNode) {
      return foundNode.role.name;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const checkCompanyRoles = (roles: String[]) => {
  var companyRoles = getCompanyRoles();
  if (roles.includes("superadmin") && isSuperUser()) {
    return true;
  }
  var result = false;
  companyRoles.forEach((node: any) => {
    if (roles.includes(node.role.name)) {
      result = true;
    }
  });
  return result;
};

export const isSuperUser = () => {
  try {
    var sessionData = localStorage.getItem(ADMIN_USER) || null;
    if (sessionData) {
      var data = JSON.parse(sessionData);
    } else {
      return null;
    }

    return data.user.is_superuser;
  } catch (e) {
    return null;
  }
};
export const parseErrorMessage = (data: any) => {
  if (typeof data === "object") {
    const firstKey = Object.keys(data)[0];
    if (firstKey) {
      return data[firstKey][0];
    }
  } else {
    return "Error";
  }
};
export const isStaffUser = () => {
  try {
    var sessionData = localStorage.getItem(ADMIN_USER) || null;
    if (sessionData) {
      var data = JSON.parse(sessionData);
    } else {
      return null;
    }
    return data.user.is_staff;
  } catch (e) {
    return null;
  }
};

export const getLocalDateTime = (timestamp: any) => {
  if (timestamp == null) {
    return "N/A";
  }
  const date = new Date(timestamp);
  return date.toLocaleDateString() + " | " + date.toLocaleTimeString();
};

export const getLocalDate = (timestamp: any) => {
  if (timestamp == null) {
    return "N/A";
  }
  const date = new Date(timestamp);
  return date.toLocaleDateString();
};

export const getLocalTime = (timestamp: any) => {
  if (timestamp == null) {
    return "N/A";
  }
  const date = new Date(timestamp);
  return date.toLocaleTimeString();
};

export const getTrimmedRestaurantName = (name: string, length: number) => {
  if (name.length > length) {
    return name.substring(0, length) + "...";
  }
  return name;
};
export const ROLES = {
  SUPERADMIN: "superadmin",
  MANAGER: "manager",
  ADMIN: "admin",
  CASHIER: "cashier",
};

export const capitalizeFirstLetter = (str: String) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
