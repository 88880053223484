import React, { useEffect, useState } from "react";
import { Row, Col, Table } from "react-bootstrap";
import ButtonComponent, {
  Icons,
} from "../../components/Buttons/ButtonComponent";
import HeadingTitle from "../../components/HeadingTitle";
import { MessageStrings, PageRoutes } from "../../common/Constants";
import {
  addDeposit,
  deleteBank,
  getBankList,
} from "../../services/AdminService";
import VerticallyCenteredModal from "../../components/Model/VerticallyCenteredModel";
import { SubmitHandler, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useCommon } from "../../components/Context/CommonContext";
import NoResultFound from "../../components/NoResultFound";
type DepositAdd = {
  total: number;
  coa: number;
  description: string;
};

const ListBanking = () => {
  const [bankList, setBankList] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  const [showBankDeposit, setShowBankDeposit] = useState<boolean>(false);
  const [activeBankId, setActiveBankId] = useState<number | null>(null);
  const { dispatch } = useCommon();

  useEffect(() => {
    setLoading(true);
    getBankList(null)
      .then((response) => {
        if (response) {
          setLoading(false);
          setBankList(response.results);
        }
      })
      .catch((error) => {
        dispatch({
          type: "messageModal",
          payload: {
            visible: true,
            title: MessageStrings.ERROR_MESSAGE_TITLE,
            message: MessageStrings.ERROR_MESSAGE_GENERIC,
            error: error,
          },
        });
      });
    return () => {};
  }, [dispatch]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<DepositAdd>({ criteriaMode: "all" });

  const handleAddDeposit: SubmitHandler<DepositAdd> = (event: any) => {
    dispatch({ type: "loading", payload: true });

    const data = {
      ...event,
      coa: 406,
    };
    addDeposit(activeBankId, data)
      .then((response: any) => {
        dispatch({ type: "loading", payload: false });
        dispatch({
          type: "toast",
          payload: {
            visible: true,
            message: ` Added Successfully`,
            success: true,
          },
        });
        setShowBankDeposit(false);
      })
      .catch((error: any) => {
        dispatch({
          type: "toast",
          payload: {
            visible: true,
            message: "Error",
            success: true,
          },
        });
        setShowBankDeposit(false);
        dispatch({ type: "loading", payload: false });
      });
  };

  const handleDelete = (e: any, name: string, bank_id: number) => {
    e.preventDefault();
    // eslint-disable-next-line no-restricted-globals
    if (confirm(`Delete ${name}?`)) {
      deleteBank(bank_id)
        .then((response: any) => {
          window.location.reload();
        })
        .catch((error: any) => {
          dispatch({
            type: "messageModal",
            payload: {
              visible: true,
              title: MessageStrings.ERROR_MESSAGE_TITLE,
              message: MessageStrings.ERROR_MESSAGE_GENERIC,
            },
          });
        });
    }
  };

  return (
    <div className="container-fluid pt-4 px-4">
      <Row className="mb-4">
        <Col>
          <HeadingTitle title="Banking List" />
        </Col>
        <Col className="text-end">
          <ButtonComponent
            tooltip={"Add Banking"}
            variant={"primary"}
            href={PageRoutes.BANKING + "/new"}
            type={Icons.Add}
            isButton={true}
          />
        </Col>
      </Row>
      {bankList.length > 0 && (
        <Table borderless responsive>
          <thead>
            <tr>
              <th>Id</th>
              <th>Name</th>
              <th>Abbreviation</th>
              <th>Debit</th>
              <th>Credit</th>
              <th>Balance</th>
              <th className="w-25">Action</th>
            </tr>
          </thead>
          <tbody>
            {bankList?.map(
              ({
                id,
                name,
                total_debit,
                total_credit,
                abbreviation,
                company,
              }: any) => (
                <tr key={id}>
                  <td>{id}</td>
                  <td>
                    <a href={`/banking/${id}`}>{name}</a>
                  </td>
                  <td>{abbreviation}</td>
                  <td>{total_debit}</td>
                  <td>{total_credit}</td>
                  <td>{total_debit - total_credit}</td>
                  <td className="w-25">
                    <ButtonComponent
                      tooltip={"Payment Methods"}
                      variant={"primary"}
                      type={Icons.Payment}
                      href={
                        PageRoutes.BANKING +
                        `/${id}` +
                        PageRoutes.PAYMENT_METHODS
                      }
                      isButton={false}
                    />
                    <ButtonComponent
                      tooltip={"Edit"}
                      variant={"primary"}
                      type={Icons.Edit}
                      href={PageRoutes.BANKING + `/${id}/edit`}
                      isButton={false}
                    />
                    <ButtonComponent
                      tooltip={"Delete"}
                      variant={"primary"}
                      type={Icons.Delete}
                      isButton={false}
                      onSubmitClick={(e: any) => {
                        handleDelete(e, name, id);
                      }}
                    />
                    <ButtonComponent
                      tooltip={"Add Deposit"}
                      variant={"primary"}
                      type={Icons.Add}
                      isButton={false}
                      onSubmitClick={() => {
                        setShowBankDeposit(true);
                        setActiveBankId(id);
                      }}
                    />
                  </td>
                </tr>
              )
            )}
          </tbody>
        </Table>
      )}
      {bankList.length === 0 && <NoResultFound message={"No Results Found"} />}
      {showBankDeposit && (
        <VerticallyCenteredModal
          model_title="Add Deposit"
          show={showBankDeposit}
          onHide={() => setShowBankDeposit(false)}
        >
          <form onSubmit={handleSubmit(handleAddDeposit)}>
            <div className="mb-2">
              <label htmlFor="total" className="form-label">
                Total
              </label>
              <input
                type="number"
                className="form-control"
                autoComplete="off"
                placeholder="Enter total here.."
                onWheel={(e) => e.currentTarget.blur()}
                {...register("total", { required: "Required" })}
              />
              <ErrorMessage
                errors={errors}
                name="total"
                render={({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <p key={type} className="text-danger">
                      * {message}
                    </p>
                  ))
                }
              />
            </div>

            <div className="mb-2">
              <label htmlFor="description" className="form-label">
                Description
              </label>
              <textarea
                className="form-control"
                placeholder="Enter description here.."
                {...register("description", { required: "Required" })}
              />
              <ErrorMessage
                errors={errors}
                name="description"
                render={({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <p key={type} className="text-danger">
                      * {message}
                    </p>
                  ))
                }
              />
            </div>

            <input className="btn btn-primary" type="submit" />
          </form>
        </VerticallyCenteredModal>
      )}
    </div>
  );
};

export default ListBanking;
