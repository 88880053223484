import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";

const NavLink = ({
  pathname,
  showSideBar,
  name,
  path,
  icon_name,
  setShowSideBar,
}: {
  pathname: any;
  showSideBar: any;
  name: string;
  path: string;
  icon_name: string;
  setShowSideBar: any;
}) => {
  return (
    <>
      {/* Show only on Big Screen */}
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 100 }}
        overlay={
          !showSideBar ? <Tooltip id="button-tooltip">{name}</Tooltip> : <></>
        }
      >
        <Link
          to={path}
          className={`nav-item nav-link  ${
            pathname === path ? "active text-primary bg-white" : ""
          } d-none d-md-block`}
        >
          <i className={`fa ${icon_name} me-2 `}></i>
          {!showSideBar ? (
            <span className="d-inline-block d-md-none">{name}</span>
          ) : (
            name
          )}
        </Link>
      </OverlayTrigger>

      {/* Show only on Mobile */}
      <Link
        to={path}
        className={`nav-item nav-link  ${
          pathname === path ? "active text-primary bg-white" : ""
        } d-block d-md-none`}
        onClick={() => {
          setShowSideBar(!showSideBar);
        }}
      >
        <i className={`fa ${icon_name} me-2 `}></i>
        {!showSideBar ? (
          <span className="d-inline-block d-md-none">{name}</span>
        ) : (
          name
        )}
      </Link>
    </>
  );
};

export default NavLink;
