import React, { Fragment, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import CustomerCreateModal from "../../pages/companyCustomers/CustomerCreateModal";
import { ErrorMessage } from "@hookform/error-message";
import { searchUserList } from "../../services/AdminService";
import { Stack } from "react-bootstrap";
import { MessageStrings } from "../../common/Constants";
import { useCommon } from "../Context/CommonContext";

type UserSearchInput = {
  register?: {};
  watch?: {};
  errors?: any;
  label: string;
  name: string;
  onChange: any;
  selectedUser: any;
  placeholder: string;
  onCreateSuccess?: {};
};

const UserSearchBox: React.FC<UserSearchInput> = ({
  register,
  watch,
  errors,
  label,
  name,
  onChange,
  selectedUser,
  placeholder,
  onCreateSuccess,
}: UserSearchInput) => {
  const { dispatch } = useCommon();

  const [showCreateCustomerDialog, setShowCreateCustomerDialog] =
    useState(false);
  const [isLoading, setLoading] = useState(false);
  const [optionsUser, setOptionsUser] = useState([]);

  const handleSearchUser = async (value: any) => {
    setLoading(true);
    searchUserList(value, 20)
      .then((response: any) => {
        const newList = response;
        newList.push({ id: null, username: "" });
        setOptionsUser(newList);
        setLoading(false);
      })
      .catch((error) => {
        dispatch({
          type: "messageModal",
          payload: {
            visible: true,
            title: MessageStrings.ERROR_MESSAGE_TITLE,
            message: MessageStrings.ERROR_MESSAGE_GENERIC,
          },
        });
      });
  };

  const filterBy = () => true;

  return (
    <>
      <CustomerCreateModal
        onCreateSuccess={onCreateSuccess}
        setShowCreateCustomerDialog={setShowCreateCustomerDialog}
        showCreateCustomerDialog={showCreateCustomerDialog}
      />

      <AsyncTypeahead
        {...register}
        id="user-search-box"
        filterBy={filterBy}
        isLoading={isLoading}
        labelKey={name}
        minLength={3}
        onSearch={handleSearchUser}
        options={optionsUser}
        placeholder={placeholder}
        selected={selectedUser}
        onChange={onChange}
        renderMenuItemChildren={(user: any) => (
          <Fragment>
            {user.id !== null && user.username && (
              <>
                <Stack direction="horizontal">
                  <img
                    alt={user.username}
                    src={user.image || "/img/profile.png"}
                    style={{
                      height: "32px",
                      marginRight: "10px",
                      width: "32px",
                    }}
                  />

                  <Stack direction="vertical">
                    <span>
                      {user.first_name !== ""
                        ? `${user.first_name} ${user.last_name}`
                        : user.username}
                    </span>
                    <span>{user.phone !== "" ? user.phone : ""}</span>
                  </Stack>
                </Stack>
              </>
            )}

            {(user as any).id === null && (
              <Stack
                direction="horizontal"
                onClick={() => {
                  setShowCreateCustomerDialog(true);
                }}
              >
                <img
                  className="rounded-circle"
                  src={"/img/profile.png"}
                  alt="profile dummy"
                  style={{
                    width: "32px",
                    height: "32px",
                    marginRight: "10px",
                  }}
                />
                <span>
                  <strong>Create New User</strong>
                </span>
              </Stack>
            )}
          </Fragment>
        )}
      />

      {errors && (
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ messages }) =>
            messages &&
            Object.entries(messages).map(([type, message]) => (
              <p key={type} className="text-danger">
                {message}
              </p>
            ))
          }
        />
      )}
    </>
  );
};

export default UserSearchBox;
