import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import {
  getBankAccount,
  getBankTransaction,
} from "../../services/AdminService";
import { Link, useParams } from "react-router-dom";
import { BankDetail, accountTranscations } from "../../class/Banking";
import LinkComponent from "../../components/Link/LinkComponent";
import { PageRoutes } from "../../common/Constants";

const BankDetails = () => {
  const param = useParams();
  const bid = param.bid;
  const [bankDetail, setBankDetail] = useState<BankDetail>();
  const [accountTransactions, setAccountTransactions] = useState<
    accountTranscations[]
  >([]);
  useEffect(() => {
    getBankAccount(bid)
      .then((response: BankDetail) => {
        setBankDetail(response);
      })
      .catch(() => {});
    getBankTransaction(Number(bid))
      .then((response: any) => {
        setAccountTransactions(response.results);
      })
      .catch(() => {});
  }, [bid]);
  return (
    <div className=" container-fluid pt-4 px-4">
      <div className="row ">
        <div className=" mb-3">
          <h3>{bankDetail?.name}</h3>
          <div className="d-flex justify-content-between">
            <h6>
              Balance :{" "}
              {Number(bankDetail?.total_debit) -
                Number(bankDetail?.total_credit)}
            </h6>
            <Link to={PageRoutes.NEW_SETTLEMENT}>
              <Button variant="secondary">Settlement</Button>
            </Link>
          </div>
          <hr />
          <h4 className="my-1 h4">Account Transactions</h4>
          <Table borderless responsive>
            <thead>
              <tr>
                <th>Ref Id</th>
                <th className="w-25">Description</th>
                <th className="w-25">Transactions Type</th>
                <th>Debit</th>
                <th>Credit</th>
              </tr>
            </thead>
            <tbody>
              {accountTransactions?.map(
                ({
                  id,
                  debit,
                  credit,
                  ref_type,
                  ref_id,
                  description,
                }: accountTranscations) => (
                  <tr key={id}>
                    <td>
                      <LinkComponent data={{ type: ref_type, name: ref_id }} />
                    </td>
                    <td>{description}</td>
                    <td>{ref_type}</td>
                    <td>{debit}</td>
                    <td>{credit}</td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default BankDetails;
