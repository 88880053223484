import React, { useEffect, useState } from "react";
import { Row, Col, Table, Breadcrumb } from "react-bootstrap";
import { MessageStrings, PageRoutes } from "../../../common/Constants";
import ButtonComponent, {
  Icons,
} from "../../../components/Buttons/ButtonComponent";
import HeadingTitle from "../../../components/HeadingTitle";
import {
  deletePaymentMethod,
  getPaymentMethodsList,
} from "../../../services/AdminService";
import ImageViewers from "../../../components/ImageViewer/ImageViewers";
import { useParams } from "react-router-dom";
import { useCommon } from "../../../components/Context/CommonContext";
type payment_method = {
  id: number;
  is_active: boolean;
  is_active_in_pos: boolean;
  name: string;
  image: string;
  updated_at: string;
  created_at: string;
  bank_account: {
    id: number;
    is_active: boolean;
    is_editable: boolean;
    abbrevation: string;
    name: string;
    code: number;
    updated_at: string;
    created_at: string;
    company: number;
  };
};

const ListPaymentMethods = () => {
  const [paymentMethodsList, setPaymentMethodsList] = useState<
    payment_method[]
  >([]);
  const [loading, setLoading] = useState(false);
  const bid = useParams().bid;
  const { dispatch } = useCommon();

  useEffect(() => {
    setLoading(true);
    getPaymentMethodsList(Number(bid))
      .then((response: any) => {
        setLoading(false);
        setPaymentMethodsList(response.results);
      })
      .catch((error) => {
        dispatch({
          type: "messageModal",
          payload: {
            visible: true,
            title: MessageStrings.ERROR_MESSAGE_TITLE,
            message: MessageStrings.ERROR_MESSAGE_GENERIC,
          },
        });
      });
  }, [bid, dispatch]);

  const handleDelete = (e: any, name: string, method_id: number) => {
    e.preventDefault();
    // eslint-disable-next-line no-restricted-globals
    if (confirm(`Delete ${name}?`)) {
      deletePaymentMethod(method_id)
        .then((response: any) => {
          window.location.reload();
        })
        .catch((error: any) => {
          dispatch({
            type: "messageModal",
            payload: {
              visible: true,
              title: MessageStrings.ERROR_MESSAGE_TITLE,
              message: MessageStrings.ERROR_MESSAGE_GENERIC,
            },
          });
        });
    }
  };
  return (
    <div className="container-fluid pt-4 px-4">
      <Row>
        <Col>
          <HeadingTitle title="Payment Methods" />
        </Col>
        <Col className="text-end">
          <ButtonComponent
            tooltip={"Add Payment Method"}
            variant={"primary"}
            href={
              PageRoutes.BANKING +
              `/${bid}` +
              PageRoutes.PAYMENT_METHODS +
              "/new"
            }
            type={Icons.Add}
            isButton={true}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Breadcrumb>
            <Breadcrumb.Item href={PageRoutes.BANKING}>
              <small>Banking List</small>
            </Breadcrumb.Item>
            {paymentMethodsList.length !== 0 && (
              <Breadcrumb.Item href={PageRoutes.BANKING}>
                <small>{paymentMethodsList[0]?.bank_account.name}</small>
              </Breadcrumb.Item>
            )}
            <Breadcrumb.Item active>
              <small>Payment Methods</small>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      {paymentMethodsList.length === 0 ? (
        <p className="text-center">No results found</p>
      ) : (
        <Table borderless responsive>
          <thead>
            <tr>
              <th>Id</th>
              <th>Name</th>
              <th>Image</th>
              <th className="w-25">Action</th>
            </tr>
          </thead>
          <tbody>
            {paymentMethodsList?.map(({ id, name, image }: any) => (
              <tr key={id}>
                <td>{id}</td>
                <td>{name}</td>
                <td>
                  {
                    <ImageViewers
                      images={[{ image_url: `${image || "/img/profile.png"}` }]}
                      placeholder={true}
                    />
                  }
                </td>
                <td className="w-25">
                  <ButtonComponent
                    tooltip={"Edit"}
                    variant={"primary"}
                    href={
                      PageRoutes.BANKING +
                      "/" +
                      bid +
                      PageRoutes.PAYMENT_METHODS +
                      "/edit/" +
                      id
                    }
                    type={Icons.Edit}
                    isButton={false}
                  />

                  <ButtonComponent
                    tooltip={"Delete"}
                    variant={"primary"}
                    type={Icons.Delete}
                    isButton={false}
                    onSubmitClick={(e: any) => {
                      handleDelete(e, name, id);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default ListPaymentMethods;
