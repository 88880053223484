import { ErrorMessage } from "@hookform/error-message";
import _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import {
  deleteProductIngredients,
  getProductDetail,
  getProductIngredients,
  setProductIngredients,
} from "../../services/AdminService";
import { getActiveRestaurantId } from "../../common/Utils";
import { SubmitHandler, useForm } from "react-hook-form";
import { Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import ButtonComponent, {
  Icons,
} from "../../components/Buttons/ButtonComponent";
import { useCommon } from "../../components/Context/CommonContext";
import HeadingTitle from "../../components/HeadingTitle";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { Product } from "../../class/Product";
import { getInventoryList } from "../../services/inventoryApi";
import { useDebounce } from "use-debounce";
import NoResultFound from "../../components/NoResultFound";
import { Inventory } from "../../class/Inventory";
import { MessageStrings } from "../../common/Constants";

type ProductIngredient = {
  quantity: string;
  stock_item: number;
};

const AddProductIngredients = (props: { id: any }) => {
  const { dispatch } = useCommon();
  const navigate = useNavigate();

  const { id } = useParams();

  const [searchValue] = useState("");
  const [productDetail, setProductDetail] = useState<Product>();
  const [ingredients, setIngredients] = useState<ProductIngredient[]>([]);
  const [isLoadingInventoryList, setIsLoadingInventoryList] = useState(false);
  const [selectedInventory, setSelectedInventory] = useState<any>();
  const [inventoryList, setInventoryList] = useState([]);

  const [debouncedSearchValue] = useDebounce(searchValue, 1000);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<ProductIngredient>({ criteriaMode: "all" });

  const handleInsert: SubmitHandler<ProductIngredient> = (event: any) => {
    if (id) {
      const data = {
        quantity: event.quantity,
        stock_item: event.stock_item,
      };
      setProductIngredients(getActiveRestaurantId(), parseInt(id), data)
        .then((response: any) => {
          if (response) {
            dispatch({ type: "loading", payload: false });
            dispatch({
              type: "toast",
              payload: {
                visible: true,
                message: `Added Successfully`,
                success: true,
              },
            });
            navigate(0);
          }
        })
        .catch(() => {
          dispatch({ type: "loading", payload: false });
          dispatch({
            type: "toast",
            payload: {
              visible: true,
              message: "Error",
              success: false,
            },
          });
        });
    }
  };

  const handleDelete = (
    e: any,
    stock_item: Inventory,
    ingredientId: number
  ) => {
    e.preventDefault();
    // eslint-disable-next-line no-restricted-globals
    let confirmValue: boolean = confirm(`Delete ${stock_item.name}?`);
    if (confirmValue === true) {
      if (id)
        deleteProductIngredients(
          getActiveRestaurantId(),
          parseInt(id),
          ingredientId
        )
          .then(() => {
            navigate(0);
          })
          .catch((error: any) => {
            dispatch({
              type: "messageModal",
              payload: {
                visible: true,
                title: MessageStrings.ERROR_MESSAGE_TITLE,
                message: MessageStrings.ERROR_MESSAGE_GENERIC,
              },
            });
          });
    }
  };

  const handleSearchInventory = (e: any) => {
    setIsLoadingInventoryList(true);
    getInventoryList(debouncedSearchValue, 1, 500)
      .then((response: any) => {
        setIsLoadingInventoryList(false);
        setInventoryList(response?.results);
      })
      .catch((error: any) => {
        setIsLoadingInventoryList(false);
        console.error("Error fetching inventory data:", error);
      });
  };

  useEffect(() => {
    if (!_.isNil(id)) {
      getProductDetail(getActiveRestaurantId(), id!)
        .then((response: Product) => {
          if (response) {
            setProductDetail(response);
          }
        })
        .catch((error) => {
          dispatch({
            type: "messageModal",
            payload: {
              visible: true,
              title: MessageStrings.ERROR_MESSAGE_TITLE,
              message: MessageStrings.ERROR_MESSAGE_GENERIC,
            },
          });
        });

      getProductIngredients(getActiveRestaurantId(), parseInt(id)).then(
        (response: any) => {
          setIngredients(response);
        }
      );
    }
  }, [dispatch, id]);

  return (
    <div className="container-fluid pt-4 px-4">
      <div className="row">
        <HeadingTitle title={`${productDetail?.name} | Ingredients`} />

        <div className="col-sm-12 col-md-4">
          <div className="bg-light rounded p-4">
            <h6>Add Product Ingredients</h6>
            <form onSubmit={handleSubmit(handleInsert)}>
              <div className="mb-2">
                <label htmlFor="Inventory Item" className="form-label">
                  <small>Inventory Item</small>
                </label>
                <AsyncTypeahead
                  {...register("stock_item", {
                    required: "Required",
                  })}
                  filterBy={() => true}
                  id="async-example"
                  isLoading={isLoadingInventoryList}
                  labelKey="name"
                  minLength={3}
                  onSearch={(data) => {
                    handleSearchInventory(data);
                  }}
                  options={inventoryList}
                  placeholder="Search Inventory"
                  selected={selectedInventory}
                  onChange={(selected: any) => {
                    setSelectedInventory(selected);
                    setValue(
                      "stock_item",
                      selected.length ? selected[0].id : null
                    );
                  }}
                  renderMenuItemChildren={(option: any) => (
                    <Fragment>
                      {(option as any).id !== null && (
                        <Fragment>
                          <div>
                            <span>{option.name}</span>
                          </div>
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="stock_item"
                  render={({ messages }) =>
                    messages &&
                    Object.entries(messages).map(([type, message]) => (
                      <p key={type} className="text-danger">
                        * {message}
                      </p>
                    ))
                  }
                />
              </div>

              <div className="mb-3">
                <label htmlFor="Quantity" className="form-label">
                  <small>Quantity</small>
                </label>
                <input
                  type="number"
                  onWheel={(e) => e.currentTarget.blur()}
                  className="form-control"
                  autoComplete="off"
                  {...register("quantity", { required: "Required" })}
                  step="any"
                />
                <ErrorMessage
                  errors={errors}
                  name="quantity"
                  render={({ messages }) =>
                    messages &&
                    Object.entries(messages).map(([type, message]) => (
                      <p key={type} className="text-danger">
                        * {message}
                      </p>
                    ))
                  }
                />
              </div>

              <div className="row d-flex align-items-center">
                <div className="col-sm-5">
                  <button type="submit" className="btn btn-primary mt-2">
                    Proceed
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="col-sm-12 col-md-8">
          {ingredients.length > 0 && (
            <div className="bg-light rounded p-2">
              <Table responsive>
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Inventory Name</th>
                    <th>Quantity</th>
                    <th className="w-25">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {ingredients?.map(({ id, quantity, stock_item }: any) => (
                    <tr key={id}>
                      <td>{id}</td>
                      <td>{stock_item.name}</td>
                      <td>
                        {quantity} {stock_item.measuring_unit.name}
                      </td>
                      <td className="w-25">
                        <ButtonComponent
                          tooltip={"Delete"}
                          variant={"primary"}
                          type={Icons.Delete}
                          isButton={false}
                          onSubmitClick={(e: any) => {
                            handleDelete(e, stock_item, id);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}

          {ingredients.length === 0 && (
            <NoResultFound message="No Ingredients Added" />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddProductIngredients;
