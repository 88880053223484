import React, { Fragment, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";
import {
  addExpenseAttachment,
  addExpenseData,
  getBankList,
  searchSupplierList,
} from "../../services/AdminService";
import { useForm } from "react-hook-form";
import ExpenseCategoryAddComponent from "./ExpenseCategoryAddComponent";
import {
  CategoryItem,
  ExpenseAPIRequest,
  ExpenseType,
  ItemDetails,
  PaymentMode,
} from "../../class/Expense";
import ExpenseItemAddComponent from "./ExpenseItemAddComponent";
import { ErrorMessage } from "@hookform/error-message";
import { useCommon } from "../../components/Context/CommonContext";
import { useNavigate } from "react-router-dom";
import { PageRoutes } from "../../common/Constants";
import CustomerCreateModal from "../companyCustomers/CustomerCreateModal";
import { parseErrorMessage } from "../../common/Utils";
import UserSearchBox from "../../components/SearchBox/UserSearchBox";

const ExpenseAdd = () => {
  const { dispatch } = useCommon();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<ExpenseType>({
    defaultValues: {
      paid_to_type: "supplier",
      paid_by_type: "bankaccount",
    },
    criteriaMode: "all",
  });

  // for user list
  const [selectedUser, setSelectedUser] = useState<any>();
  const [selectedPaidByUser, setSelectedPaidByUser] = useState<any>();

  // for supplier list
  const [supplierList, setSupplierList] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState<any>();
  const [isLoadingSupplier, setIsLoadingSupplier] = useState(false);
  const handleSearchSupplier = (value: any) => {
    setIsLoadingSupplier(true);
    searchSupplierList(value)
      .then((response: any) => {
        const newList = response.results;
        newList.push({ id: null, name: "" });
        setSupplierList(newList);
        setIsLoadingSupplier(false);
      })
      .catch(() => {});
  };

  // for bank list
  const [bankList, setBankList] = useState([]);
  const [selectedBank, setSelectedBank] = useState<any>();

  const [totalAmount, setTotalAmount] = useState(0);
  const [totalItemAmount, setItemTotalAmount] = useState(0);

  const [categoryList, setCategoryList] = useState<CategoryItem[]>([]);
  const [inventoryList, setInventoryList] = useState<ItemDetails[]>([]);

  const [categoryListError, setCategoryListError] = useState<string>("");

  const [overallError, setOverallError] = useState<string>("");

  const [showCreateCustomerDialog, setShowCreateCustomerDialog] =
    useState(false);

  const filterBy = () => true;

  const handleAdd = (data: any) => {
    if (categoryList.length === 0) {
      setCategoryListError("* Please enter category data");
      return;
    } else {
      setCategoryListError("");
    }

    if (totalAmount < totalItemAmount) {
      setOverallError(
        "* Total amount mismatch. Inventory amount is greater than expense cateogory."
      );
      return;
    }

    dispatch({ type: "loading", payload: true });

    let expenseAPIRequest: ExpenseAPIRequest = {
      description: data.description,
      ref_no: "",
      payee_id: data.paid_to_name,
      payee_type: data.paid_to_type,
      paid_from_id: data.paid_by_name,
      paid_from_type: data.paid_by_type,
      payment_mode: data.payment_mode,
      categories: categoryList.map((item: CategoryItem) => {
        return {
          coa: item.category.id,
          description: item.description,
          total: item.amount,
        };
      }),
      items: inventoryList.map((item: ItemDetails) => {
        return {
          stock_item: item.product.id,
          price: item.rate,
          quantity: item.quantity,
        };
      }),
    };

    addExpenseData(expenseAPIRequest)
      .then((response) => {
        if (response.id != null && data.attachment) {
          addExpenseAttachment(response.id, data.attachment[0])
            .then((response) => {
              navigate(PageRoutes.EXPENSE);
            })
            .catch((error) => {
              console.log(error.message);
            });
        } else {
          navigate(PageRoutes.EXPENSE);
        }
      })
      .catch((error) => {
        dispatch({
          type: "toast",
          payload: {
            visible: true,
            message: parseErrorMessage(error),
          },
        });
      })
      .finally(() => {
        dispatch({ type: "loading", payload: false });
      });
  };

  useEffect(() => {
    getBankList(null)
      .then((response: any) => {
        setBankList(response.results);
      })
      .catch(() => {});
  }, []);

  return (
    <>
      <div className="p-4">
        <h4 className="mb-4">Expense</h4>
        <form className="expense" onSubmit={handleSubmit(handleAdd)}>
          <Row>
            <div className="d-flex flex-wrap justify-content-between">
              <section className=" d-flex flex-wrap col-md-10  col-lg-8 col-sm-12 gap-md-4">
                {/* paid to section STARTS */}
                <Col md={5} sm={12}>
                  <label
                    className="form-check-label fw-bold mb-2"
                    htmlFor="disabledFieldsetCheck"
                  >
                    Paid to
                  </label>
                  <br />
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="supplier"
                      {...register("paid_to_type")}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio2">
                      <i className="fa fa-building me-2"></i>Supplier
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="user"
                      {...register("paid_to_type")}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio1">
                      <i className="fa fa-user me-2"></i>User
                    </label>
                  </div>

                  {watch("paid_to_type") === "supplier" && (
                    <AsyncTypeahead
                      {...register("paid_to_name", {
                        required: " * Required",
                      })}
                      filterBy={filterBy}
                      id="async-example"
                      isLoading={isLoadingSupplier}
                      labelKey="name"
                      minLength={3}
                      onSearch={(data) => {
                        handleSearchSupplier(data);
                      }}
                      options={supplierList}
                      placeholder="Search for Supplier"
                      selected={selectedSupplier}
                      onChange={(selected: any) => {
                        setSelectedSupplier(selected);
                        setValue(
                          "paid_to_name",
                          selected.length ? selected[0].id : null
                        );
                      }}
                      renderMenuItemChildren={(option: any) => (
                        <Fragment>
                          {(option as any).id !== null && (
                            <div>
                              <span>{option.name}</span>
                            </div>
                          )}

                          {(option as any).id === null && (
                            <div
                              onClick={() => {
                                navigate(PageRoutes.NEW_SUPPLIER);
                              }}
                            >
                              <span>Add new supplier</span>
                            </div>
                          )}
                        </Fragment>
                      )}
                    />
                  )}
                  {watch("paid_to_type") === "user" && (
                    <UserSearchBox
                      register={register("paid_to_name", {
                        required: " * Required",
                      })}
                      label={"username"}
                      name={"username"}
                      onChange={(selected: any) => {
                        setSelectedUser(selected);
                        setValue(
                          "paid_to_name",
                          selected.length ? selected[0].id : null
                        );
                      }}
                      selectedUser={selectedUser}
                      placeholder={"Search for user"}
                    />
                  )}
                </Col>
                {/* paid to section ENDS */}
                {/* paid by section STARTS */}
                <Col md={5} sm={12}>
                  <label
                    className="form-check-label fw-bold mb-2"
                    htmlFor="disabledFieldsetCheck"
                  >
                    Paid by
                  </label>
                  <br />
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input me-2"
                      type="radio"
                      value="bankaccount"
                      {...register("paid_by_type")}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio2">
                      <i className="fa fa-building me-2"></i>Bank
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input me-2"
                      type="radio"
                      value="user"
                      {...register("paid_by_type")}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio1">
                      <i className="fa fa-user me-2"></i>User
                    </label>
                  </div>

                  {watch("paid_by_type") === "bankaccount" && (
                    <Typeahead
                      {...register("paid_by_name", {
                        required: " * Required",
                      })}
                      id="async-example2"
                      labelKey="name"
                      options={bankList}
                      placeholder="Choose Bank"
                      selected={selectedBank}
                      onChange={(selected: any) => {
                        setSelectedBank(selected);
                        setValue(
                          "paid_by_name",
                          selected.length ? selected[0].id : null
                        );
                      }}
                      renderMenuItemChildren={(option: any) => (
                        <Fragment>
                          {(option as any).id !== null && (
                            <Fragment>
                              <div>
                                <span>
                                  {option.name + " | " + option.abbreviation}
                                </span>
                              </div>
                            </Fragment>
                          )}

                          {(option as any).id === null && (
                            <div
                              onClick={() => {
                                navigate(PageRoutes.NEW_BANKING);
                              }}
                            >
                              <span>Add new bank</span>
                            </div>
                          )}
                        </Fragment>
                      )}
                    />
                  )}
                  {watch("paid_by_type") === "user" && (
                    <UserSearchBox
                      register={register("paid_by_name", {
                        required: " * Required",
                      })}
                      label={"username"}
                      name={"username"}
                      onChange={(selected: any) => {
                        setSelectedPaidByUser(selected);
                        setValue(
                          "paid_by_name",
                          selected.length ? selected[0].id : null
                        );
                      }}
                      selectedUser={selectedPaidByUser}
                      placeholder={"Search for user"}
                    />
                  )}
                  <ErrorMessage
                    errors={errors}
                    name="paid_by_name"
                    render={({ messages }) =>
                      messages &&
                      Object.entries(messages).map(([type, message]) => (
                        <p key={type} className="text-danger">
                          {message}
                        </p>
                      ))
                    }
                  />
                </Col>
                {/* paid by section ENDS */}

                {/* payment method section */}
                {watch("paid_by_type") === "bankaccount" &&
                  watch("paid_by_name") && (
                    <Col md={5} sm={12}>
                      <label
                        className="form-check-label fw-bold"
                        htmlFor="disabledFieldsetCheck"
                      >
                        Payment method {watch("payment_mode")}
                      </label>
                      <select
                        id="disabledSelect"
                        className="form-select"
                        {...register("payment_mode", { required: true })}
                      >
                        {selectedBank[0].payment_mode.map(
                          ({ id, name }: PaymentMode) => (
                            <option value={id}>{name}</option>
                          )
                        )}
                      </select>
                    </Col>
                  )}
              </section>
              <section className="d-flex flex-column justify-content-center  align-items-center">
                <div>
                  <h6>AMOUNT</h6>
                  <h2>
                    Rs {totalAmount} (
                    <span className="text-secondary">{totalItemAmount}</span>)
                  </h2>
                </div>
              </section>
            </div>
          </Row>

          {/* Category section */}
          <Row>
            <ExpenseCategoryAddComponent
              setTotalAmount={setTotalAmount}
              setCategoryList={setCategoryList}
            />
          </Row>
          <span className="text-primary">{categoryListError}</span>

          {/* Item section */}
          <Row>
            <ExpenseItemAddComponent
              setTotalAmount={setItemTotalAmount}
              setInventoryList={setInventoryList}
            />
          </Row>

          {/* memo section & attachment section */}
          <Row>
            <div className="col-sm-12 col-md-6 mt-5">
              <h6>Additonal Description</h6>
              <textarea
                className="form-control col-sm-6"
                id="memo"
                rows={3}
                {...register("description", {
                  required: "* Required",
                })}
              ></textarea>

              <ErrorMessage
                errors={errors}
                name="description"
                render={({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <p key={type} className="text-danger">
                      {message}
                    </p>
                  ))
                }
              />
            </div>

            <div className="col-sm-12 col-md-6 mt-5">
              <div className="d-flex ">
                <h6>Attachments</h6>
                <span className="ms-3">Maximum size :20MB</span>
              </div>
              <input
                type="file"
                {...register("attachments", {
                  required: false,
                })}
              ></input>
            </div>
          </Row>

          <div className="d-flex justify-content-end">
            <Button type="submit">SUBMIT</Button>
          </div>

          <span className="text-primary">{overallError}</span>
        </form>
      </div>

      <CustomerCreateModal
        onCreateSuccess={() => {}}
        setShowCreateCustomerDialog={setShowCreateCustomerDialog}
        showCreateCustomerDialog={showCreateCustomerDialog}
      />
    </>
  );
};

export default ExpenseAdd;
