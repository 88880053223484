import React from "react";
import { useNavigate } from "react-router-dom";
import AdminService, {
  getUserData,
  postLogin,
  storeSessionDataInStorage,
} from "../services/AdminService";
import { saveActiveRestaurant, setAdminUserData } from "../common/Utils";
import { LoginResponse } from "../class/LoginResponse";
import { ADMIN_USERNAME } from "../common/Constants";

const AuthContext = React.createContext<any>(null);

export const useAuth = () => {
  return React.useContext(AuthContext);
};

export const AuthProvider = ({ children }: any) => {
  const navigate = useNavigate();

  const [session, setSession] = React.useState<LoginResponse | null>(
    AdminService.userValue
  );

  const handleLogin = (
    username: string,
    password: string,
    dispatch: any,
    isRememberMe: boolean
  ) => {
    dispatch({ type: "loading", payload: true });
    postLogin({
      username: username,
      password: password,
    })
      .then((response: LoginResponse) => {
        if (response !== null) {
          if (isRememberMe) {
            localStorage.setItem(ADMIN_USERNAME, username);
          }

          storeSessionDataInStorage(response);

          setSession(response);

          if (response.user.is_superuser) saveActiveRestaurant(null);
          else saveActiveRestaurant(response.user.company_roles[0].company);
        } else {
          dispatch({ type: "loading", payload: false });
          dispatch({
            type: "toast",
            payload: {
              visible: true,
              message: "Login Failed. Please check email and password.",
            },
          });
        }
      })
      .catch((error) => {
        dispatch({ type: "loading", payload: false });
        dispatch({
          type: "toast",
          payload: {
            visible: true,
            message: error.detail,
          },
        });
      })
      .finally(() => {
        getUserData()
          .then((response) => {
            setAdminUserData(response);
          })
          .catch(() => {});
        navigate("/");
        dispatch({ type: "loading", payload: false });
      });
  };

  const handleLogout = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Are you sure you want to logout ?")) {
      AdminService.postLogout();
      setSession(null);
    }
  };

  const value = {
    session,
    onLogin: handleLogin,
    onLogout: handleLogout,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
