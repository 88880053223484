import { faFilter, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useState } from "react";
import { Button, Col, InputGroup, Row, Table } from "react-bootstrap";
import Stack from "react-bootstrap/Stack";
import { Link, useNavigate } from "react-router-dom";
import { ROLES, getLocalDateTime } from "../../common/Utils";
import { useCommon } from "../../components/Context/CommonContext";
import "react-datepicker/dist/react-datepicker.css";
import ButtonComponent, {
  Icons,
} from "../../components/Buttons/ButtonComponent";
import HeadingTitle from "../../components/HeadingTitle";
import NoResultFound from "../../components/NoResultFound";
import { getInventoryList } from "../../services/inventoryApi";
import { useDebounce } from "use-debounce";
import { PageRoutes } from "../../common/Constants";
import ReactPaginate from "react-paginate";
import Form from "react-bootstrap/Form";
import { InventoryType } from "../../class/Inventory";

const InventoryList = (): JSX.Element => {
  const [inventoryList, setInventoryList] = useState<InventoryType[]>([]);
  const { dispatch } = useCommon();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [debouncedSearchValue] = useDebounce(searchValue, 1000);
  const pageSizeArray = [50, 100, 200, 500];
  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState<number>(pageSizeArray[0]);
  const [currentPage, setcurrentPage] = useState<number>(1);

  const getInventoryData = useCallback(() => {
    dispatch({ type: "loading", payload: true });
    getInventoryList(debouncedSearchValue, currentPage, pageSize)
      .then((response: any) => {
        dispatch({ type: "loading", payload: false });
        setInventoryList(response?.results);
        setPageCount((prevPageCount) => Math.ceil(response.count / pageSize));
      })
      .catch((error: any) => {
        dispatch({ type: "loading", payload: false });
        console.error("Error fetching inventory data:", error);
      });
  }, [dispatch, debouncedSearchValue, currentPage, pageSize]);

  useEffect(() => {
    getInventoryData();
  }, [getInventoryData]);

  const handlePageClick = (event: any) => {
    const pageNo = event.selected + 1;
    setcurrentPage(pageNo);
  };
  return (
    <div className="mt-4 mx-4">
      <div className="d-flex justify-content-between">
        <HeadingTitle title="Inventory" />
        <Link className="d-flex gray pointer" to={PageRoutes.MEASURING_UNITS}>
          <FontAwesomeIcon icon={faFilter} />
          <h6 className="mx-2">Measuring Unit</h6>
        </Link>
      </div>
      <div className="d-flex flex-wrap align-items-baseline justify-content-between">
        <Col sm={4} md={5} lg={4} xl={2}>
          <InputGroup>
            <InputGroup.Text id="inputGroup-sizing-default">
              Page Size
            </InputGroup.Text>
            <Form.Select
              aria-label="Default select example"
              aria-describedby="inputGroup-sizing-default"
              onChange={(event: any) => {
                setPageSize(parseInt(event.target.value));
              }}
            >
              {pageSizeArray.map((item: number) => {
                return (
                  <option value={item} key={item} selected={pageSize === item}>
                    {item}
                  </option>
                );
              })}
            </Form.Select>
          </InputGroup>
        </Col>
        <Col md={5} lg={5} xl={4}>
          <form>
            <Stack direction="horizontal">
              <input
                className="form-control"
                placeholder="Search.."
                type="text"
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
              <Button type="submit" className="m-2 text-primary fw-bold">
                <FontAwesomeIcon className="text-white" icon={faSearch} />
              </Button>
              <ButtonComponent
                company_roles={[ROLES.ADMIN, ROLES.MANAGER, ROLES.SUPERADMIN]}
                tooltip={"Add Inventory"}
                variant={"primary"}
                href={PageRoutes.NEW_INVENTORY}
                type={Icons.Add}
                isButton={true}
              />
            </Stack>
          </form>
        </Col>
      </div>
      {inventoryList.length === 0 ? (
        <NoResultFound message="No results" />
      ) : (
        <Table borderless responsive className="mt-2">
          <thead>
            <tr>
              <th>Created On</th>
              <th>Stock Name</th>
              <th>Price/Unit</th>

              <th>Description</th>
              <th>Total Stock</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {inventoryList.map(
              ({
                id,
                name,
                avg_price,
                description,
                total_stock,
                measuring_unit,
                created_at,
              }) => (
                <tr key={id}>
                  <td>{getLocalDateTime(created_at)}</td>
                  <td
                    className="pointer text-primary"
                    onClick={() => navigate(`/inventory/${id}/transactions`)}
                  >
                    {name}
                  </td>
                  <td>
                    {avg_price} /{measuring_unit.symbol}
                  </td>
                  <td>{description}</td>
                  <td>{total_stock}</td>
                  <td>
                    <ButtonComponent
                      tooltip={"Edit"}
                      variant={"primary"}
                      type={Icons.Edit}
                      isButton={false}
                      onSubmitClick={() => navigate(`/inventory/${id}/edit/`)}
                    />
                    <ButtonComponent
                      tooltip={"Add Stock Manually"}
                      variant={"primary"}
                      type={Icons.Add}
                      isButton={false}
                      onSubmitClick={() =>
                        navigate(`/inventory/${id}/add-stock/`)
                      }
                    />
                  </td>
                </tr>
              )
            )}
          </tbody>
        </Table>
      )}
      {inventoryList.length > 0 && (
        <Row className="mt-4">
          <Col className="text-center">
            <ReactPaginate
              breakLabel="..."
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="Previous"
              nextLabel="Next"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakClassName="page-item"
              breakLinkClassName="page-link"
              marginPagesDisplayed={2}
              containerClassName="pagination d-inline-flex"
              activeClassName="active"
              initialPage={currentPage - 1}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default InventoryList;
