import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useCommon } from "../../components/Context/CommonContext";
import {
  createSettlement,
  getBankList,
  searchSupplierList,
} from "../../services/AdminService";
import { ErrorMessage } from "@hookform/error-message";
import { Row, Col, Button } from "react-bootstrap";
import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";
import { PaymentMode } from "../../class/Expense";
import { PageRoutes } from "../../common/Constants";
import { getActiveRestaurantId } from "../../common/Utils";
import UserSearchBox from "../../components/SearchBox/UserSearchBox";

type SettementType = {
  paid_to_type: string | null; //user or supplier
  paid_to_name: number | string;
  paid_by_type: string | null; //user or bank
  paid_by_name: number | string;
  payment_mode: string;
  description: string;
  amount: number;
  ref_no: string | null;
};

type SettlementRequest = {
  payee_type: string;
  payee_id: number;
  paid_from_type: string;
  paid_from_id: number;
  payment_mode: number;
  total: number;
  ref_no: string;
};

const NewSettlement = () => {
  const { dispatch } = useCommon();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm<SettementType>({
    defaultValues: {
      paid_to_type: "bankaccount",
      paid_by_type: "bankaccount",
    },
    criteriaMode: "all",
  });

  const [overallError, setOverallError] = useState<string>("");

  const [selectedUser, setSelectedUser] = useState<any>();

  const [selectedPaidByUser, setSelectedPaidByUser] = useState<any>();

  // for supplier list
  const [supplierList, setSupplierList] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState<any>();
  const [isLoadingSupplier, setIsLoadingSupplier] = useState(false);
  const handleSearchSupplier = (value: any) => {
    setIsLoadingSupplier(true);
    searchSupplierList(value).then((response: any) => {
      const newList = response.results;
      newList.push({ id: null, name: "" });
      setSupplierList(newList);
      setIsLoadingSupplier(false);
    });
  };

  // for bank list
  const [bankList, setBankList] = useState([]);
  const [selectedBank, setSelectedBank] = useState<any>();
  const [selectedBankPaidBy, setSelectedBankPaidBy] = useState<any>();

  const filterBy = () => true;

  useEffect(() => {
    getBankList(null).then((response: any) => {
      setBankList(response.results);
    });
  }, []);

  const handleAdd = (event: SettementType) => {
    if (
      event.paid_to_type === "bankaccount" ||
      event.paid_by_type === "bankaccount"
    ) {
      setOverallError("");
    } else {
      setOverallError(
        "Either receiver or sender should be bank. Please enter correct settlement data."
      );
      return;
    }

    const request: SettlementRequest = {
      payee_type: event.paid_to_type!,
      payee_id: Number(event.paid_to_name),
      paid_from_type: event.paid_by_type!,
      paid_from_id: Number(event.paid_by_name),
      payment_mode: Number(event.payment_mode),
      total: Number(event.amount),
      ref_no: event.ref_no ? event.ref_no : "",
    };

    dispatch({ type: "loading", payload: true });
    createSettlement(getActiveRestaurantId(), request)
      .then((response: any) => {
        dispatch({ type: "loading", payload: false });
        reset({
          paid_to_type: "bankaccount",
          paid_by_type: "bankaccount",
          amount: 0,
          description: "",
          payment_mode: undefined,
        });
        dispatch({
          type: "messageModal",
          payload: {
            visible: true,
            title: "Success",
            message: "Settlement created successfully.",
          },
        });
      })
      .catch((error: any) => {
        dispatch({ type: "loading", payload: false });
        dispatch({
          type: "toast",
          payload: {
            visible: true,
            message: error.message,
            success: false,
          },
        });
      });
  };

  return (
    <div className="p-4">
      <h4 className="mb-4">Settlement</h4>
      <form className="expense" onSubmit={handleSubmit(handleAdd)}>
        <Row>
          {/* paid to section STARTS */}
          <Col md={6} sm={12} className="mt-4">
            <label
              className="form-check-label fw-bold mb-2"
              htmlFor="disabledFieldsetCheck"
            >
              Paid to
            </label>
            <br />
            <div className="form-check form-check-inline">
              <input
                className="form-check-input me-2"
                type="radio"
                value="bankaccount"
                {...register("paid_to_type")}
              />
              <label className="form-check-label" htmlFor="inlineRadio2">
                <i className="fa fa-building me-2"></i>Bank
              </label>
            </div>
            {watch("paid_by_type") === "bankaccount" && (
              <>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="user"
                    {...register("paid_to_type")}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio1">
                    <i className="fa fa-user me-2"></i>User
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="supplier"
                    {...register("paid_to_type")}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio2">
                    <i className="fa fa-building me-2"></i>Supplier
                  </label>
                </div>
              </>
            )}

            {watch("paid_to_type") === "supplier" && (
              <AsyncTypeahead
                {...register("paid_to_name", {
                  required: " * Required",
                })}
                filterBy={filterBy}
                id="async-example"
                isLoading={isLoadingSupplier}
                labelKey="name"
                minLength={3}
                onSearch={(data) => {
                  handleSearchSupplier(data);
                }}
                options={supplierList}
                placeholder="Search for Supplier"
                selected={selectedSupplier}
                onChange={(selected: any) => {
                  setSelectedSupplier(selected);
                  setValue(
                    "paid_to_name",
                    selected.length ? selected[0].id : null
                  );
                }}
                renderMenuItemChildren={(option: any) => (
                  <Fragment>
                    {(option as any).id !== null && (
                      <div>
                        <span>{option.name}</span>
                      </div>
                    )}

                    {(option as any).id === null && (
                      <div
                        onClick={() => {
                          navigate(PageRoutes.NEW_SUPPLIER);
                        }}
                      >
                        <span>Add new supplier</span>
                      </div>
                    )}
                  </Fragment>
                )}
              />
            )}
            {watch("paid_to_type") === "user" && (
              <UserSearchBox
                register={register("paid_to_name", {
                  required: " * Required",
                })}
                label={"username"}
                name={"username"}
                onChange={(selected: any) => {
                  setSelectedUser(selected);
                  setValue(
                    "paid_to_name",
                    selected.length ? selected[0].id : null
                  );
                }}
                selectedUser={selectedUser}
                placeholder={"Search for user"}
              />
            )}
            {watch("paid_to_type") === "bankaccount" && (
              <Typeahead
                {...register("paid_to_name", {
                  required: " * Required",
                })}
                id="async-example2"
                labelKey="name"
                options={bankList}
                placeholder="Choose Bank"
                selected={selectedBank}
                onChange={(selected: any) => {
                  setSelectedBank(selected);
                  setValue(
                    "paid_to_name",
                    selected.length ? selected[0].id : null
                  );
                }}
                renderMenuItemChildren={(option: any) => (
                  <Fragment>
                    {(option as any).id !== null && (
                      <Fragment>
                        <div>
                          <span>
                            {option.name + " | " + option.abbreviation}
                          </span>
                        </div>
                      </Fragment>
                    )}

                    {(option as any).id === null && (
                      <div
                        onClick={() => {
                          navigate(PageRoutes.NEW_BANKING);
                        }}
                      >
                        <span>Add new bank</span>
                      </div>
                    )}
                  </Fragment>
                )}
              />
            )}
            <ErrorMessage
              errors={errors}
              name="paid_to_name"
              render={({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <p key={type} className="text-danger">
                    {message}
                  </p>
                ))
              }
            />
          </Col>
          {/* paid to section ENDS */}
          {/* paid by section STARTS */}
          <Col md={6} sm={12} className="mt-4">
            <label
              className="form-check-label fw-bold mb-2"
              htmlFor="disabledFieldsetCheck"
            >
              Paid by
            </label>
            <br />
            <div className="form-check form-check-inline">
              <input
                className="form-check-input me-2"
                type="radio"
                value="bankaccount"
                {...register("paid_by_type")}
              />
              <label className="form-check-label" htmlFor="inlineRadio2">
                <i className="fa fa-building me-2"></i>Bank
              </label>
            </div>

            {watch("paid_to_type") === "bankaccount" && (
              <>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input me-2"
                    type="radio"
                    value="user"
                    {...register("paid_by_type")}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio1">
                    <i className="fa fa-user me-2"></i>User
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="supplier"
                    {...register("paid_by_type")}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio2">
                    <i className="fa fa-building me-2"></i>Supplier
                  </label>
                </div>
              </>
            )}

            {watch("paid_by_type") === "supplier" && (
              <AsyncTypeahead
                {...register("paid_by_name", {
                  required: " * Required",
                })}
                filterBy={filterBy}
                id="async-example"
                isLoading={isLoadingSupplier}
                labelKey="name"
                minLength={3}
                onSearch={(data) => {
                  handleSearchSupplier(data);
                }}
                options={supplierList}
                placeholder="Search for Supplier"
                selected={selectedSupplier}
                onChange={(selected: any) => {
                  setSelectedSupplier(selected);
                  setValue(
                    "paid_by_name",
                    selected.length ? selected[0].id : null
                  );
                }}
                renderMenuItemChildren={(option: any) => (
                  <Fragment>
                    {(option as any).id !== null && (
                      <div>
                        <span>{option.name}</span>
                      </div>
                    )}

                    {(option as any).id === null && (
                      <div
                        onClick={() => {
                          navigate(PageRoutes.NEW_SUPPLIER);
                        }}
                      >
                        <span>Add new supplier</span>
                      </div>
                    )}
                  </Fragment>
                )}
              />
            )}
            {watch("paid_by_type") === "bankaccount" && (
              <Typeahead
                {...register("paid_by_name", {
                  required: " * Required",
                })}
                id="async-example2"
                labelKey="name"
                options={bankList}
                placeholder="Choose Bank"
                selected={selectedBankPaidBy}
                onChange={(selected: any) => {
                  setSelectedBankPaidBy(selected);
                  setValue(
                    "paid_by_name",
                    selected.length ? selected[0].id : null
                  );
                }}
                renderMenuItemChildren={(option: any) => (
                  <Fragment>
                    {(option as any).id !== null && (
                      <Fragment>
                        <div>
                          <span>
                            {option.name + " | " + option.abbreviation}
                          </span>
                        </div>
                      </Fragment>
                    )}

                    {(option as any).id === null && (
                      <div
                        onClick={() => {
                          navigate(PageRoutes.NEW_BANKING);
                        }}
                      >
                        <span>Add new bank</span>
                      </div>
                    )}
                  </Fragment>
                )}
              />
            )}
            {watch("paid_by_type") === "user" && (
              <UserSearchBox
                register={register("paid_by_name", {
                  required: " * Required",
                })}
                label={"username"}
                name={"username"}
                onChange={(selected: any) => {
                  setSelectedPaidByUser(selected);
                  setValue(
                    "paid_by_name",
                    selected.length ? selected[0].id : null
                  );
                }}
                selectedUser={selectedPaidByUser}
                placeholder={"Search for user"}
              />
            )}
            <ErrorMessage
              errors={errors}
              name="paid_by_name"
              render={({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <p key={type} className="text-danger">
                    {message}
                  </p>
                ))
              }
            />
          </Col>
          {/* paid by section ENDS */}

          {/* payment method section */}
          {watch("paid_by_type") !== "bankaccount" &&
            watch("paid_to_type") === "bankaccount" &&
            watch("paid_to_name") && (
              <Col md={6} sm={12} className="mt-4">
                {selectedBank && (
                  <>
                    <label
                      className="form-check-label fw-bold"
                      htmlFor="disabledFieldsetCheck"
                    >
                      Paid to Payment method
                    </label>
                    <select
                      id="disabledSelect"
                      className="form-select"
                      {...register("payment_mode", {
                        required: {
                          value:
                            watch("paid_by_type") !== "bankaccount" &&
                            watch("paid_to_type") === "bankaccount",
                          message: "* Required",
                        },
                      })}
                    >
                      {selectedBank[0].payment_mode.map(
                        ({ id, name }: PaymentMode) => (
                          <option value={id}>{name}</option>
                        )
                      )}
                    </select>

                    <ErrorMessage
                      errors={errors}
                      name="payment_mode"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <p key={type} className="text-danger">
                            {message}
                          </p>
                        ))
                      }
                    />
                  </>
                )}
              </Col>
            )}

          {watch("paid_by_type") === "bankaccount" && watch("paid_by_name") && (
            <Col md={6} sm={12} className="mt-4">
              {selectedBankPaidBy && (
                <>
                  <label
                    className="form-check-label fw-bold"
                    htmlFor="disabledFieldsetCheck"
                  >
                    Paid by Payment method
                  </label>
                  <select
                    id="disabledSelect"
                    className="form-select"
                    {...register("payment_mode", {
                      required: {
                        value: watch("paid_by_type") === "bankaccount",
                        message: "* Required",
                      },
                    })}
                  >
                    {selectedBankPaidBy[0].payment_mode.map(
                      ({ id, name }: PaymentMode) => (
                        <option value={id}>{name}</option>
                      )
                    )}
                  </select>

                  <ErrorMessage
                    errors={errors}
                    name="payment_mode"
                    render={({ messages }) =>
                      messages &&
                      Object.entries(messages).map(([type, message]) => (
                        <p key={type} className="text-danger">
                          {message}
                        </p>
                      ))
                    }
                  />
                </>
              )}
            </Col>
          )}
          {/* payment method section ends */}

          <div className="col-sm-12 col-md-6 mt-4">
            <h6>Amount</h6>
            <input
              className="form-control col-sm-6"
              id="amount"
              type="number"
              onWheel={(e) => e.currentTarget.blur()}
              {...register("amount", {
                required: "* Required",
                min: {
                  value: 1,
                  message: "Enter valid amount",
                },
              })}
            />

            <ErrorMessage
              errors={errors}
              name="amount"
              render={({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <p key={type} className="text-danger">
                    {message}
                  </p>
                ))
              }
            />
          </div>

          <div className="col-sm-12 col-md-6 mt-4">
            <h6>Reference No.</h6>
            <input
              className="form-control col-sm-6"
              id="ref_no"
              type="text"
              {...register("ref_no")}
            />

            <ErrorMessage
              errors={errors}
              name="ref_no"
              render={({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <p key={type} className="text-danger">
                    {message}
                  </p>
                ))
              }
            />
          </div>

          <div className="col-sm-12 col-md-6 mt-4">
            <h6>Additonal Description</h6>
            <textarea
              className="form-control col-sm-6"
              id="memo"
              rows={3}
              {...register("description")}
            ></textarea>

            <ErrorMessage
              errors={errors}
              name="description"
              render={({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <p key={type} className="text-danger">
                    {message}
                  </p>
                ))
              }
            />
          </div>
        </Row>

        <span className="text-primary mt-4">{overallError}</span>

        <div className="d-flex justify-content-end mt-4">
          <Button type="submit">SUBMIT</Button>
        </div>
      </form>
    </div>
  );
};

export default NewSettlement;
