import { useState } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { CompanyRole, LoginResponse } from "../../class/LoginResponse";
import { getActiveRestaurant } from "../../common/Utils";
import { useAuth } from "../../components/AuthProvider";

const RequireAuth = ({ allowedRoles }: { allowedRoles: any }) => {
  const { session }: { session: LoginResponse } = useAuth();
  const location = useLocation();

  const [activeRestro] = useState(getActiveRestaurant());

  if (session.user.is_superuser) return <Outlet />;

  return session?.user?.company_roles.find(
    (company_role: CompanyRole) =>
      allowedRoles?.includes(company_role.role.name) &&
      company_role.company.id === activeRestro.id
  ) ? (
    <Outlet />
  ) : session?.user ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
