/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useAuth } from "../AuthProvider";
import {
  getActiveRestaurantId,
  getActiveUserCompanyRole,
  getAdminUserData,
  isSuperUser,
  setAdminUserData,
} from "../../common/Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Badge, Dropdown } from "react-bootstrap";
import { getUserData } from "../../services/AdminService";
import CompanySwitch from "./CompanySwitch";
import { MessageStrings, PageRoutes } from "../../common/Constants";
import { useCommon } from "../Context/CommonContext";
const Header = ({
  showSideBar,
  setShowSideBar,
}: {
  showSideBar: any;
  setShowSideBar: any;
}) => {
  const [userData, setuserData] = useState({ username: "" });
  const localUserData = getAdminUserData();
  const { onLogout } = useAuth();
  const { dispatch } = useCommon();

  useEffect(() => {
    if (localUserData != null) {
      setuserData(JSON.parse(localUserData));
    } else {
      getUserData()
        .then((response) => {
          setuserData(response);
          setAdminUserData(response);
        })
        .catch((error) => {
          dispatch({
            type: "messageModal",
            payload: {
              visible: true,
              title: MessageStrings.ERROR_MESSAGE_TITLE,
              message: MessageStrings.ERROR_MESSAGE_GENERIC,
            },
          });
        });
    }
    return () => {};
  }, [dispatch, localUserData]);
  return (
    <nav className="navbar navbar-expand bg-light navbar-light d-flex sticky-top px-4 py-0 d-none d-md-flex">
      <span
        className="mobileMenuToggler pointer"
        onClick={() => {
          setShowSideBar(!showSideBar);
        }}
      >
        <i className="fa fa-bars mt-2"></i>
      </span>
      <CompanySwitch />
      <div className="navbar-nav d-flex  justify-content-between align-items-center ms-auto">
        {/* <span className="d-none d-md-block">
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip id="button-tooltip" style={{ position: "fixed" }}>
                {isSuperUser() ? (
                  "SUPER ADMIN"
                ) : (
                  <span>
                    {getActiveUserCompanyRole(getActiveRestaurantId()) &&
                      getActiveUserCompanyRole(
                        getActiveRestaurantId()
                      ).toUpperCase()}
                  </span>
                )}
              </Tooltip>
            }
          >
            <Badge bg="secondary">
              <h6 className="text-white text-3xl mb-0">
                <FontAwesomeIcon icon={faUser} style={{ color: "white" }} />
                <span className="phUserName ms-1">{userData.username} </span>
              </h6>
            </Badge>
          </OverlayTrigger>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip id="button-tooltip" style={{ position: "fixed" }}>
                Log out
              </Tooltip>
            }
          >
            <a onClick={onLogout} href="#" className="ms-2">
              <i className="fas fa-sign-out-alt  white" aria-hidden="true"></i>
            </a>
          </OverlayTrigger>
        </span> */}

        <Dropdown className="d-block">
          <Dropdown.Toggle variant="info" id="dropdown-basic">
            <FontAwesomeIcon icon={faUser} style={{ color: "white" }} />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item>
              <span>{userData.username}</span>
              <br></br>
              <Badge>
                {isSuperUser() ? (
                  "SUPER ADMIN"
                ) : (
                  <span>
                    {getActiveUserCompanyRole(getActiveRestaurantId()) &&
                      getActiveUserCompanyRole(
                        getActiveRestaurantId()
                      ).toUpperCase()}
                  </span>
                )}
              </Badge>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item href={PageRoutes.PROFILE}>
              View Profile
            </Dropdown.Item>
            <Dropdown.Item href={PageRoutes.CHANGE_PASSWORD}>
              Change Password
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item href="#" onClick={onLogout}>
              Sign Out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </nav>
  );
};

export default Header;
